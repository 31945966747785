import {
  Button,
  styled,
  TableCell,
  Typography,
} from '@material-ui/core';


export const SecondaryTitle = styled(Typography)({
  fontSize: 30,
  color: '#4d4c4c'
})

export const TableCellWaldo = styled(TableCell)({
  fontWeight: 'bold',
  color: '#4d4c4c'
})


export const PrimaryButton = styled(Button)({
  backgroundColor: '#3172B4',
  color: 'white',
  borderRadius: 2,

  '&:hover': {
    borderRadius: 2,
    backgroundColor: '#87b3de'

  },

});



export const JobsButton = styled(Button)({
  backgroundColor: 'white',
  borderRadius: 0,
  outlineColor: 'grey',
  marginBottom: 5,


  '&:hover': {
    color: '#3172B4',
    borderRadius: 0,
    outlineColor: 'grey',
    marginBottom: 5
  },

  '&:focus': {
    color: 'white',
    backgroundColor: '#3172B4',
    borderColor: '#3172B4',
  },


});


export const RectangleButton = styled(Button)({
  borderRadius: 0,
});



export const SecondaryButton = styled(Button)({
  outlineColor: 'black',
  borderRadius: 0,

  '&:hover': {
    color: '#3172B4',
    borderRadius: 0,
    outlineColor: 'black',

  },

});

export const DeclineButton = styled(Button)({
  borderRadius: 0,
  outlineColor: 'black',

  '&:hover': {
    color: 'red',
    borderRadius: 0,


  },

});


export const LogOutButton = styled(Button)({
  borderColor: 'grey',
  borderRadius: 0,

  '&:hover': {
    color: '#3172B4',
    borderRadius: 0,
    borderColor: '#3172B4',

  },
});

export const LinkButton = styled(Button)({
  padding: 0,
  color: '#069',
  cursor: 'pointer',
  fontSize: 10
});


