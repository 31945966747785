import React, { useState, useContext } from 'react'
import gql from 'graphql-tag'
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks'


import { Typography, Avatar, Dialog, Toolbar, Divider, Paper, IconButton } from '@material-ui/core'

import {
    WoodLoadQuery,
    WoodLoadQueryVariables,
    WoodLoadQuery_woodLoadById_jobByJobId_companyByCompanyId_woodLoadFieldSettingsByCompanyId_nodes as WoodLoadFieldSetting
} from './generated/WoodLoadQuery'
import { dateToString, isPresent } from './utils'
import { Link } from 'react-router-dom'
import { NextWoodLoad, NextWoodLoadVariables } from './generated/NextWoodLoad'
import { PrevWoodLoad, PrevWoodLoadVariables } from './generated/PrevWoodLoad'
import { PrimaryButton, SecondaryButton } from './styles'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import EditIcon from '@material-ui/icons/Edit'


import { formatDistance, format } from 'date-fns'
import CreateWoodLoadStateEntryDialog from './WoodLoads/CreateWoodLoadStateEntryDialog'
import ColoredLabel from './WoodLoadState/ColoredLabel'
import WaldoTable from './WaldoTable'
import AvatarWithName from './AvatarWithName'
import { PageTitle } from './WaldoStyle'
import { GetWoodLoadPdf, GetWoodLoadPdfVariables } from './generated/GetWoodLoadPdf'
import CommentSection from './CommentSection'
import { CreateWoodLoadComment, CreateWoodLoadCommentVariables } from './generated/CreateWoodLoadComment'
import { UserContext } from './UserContext'
import { ConfigurableWoodLoadField } from './Company/ConfigureWoodLoadFields'


const WOOD_LOAD_QUERY = gql`query WoodLoadQuery($woodLoadId: Int!) {
    woodLoadById(id: $woodLoadId) {
      idOnJob
      createdAt
      id
      notes
      speciesGroupName
      userByLoggerUserId {
        id
        avatarUrl
        username
        companyName
      }
      logRoadByLogRoadId {
        id
        name
      }
      acceptedApplicationId
      truckerLoadApplicationByAcceptedApplicationId {
        id
        userId
        woodLoadId
        estimatedPickupDate
        userByUserId {
          avatarUrl
          id
          username
        }
        companyByCompanyId {
          id
          name
          avatarUrl
        }
      }
      pickedUpAt
      droppedOffAt
      jobByJobId {
        contractName
        companyByCompanyId {
          name
          id
          avatarUrl
          userWoodLoadFieldsByCompanyId {
            nodes {
              id
              name
            }
          }
          woodLoadFieldSettingsByCompanyId {
            nodes {
              id
              nativeFieldName
              userWoodLoadFieldId
              index
              hide
            }
          }
        }
        id
      }
      woodLoadDestinationByWoodLoadDestinationId {
        id
        name
        millByMillId {
          id
          name
        }
      }
      pickUpLocation {
        longitude
        latitude
      }
      woodLoadPhotosByWoodLoadId {
        nodes {
          photoUrl
        }
      }
      haulRate
      tripTicketNumber
      value
      unit
      scaleTicketNumber
      woodLoadTypeByLoadType {
        name
      }
      woodSpecyByWoodSpeciesId {
        name
      }
      scaleSlipPhotoUrl
      tripTicketPhotoUrl
      companyByLoggingCompanyId {
        id
        name
        avatarUrl
        
      }
      estimatedVolume
      estimatedDroppedOffAt
      woodLoadStateEntriesByWoodLoadId(orderBy: ID_DESC) {
        nodes {
          id
          createdAt
          comment
          userByCreatorId {
            id
            username
            avatarUrl
          }
          woodLoadStateByStateId {
            createdAt
            id
            name
            color
            companyId
          }
        }
      }
      woodLoadCommentsByWoodLoadId {
        nodes {
          id
          userByCreatorId {
            id
            username
            avatarUrl
          }
          content
          createdAt
        }
      }
      userWoodLoadFieldValuesByWoodLoadId {
        nodes {
          id
          value
          userWoodLoadFieldId
        }
      }
    }
  }
  `

const NEXT_WOOD_LOAD = gql`
    query NextWoodLoad($jobId: Int!, $idOnJob: Int!) {
        allWoodLoads(
            filter: {
                jobId: { equalTo: $jobId }
                idOnJob: { greaterThan: $idOnJob }
            }
            first: 1
            orderBy: ID_ON_JOB_ASC
        ) {
            nodes {
                id
                idOnJob
            }
        }
    }
`

const PREV_WOOD_LOAD = gql`
    query PrevWoodLoad($jobId: Int!, $idOnJob: Int!) {
        allWoodLoads(
            filter: {
                jobId: { equalTo: $jobId }
                idOnJob: { lessThan: $idOnJob }
            }
            first: 1
            orderBy: ID_ON_JOB_DESC
        ) {
            nodes {
                id
                idOnJob
            }
        }
    }
`

// Each time this resolver is called, the server generates a PDF and stores in AWS, so use minimally.
const GENERATE_PDF = gql`query GetWoodLoadPdf($woodLoadId: Int!) {
    woodLoadById(id: $woodLoadId) {
      id
      pdfUrl
    }
  }
  `

const CREATE_COMMENT = gql`mutation CreateWoodLoadComment(
    $woodLoadId: Int!
    $creatorId: Int!
    $content: String!
  ) {
    createWoodLoadComment(
      input: {
        woodLoadComment: {
          woodLoadId: $woodLoadId
          creatorId: $creatorId
          content: $content
        }
      }
    ) {
      clientMutationId
    }
  }
  `

const download = (path: string, filename: string) => {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;
    anchor.target = '_blank'

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);
};

interface Props {
    //close: void;
    woodLoadId: string
}
function getNegativeOne() {
    return -1
}

function divider() {
    return (
        <div
            style={{
                height: 2,
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                gridColumnStart: 1,
                gridColumnEnd: 3,
                borderColor: 'lightgrey',
            }}
        />
    )
}

export function printDistance(d: any | null) {
    if (d !== null) {
        return formatDistance(new Date(d), new Date(), { addSuffix: true })
    } else {
        return ''
    }
}

export default function WoodLoad(props: Props) {
    const userContext = useContext(UserContext)
    const client = useApolloClient()
    const [fullScreenImage, setFullScreenImage] = useState<string>('')

    const { data, loading, refetch } = useQuery<WoodLoadQuery, WoodLoadQueryVariables>(
        WOOD_LOAD_QUERY,
        {
            variables: {
                woodLoadId: Number(props.woodLoadId) || getNegativeOne(),
            },
        }
    )

    const { data: nextWoodLoadData } = useQuery<
        NextWoodLoad,
        NextWoodLoadVariables
    >(NEXT_WOOD_LOAD, {
        variables: {
            jobId: data?.woodLoadById?.jobByJobId?.id || -1,
            idOnJob: data?.woodLoadById?.idOnJob || -1,
        },
    })

    const { data: prevWoodLoadData } = useQuery<
        PrevWoodLoad,
        PrevWoodLoadVariables
    >(PREV_WOOD_LOAD, {
        variables: {
            jobId: data?.woodLoadById?.jobByJobId?.id || -1,
            idOnJob: data?.woodLoadById?.idOnJob || -1,
        },
    })

    const [loadStateOpen, setLoadStateOpen] = useState<boolean>(false)

    const [createComment] = useMutation<CreateWoodLoadComment, CreateWoodLoadCommentVariables>(CREATE_COMMENT)

    const datePast = new Date(data?.woodLoadById?.createdAt);
    const date = new Date()

    if (loading || !data) {
        return <div>Loading...</div>
    }




    function getMillOrDestinationName() {
        if (data?.woodLoadById?.woodLoadDestinationByWoodLoadDestinationId?.millByMillId) {
            return data.woodLoadById.woodLoadDestinationByWoodLoadDestinationId.millByMillId.name
        } else {
            return data?.woodLoadById?.woodLoadDestinationByWoodLoadDestinationId?.name
        }
    }

    const state = data.woodLoadById?.woodLoadStateEntriesByWoodLoadId.nodes[0]

    const woodLoadFields: ({
        apiName?: ConfigurableWoodLoadField,
        userJobFieldId?: number
        woodLoadFieldSetting?: WoodLoadFieldSetting
        render: () => JSX.Element
    })[] = [
            {
                apiName: 'pickUpLocation',
                woodLoadFieldSetting: data.woodLoadById?.jobByJobId?.companyByCompanyId?.woodLoadFieldSettingsByCompanyId.nodes.find(n => n?.nativeFieldName === 'pickUpLocation') ?? undefined,
                render: () => <>
                    <Typography style={{ gridColumn: 1 }}>
                        Location (lat,lng):
                    </Typography>
                    <Typography style={{ gridColumn: 2 }}>
                        {Math.round(
                            Number(data?.woodLoadById?.pickUpLocation?.latitude)
                        )}
                        ,
                        {Math.round(
                            Number(
                                data?.woodLoadById?.pickUpLocation?.longitude
                            )
                        )}
                    </Typography>
                </>
            },
            {
                apiName: 'loadType',
                woodLoadFieldSetting: data.woodLoadById?.jobByJobId?.companyByCompanyId?.woodLoadFieldSettingsByCompanyId.nodes.find(n => n?.nativeFieldName === 'loadType') ?? undefined,
                render: () => <>
                    <Typography style={{ gridColumn: 1 }}>
                        Product Type:
                    </Typography>
                    <Typography style={{ gridColumn: 2 }}>
                        {data?.woodLoadById?.woodLoadTypeByLoadType?.name}
                    </Typography>
                </>
            },
            {
                apiName: 'speciesGroupName',
                woodLoadFieldSetting: data.woodLoadById?.jobByJobId?.companyByCompanyId?.woodLoadFieldSettingsByCompanyId.nodes.find(n => n?.nativeFieldName === 'speciesGroupName') ?? undefined,
                render: () => <>
                    <Typography style={{ gridColumn: 1 }}>
                        Species Group:
                    </Typography>
                    <Typography style={{ gridColumn: 2 }}>
                        {data?.woodLoadById?.speciesGroupName}
                    </Typography>
                </>
            },
            {
                apiName: 'speciesId',
                woodLoadFieldSetting: data.woodLoadById?.jobByJobId?.companyByCompanyId?.woodLoadFieldSettingsByCompanyId.nodes.find(n => n?.nativeFieldName === 'speciesId') ?? undefined,
                render: () => <>
                    <Typography style={{ gridColumn: 1 }}>
                        Species:
                    </Typography>
                    <Typography style={{ gridColumn: 2 }}>
                        {data?.woodLoadById?.woodSpecyByWoodSpeciesId?.name}
                    </Typography>
                </>
            },
            {
                apiName: 'woodLoadDestinationId',
                woodLoadFieldSetting: data.woodLoadById?.jobByJobId?.companyByCompanyId?.woodLoadFieldSettingsByCompanyId.nodes.find(n => n?.nativeFieldName === 'woodLoadDestinationId') ?? undefined,
                render: () => <>
                    <Typography style={{ gridColumn: 1 }}>
                        Destination:
                    </Typography>
                    <Typography style={{ gridColumn: 2 }}>
                        {
                            data?.woodLoadById
                                ?.woodLoadDestinationByWoodLoadDestinationId
                                ?.name
                        }
                    </Typography>s
                </>
            },
            {
                apiName: 'haulRate',
                woodLoadFieldSetting: data.woodLoadById?.jobByJobId?.companyByCompanyId?.woodLoadFieldSettingsByCompanyId.nodes.find(n => n?.nativeFieldName === 'haulRate') ?? undefined,
                render: () => <>
                    <Typography style={{ gridColumn: 1 }}>
                        Haul Rate:
                    </Typography>
                    <Typography style={{ gridColumn: 2 }}>
                        {data?.woodLoadById?.haulRate}
                    </Typography>
                </>
            },
            {
                apiName: 'notes',
                woodLoadFieldSetting: data.woodLoadById?.jobByJobId?.companyByCompanyId?.woodLoadFieldSettingsByCompanyId.nodes.find(n => n?.nativeFieldName === 'notes') ?? undefined,
                render: () => <>
                    <Typography style={{ gridColumn: 1 }}>Notes:</Typography>
                    <Typography style={{ gridColumn: 2 }}>
                        {data?.woodLoadById?.notes}
                    </Typography>
                </>
            }
        ]

    for (const userField of data.woodLoadById?.jobByJobId?.companyByCompanyId?.userWoodLoadFieldsByCompanyId.nodes.filter(isPresent) ?? []) {
        woodLoadFields.push({
            userJobFieldId: userField.id,
            woodLoadFieldSetting: data.woodLoadById?.jobByJobId?.companyByCompanyId?.woodLoadFieldSettingsByCompanyId.nodes.find(n => n?.userWoodLoadFieldId === userField.id) ?? undefined,
            render: () => <>
                <Typography style={{ gridColumn: 1 }}>
                    {userField.name}
                </Typography>
                <Typography style={{ gridColumn: 2 }}>
                    {data?.woodLoadById?.userWoodLoadFieldValuesByWoodLoadId.nodes.find(n => n?.userWoodLoadFieldId === userField.id)?.value}
                </Typography>
            </>
        })
    }


    return (
        <div style={{ textAlign: 'center' }}>
            <div
                style={{
                    display: 'inline-grid',
                    gridTemplateColumns: '200px 500px 200px',
                }}
            >
                <div style={{ gridColumn: 1 }}>
                    {(prevWoodLoadData?.allWoodLoads?.nodes.length || 0) >
                        0 && (
                            <Link
                                to={`/wood_loads/${prevWoodLoadData?.allWoodLoads?.nodes[0]?.id}`}
                            >
                                Previous Load (
                                {prevWoodLoadData?.allWoodLoads?.nodes[0]?.idOnJob})
                            </Link>
                        )}
                </div>
                <Paper>
                    <div
                        style={{
                            margin: 20,
                            gridRowGap: 8,
                            textAlign: 'left',
                            alignItems: 'center',
                            gridColumnGap: 10,
                            gridColumn: 2,
                        }}
                    >

                        <div style={{ textAlign: 'center' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 5fr 1fr', alignItems: 'center' }}>
                                <div />
                                <Typography>{data?.woodLoadById?.woodLoadTypeByLoadType?.name || 'Wood'} headed to {getMillOrDestinationName()}</Typography>
                                <IconButton onClick={() => setLoadStateOpen(true)}>
                                    <EditIcon />
                                </IconButton>
                            </div>
                            <Typography style={{ fontWeight: 500, fontSize: 14, fontStyle: 'italic', alignSelf: 'center', color: 'grey' }}>{format(datePast, 'MM/dd/yyyy hh:mm a')} ({formatDistance(datePast, date, { addSuffix: true })})</Typography>
                            <IconButton
                                style={{ width: 60 }}
                                onClick={() => {
                                    navigator.clipboard.writeText(`https://app.waldologs.com/wood_loads/${data.woodLoadById?.id}`)
                                    alert('The link to this load is copied to your Clipboard! You can paste it into another app.')
                                }}>
                                <FileCopyIcon />
                            </IconButton>
                            <Typography style={{ color: 'grey', fontStyle: 'italic', fontSize: 14, marginBottom: 15 }}>{data?.woodLoadById?.id}</Typography>

                            {state && <div>
                                <Link to={`/companies/${state.woodLoadStateByStateId?.companyId}/wood_load_states/${state.woodLoadStateByStateId?.id}`}><ColoredLabel color={state.woodLoadStateByStateId?.color || ''} text={state.woodLoadStateByStateId?.name || ''} /></Link>
                            </div>}
                        </div>

                        <div
                            style={{
                                margin: 20,
                                gridTemplateColumns: '50% 50%',
                                gridRowGap: 8,
                                display: 'inline-grid',
                                textAlign: 'left',
                                alignItems: 'center',
                                gridColumnGap: 10,
                            }}
                        >

                            <Typography style={{ gridColumnStart: 1, gridColumnEnd: 3, fontWeight: 'bold', fontSize: 25, marginRight: 10 }} >Job</Typography>

                            <Typography style={{ gridColumn: 1 }}>Company:</Typography>


                            <Link to={`/companies/${data?.woodLoadById?.jobByJobId?.companyByCompanyId?.id}`} style={{ gridColumn: 2 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar style={{ marginRight: 5 }} src={data?.woodLoadById?.jobByJobId?.companyByCompanyId?.avatarUrl || ''} />
                                    <Typography>
                                        {
                                            data?.woodLoadById?.jobByJobId?.companyByCompanyId?.name
                                        }
                                    </Typography>
                                </div>
                            </Link>

                            <Typography style={{ gridColumn: 1 }}>Job Name:</Typography>
                            <Link style={{ gridColumn: 2 }} to={`/jobs/${data?.woodLoadById?.jobByJobId?.id}`}> {data?.woodLoadById?.jobByJobId?.contractName}</Link>




                            <Typography style={{ gridColumnStart: 1, gridColumnEnd: 3, fontWeight: 'bold', fontSize: 25 }}>Logger</Typography>
                            <Typography style={{ gridColumn: 1 }}>By:</Typography>
                            <Link style={{ gridColumn: 2 }} to={`/users/${data?.woodLoadById?.userByLoggerUserId?.id}`}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar style={{ marginRight: 5, marginLeft: 5 }} src={data?.woodLoadById?.userByLoggerUserId?.avatarUrl || undefined} />
                                    <Typography>
                                        {data?.woodLoadById?.userByLoggerUserId?.username}
                                    </Typography>
                                </div>
                            </Link>

                            <Typography style={{ gridColumn: 1 }}>For:</Typography>
                            <Link to={`/companies/${data?.woodLoadById?.companyByLoggingCompanyId?.id}`} style={{ gridColumn: 2 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar style={{ marginRight: 5, marginLeft: 5 }} src={data?.woodLoadById?.companyByLoggingCompanyId?.avatarUrl || ''} />
                                    <Typography>
                                        {data?.woodLoadById?.companyByLoggingCompanyId?.name}
                                    </Typography>
                                </div>
                            </Link>




                            {divider()}

                            <Typography style={{ gridColumn: 1 }}>
                                Created At:
                            </Typography>
                            <Typography style={{ gridColumn: 2 }}>
                                {dateToString(new Date(data?.woodLoadById?.createdAt))}{' '}
                                ({printDistance(data?.woodLoadById?.createdAt)})
                            </Typography>

                            {divider()}



                            <Typography style={{ gridColumn: 1 }}>Load #:</Typography>
                            <Typography style={{ gridColumn: 2 }}>
                                {data?.woodLoadById?.idOnJob}
                            </Typography>

                            {divider()}



                            {
                                woodLoadFields
                                    .filter(wf => !wf.woodLoadFieldSetting?.hide)
                                    .sort((a, b) => {
                                        const aIdx = a.woodLoadFieldSetting?.index
                                        const bIdx = b.woodLoadFieldSetting?.index
                                        if (isPresent(aIdx) && isPresent(bIdx)) {
                                            return aIdx - bIdx
                                        }
                                        return 0
                                    })
                                    .map(wlf => <>
                                        {wlf.render()}
                                        {divider()}
                                    </>)
                            }

                            <Typography style={{ gridColumn: 1 }}>Photos:</Typography>
                            <Typography style={{ gridColumn: 2 }}>
                                <div>
                                    {data?.woodLoadById?.woodLoadPhotosByWoodLoadId.nodes
                                        .filter((p) => p)
                                        .map((p, idx) => (
                                            <img
                                                alt="full screen dialog"
                                                key={idx}
                                                src={p?.photoUrl || ''}
                                                style={{ maxHeight: 100 }}
                                                onClick={() => {
                                                    setFullScreenImage(p?.photoUrl || '')
                                                }}
                                            />
                                        ))}
                                </div>{' '}
                            </Typography>

                            {divider()}


                            <Typography style={{ gridColumnStart: 1, gridColumnEnd: 3, fontWeight: 'bold', fontSize: 25 }}>Trucker</Typography>
                            <Typography style={{ gridColumn: 1 }}>By:</Typography>
                            <Link style={{ gridColumn: 2 }} to={`/users/${data?.woodLoadById?.truckerLoadApplicationByAcceptedApplicationId?.userByUserId?.id}`}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar style={{ marginRight: 5, marginLeft: 5 }} src={data?.woodLoadById?.truckerLoadApplicationByAcceptedApplicationId?.userByUserId?.avatarUrl || ''} />
                                    <Typography>
                                        {data?.woodLoadById?.truckerLoadApplicationByAcceptedApplicationId?.userByUserId?.username}
                                    </Typography>
                                </div>
                            </Link>

                            <Typography style={{ gridColumn: 1 }}>For:</Typography>
                            <Link to={`/companies/${data?.woodLoadById?.truckerLoadApplicationByAcceptedApplicationId?.companyByCompanyId?.id}`} style={{ gridColumn: 2 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar style={{ marginRight: 5, marginLeft: 5 }} src={data?.woodLoadById?.truckerLoadApplicationByAcceptedApplicationId?.companyByCompanyId?.avatarUrl || ''} />
                                    <Typography>
                                        {data?.woodLoadById?.truckerLoadApplicationByAcceptedApplicationId?.companyByCompanyId?.name}
                                    </Typography>
                                </div>
                            </Link>

                            <Typography>Estimated Pickup:</Typography>

                            <Typography>{data?.woodLoadById?.truckerLoadApplicationByAcceptedApplicationId?.estimatedPickupDate}</Typography>

                            {divider()}



                            <Typography style={{ gridColumn: 1 }}>
                                Picked Up at:
                            </Typography>
                            {data?.woodLoadById?.pickedUpAt && (
                                <Typography style={{ gridColumn: 2 }}>
                                    {dateToString(
                                        new Date(data?.woodLoadById?.pickedUpAt)
                                    )}{' '}
                                    ({printDistance(data?.woodLoadById?.pickedUpAt)})
                                </Typography>
                            )}

                            {divider()}

                            <Typography>Estimated Dropoff:</Typography>

                            {data?.woodLoadById?.estimatedDroppedOffAt && <Typography>{dateToString(
                                new Date(data?.woodLoadById?.estimatedDroppedOffAt)
                            )}{' '}
                                ({printDistance(data?.woodLoadById?.estimatedDroppedOffAt)})</Typography>}

                            {divider()}

                            <Typography>Estimated Volume:</Typography>

                            <Typography>{data?.woodLoadById?.estimatedVolume}</Typography>

                            {divider()}

                            <Typography style={{ gridColumn: 1 }}>
                                Dropped off at:
                            </Typography>
                            {data?.woodLoadById?.droppedOffAt && (
                                <Typography style={{ gridColumn: 2 }}>
                                    {dateToString(
                                        new Date(data?.woodLoadById?.droppedOffAt)
                                    )}{' '}
                                    ({printDistance(data?.woodLoadById?.droppedOffAt)})
                                </Typography>
                            )}

                            {divider()}

                            <Typography style={{ gridColumn: 1 }}>
                                Trip ticket #:
                            </Typography>
                            <Typography style={{ gridColumn: 2 }}>
                                {data?.woodLoadById?.tripTicketNumber}
                            </Typography>

                            {divider()}

                            <Typography style={{ gridColumn: 1 }}>
                                Scale ticket #:
                            </Typography>
                            <Typography style={{ gridColumn: 2 }}>
                                {data?.woodLoadById?.scaleTicketNumber}
                            </Typography>

                            {divider()}

                            <Typography style={{ gridColumn: 1 }}>
                                Load Unit:
                            </Typography>
                            <Typography style={{ gridColumn: 2 }}>
                                {data?.woodLoadById?.unit}
                            </Typography>

                            {divider()}

                            <Typography style={{ gridColumn: 1 }}>
                                Load Value:
                            </Typography>
                            <Typography style={{ gridColumn: 2 }}>
                                {data?.woodLoadById?.value}
                            </Typography>
                            {divider()}
                            {data?.woodLoadById?.tripTicketPhotoUrl && (
                                <>
                                    {' '}
                                    <Typography style={{ gridColumn: 1 }}>
                                        Trip Ticket Photo:
                                    </Typography>
                                    <Typography style={{ gridColumn: 2 }}>
                                        <div>
                                            <img
                                                src={
                                                    data?.woodLoadById
                                                        ?.tripTicketPhotoUrl || ''
                                                }
                                                style={{ height: 100 }}
                                                onClick={() => {
                                                    console.log('hi')
                                                    setFullScreenImage(
                                                        data?.woodLoadById
                                                            ?.tripTicketPhotoUrl || ''
                                                    )
                                                }
                                                }
                                                alt="panda"
                                            />
                                        </div>{' '}
                                    </Typography>
                                    {divider()}
                                </>
                            )}

                            {data?.woodLoadById?.scaleSlipPhotoUrl && (
                                <>
                                    {' '}
                                    <Typography style={{ gridColumn: 1 }}>
                                        Scale Slip Photo:
                                    </Typography>
                                    <Typography style={{ gridColumn: 2 }}>
                                        <div>
                                            <img
                                                src={
                                                    data?.woodLoadById
                                                        ?.scaleSlipPhotoUrl || ''
                                                }
                                                style={{ height: 100 }}
                                                onClick={() => {
                                                    setFullScreenImage(
                                                        data?.woodLoadById
                                                            ?.scaleSlipPhotoUrl || ''
                                                    )
                                                    // setScaleSlipPhotoViewed(prev => !prev)
                                                }}
                                                alt="panda"
                                            />
                                        </div>{' '}
                                    </Typography>
                                    {divider()}
                                </>
                            )}

                            <PrimaryButton
                                style={{ gridColumn: 1 }}
                                onClick={async () => {
                                    const res = await client.query<GetWoodLoadPdf, GetWoodLoadPdfVariables>({
                                        query: GENERATE_PDF,
                                        variables: {
                                            woodLoadId: parseInt(props.woodLoadId, 10)
                                        }
                                    })

                                    await new Promise(resolve => setTimeout(() => resolve(1), 1000))

                                    if (res.data.woodLoadById?.pdfUrl) {
                                        download(res.data.woodLoadById.pdfUrl, `Waldo Load ${props.woodLoadId}.pdf`)
                                    }

                                }}
                            >Download PDF</PrimaryButton>
                        </div>





                    </div>
                </Paper>

                <div style={{ gridColumn: 3 }}>
                    {(nextWoodLoadData?.allWoodLoads?.nodes.length || 0) >
                        0 && (
                            <Link
                                to={`/wood_loads/${nextWoodLoadData?.allWoodLoads?.nodes[0]?.id}`}
                            >
                                Next Load (
                                {nextWoodLoadData?.allWoodLoads?.nodes[0]?.idOnJob})
                            </Link>
                        )}
                </div>
            </div>

            <div style={{ width: '100%', textAlign: 'left' }}><PageTitle>STATE ENTRIES</PageTitle></div>
            <WaldoTable
                data={data.woodLoadById?.woodLoadStateEntriesByWoodLoadId.nodes.filter(isPresent) || []}
                columns={[
                    {
                        header: 'State',
                        render: (r) => <Link to={`/companies/${r.woodLoadStateByStateId?.companyId}/wood_load_states/${r.woodLoadStateByStateId?.id}`}>
                            <ColoredLabel color={r.woodLoadStateByStateId?.color || ''} text={r.woodLoadStateByStateId?.name || ''} />
                        </Link>
                    },
                    {
                        header: 'At',
                        render: (r) => <Typography>{dateToString(new Date(r.createdAt))}{' '}
                            ({printDistance(r.createdAt)})</Typography>
                    },
                    {
                        header: 'By',
                        render: (r) => <AvatarWithName
                            linkTo={`/users/${r.userByCreatorId?.id}`}
                            avatarUrl={r.userByCreatorId?.avatarUrl || ''}
                            name={r.userByCreatorId?.username || ''}
                        />
                    },
                    {
                        header: 'Comment',
                        render: (r) => r.comment
                    }
                ]}
            />

            <CommentSection
                comments={data.woodLoadById?.woodLoadCommentsByWoodLoadId.nodes.filter(isPresent) || []}
                createComment={async (text: string) => {
                    await createComment({
                        variables: {
                            creatorId: userContext?.currentUser.currentUser?.id || -1,
                            woodLoadId: parseInt(props.woodLoadId, 10),
                            content: text
                        }
                    })
                    await refetch()
                }}
            />

            <div style={{ height: 400 }} />


            {
                fullScreenImage && (
                    <Dialog
                        fullScreen
                        title="View Image"
                        open={Boolean(fullScreenImage)}
                        style={{
                            height: window.innerHeight,
                            width: window.innerWidth,
                        }}
                    >

                        <Toolbar>
                            <Typography>
                                View Image
                            </Typography>
                        </Toolbar>
                        <Divider style={{ marginBottom: 10 }} />

                        <img
                            style={{
                                maxHeight: window.innerHeight * 0.8,
                                maxWidth: 900,
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                            src={fullScreenImage}


                            alt="panda"
                        />

                        <Divider style={{ marginTop: 10 }} />

                        <div style={{ alignContent: 'right', marginTop: 20 }}>
                            <SecondaryButton
                                variant="outlined"
                                onClick={() => setFullScreenImage('')}
                                style={{ marginLeft: 1000 }}>
                                cancel
                            </SecondaryButton>
                            <PrimaryButton
                                onClick={() => setFullScreenImage('')}
                                style={{ marginLeft: 10 }}>
                                ok
                            </PrimaryButton>
                        </div>


                    </Dialog>
                )
            }

            {
                loadStateOpen && data.woodLoadById && <CreateWoodLoadStateEntryDialog
                    open={loadStateOpen}
                    setOpen={async () => {
                        await refetch()
                        setLoadStateOpen(false)
                    }}
                    woodLoad={data.woodLoadById}
                />
            }
        </div >
    )
}
