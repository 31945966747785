import React from 'react';
import { History } from 'history';

const HistoryContext = React.createContext<{
    history: History | undefined;
}>({
    history: undefined,
});

export default HistoryContext;
