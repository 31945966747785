import React, { useState } from 'react'
import { TextField, Grid, } from '@material-ui/core';
import { Authenticate, AuthenticateVariables } from './generated/Authenticate'

import { RegisterUserMutation, RegisterUserMutationVariables } from './generated/RegisterUserMutation'
import { useMutation } from '@apollo/react-hooks';
import { REGISTER_MUTATION, AUTHENTICATE } from './RegisterLogin'
import { PrimaryButton, SecondaryTitle } from './styles';
interface Props {
    refetch: () => void
}

function divider() {
    return <div style={{
        height: 2,
        width: 500,
        alignContent: 'center',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        gridColumnStart: '1',
        gridColumnEnd: '3',
        borderColor: 'lightgrey'
    }} />
}

export default function RegisterModal(props: Props) {

    const [authenticate] = useMutation<Authenticate, AuthenticateVariables>(AUTHENTICATE)
    const [register] = useMutation<RegisterUserMutation, RegisterUserMutationVariables>(REGISTER_MUTATION, {
        onError: () => { }
    });
    const [registerFail, setRegisterFail] = useState<boolean>(false)
    const [registerEmail, setRegisterEmail] = useState<string>('')
    const [username, setUserName] = useState<string>('')
    const [password, setPassword] = useState<string>('')


    //const onRegisterFinish = async (values: any) => {
    //const { registerEmail, username, password }: { registerEmail: string, username: string, password: string } = values
    //};


    return <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ textAlign: 'center', maxWidth: 700, minWidth: 500 }}>
            <Grid>

                <SecondaryTitle style={{ marginTop: 20 }}>Register</SecondaryTitle>
                <Grid item>
                    <TextField style={{ width: 250, }}
                        value={registerEmail}
                        onChange={(event) => {
                            setRegisterEmail(event.target.value)
                        }}
                        margin="dense"
                        variant="outlined"
                        placeholder="Email" />
                </Grid>
                <Grid item>
                    <TextField
                        style={{ width: 250, marginTop: 10 }}
                        value={username}
                        onChange={(event) => {
                            setUserName(event.target.value)
                        }}
                        margin="dense"
                        variant="outlined"
                        placeholder="Username" />
                </Grid>
                <Grid item>
                    <TextField
                        style={{ width: 250, marginTop: 10 }}
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}
                        autoComplete="new-password"
                        type="password"
                        margin="dense"
                        variant="outlined"
                        placeholder="Password" />
                </Grid>
                {registerFail && <div style={{ color: 'red' }}>
                    Please enter valid email, username, and password
                <div>
                        Email or username may already be in use
                </div>
                    <div>
                        Username cannot have capital letters
                </div>
                </div>}

                <PrimaryButton
                    onClick={async () => {
                        localStorage.removeItem('token')


                        const registerResult = await register({
                            variables: {
                                email: registerEmail,
                                username: username,
                                password: password
                            }
                        })
                        if (registerResult === undefined) {
                            setRegisterFail(true)
                            return
                        }
                        const res = await authenticate({
                            variables: {
                                email: registerEmail,
                                password: password
                            }
                        })
                        if (res.data?.authenticate?.jwtToken) {
                            localStorage.setItem('token', res.data?.authenticate?.jwtToken);
                            await props.refetch()
                        } else { setRegisterFail(true) }

                    }}
                    style={{ marginTop: 15, marginBottom: 15 }}>
                    Register
            </PrimaryButton>

                {divider()}
            </Grid>
        </div>
    </div>
}