import React from 'react';

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
} from '@material-ui/core';

interface Props {
    open: boolean;
    setOpen: (val: boolean) => void;
    onConfirm: () => void;
    message: string;
    title?: string;
}

export default function ConfirmationDialog(props: Props) {
    return (
        <Dialog
            disableBackdropClick
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {props.title || 'Confirm'}
            </DialogTitle>
            <DialogContent>
                <Typography>{props.message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpen(false)} variant="outlined">
                    No
                </Button>
                <div>
                    <Button
                        onClick={async () => {
                            await props.onConfirm();
                            props.setOpen(false);
                        }}
                        color="primary"
                        variant="contained"
                    >
                        Yes
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}