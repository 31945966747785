import React, { useState, useEffect } from 'react'
import { Radio, FormControl, FormControlLabel, Typography, RadioGroup, } from '@material-ui/core'

import { SecondaryTitle, } from './styles'
import HomeScreenButton from './HomeScreenButton';
import Bell from './bell.svg'
import HardHat from './hard-hat.svg'
import Briefcase from './briefcase.svg'
import Notifications from './Notifications'
import Jobs from './Jobs';
import RecentWoodLoads from './CompanyWoodLoads';
import gql from 'graphql-tag';
import { HomeScreenQuery } from './generated/HomeScreenQuery';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`query HomeScreenQuery {
    currentUser {
      id
      companyByDefaultCompanyId {
        id
        name
        avatarUrl
      }
    }
  }
  `


export type Server = 'Dev' | 'Prod'

export default function Home() {

    const [server, setServer] = useState<Server>('Prod')

    const { data } = useQuery<HomeScreenQuery>(QUERY)


    function reloadServer() {
        const val = localStorage.getItem('waldo_server')

        if (val === 'Dev') {
            setServer('Dev')
        } else if (val === 'Prod') {
            setServer('Prod')
        }
    }

    useEffect(() => {

        reloadServer()

    }, [])


    const defaultCompany = data?.currentUser?.companyByDefaultCompanyId


    return <div style={{ marginTop: 10, display: 'inline-grid', rowGap: 10 }}>

        <div style={{ display: 'flex' }}>
            <HomeScreenButton
                title="Jobs"
                to="/jobs"
                image={HardHat}
            />

            {
                defaultCompany && <HomeScreenButton
                    title={defaultCompany.name}
                    to={`/companies/${defaultCompany.id}`}
                    image={defaultCompany.avatarUrl}
                />
            }

            <HomeScreenButton
                title="Companies"
                to="/companies"
                image={Briefcase}
            />


            <div style={{ display: 'flex', alignItems: 'center' }}>
                <HomeScreenButton
                    title="Notifications"
                    to="/notifications/"
                    image={Bell}
                />

            </div>
        </div>

        <div style={{ marginTop: 20, }}>
            <SecondaryTitle>Recent Notifications</SecondaryTitle>
            <Notifications
                first={10}
            />


        </div>



        <div style={{ marginTop: 20 }}>
            <SecondaryTitle>Recent Wood Loads</SecondaryTitle>
            <RecentWoodLoads
                first={5}
            />
        </div>

        <div style={{ marginTop: 20 }}>
            <SecondaryTitle>Recent Jobs</SecondaryTitle>
            <Jobs
                first={3}
            />
        </div>


        <div style={{ display: 'flex', marginTop: 50 }}>
            <Typography style={{ marginRight: 15, marginTop: 9 }}>Server:</Typography>

            <FormControl>
                <RadioGroup style={{ flexDirection: 'row' }}>
                    <FormControlLabel
                        checked={server === 'Prod'}
                        control={<Radio style={{ color: 'grey' }} />}
                        label="Normal"
                        onChange={() => {
                            setServer('Prod')
                            localStorage.setItem('waldo_server', 'Prod');
                            reloadServer()
                        }}
                    />
                    <FormControlLabel
                        checked={server === 'Dev'}
                        control={<Radio style={{ color: 'grey' }} />}
                        label="Test"
                        onChange={() => {
                            setServer('Dev')
                            localStorage.setItem('waldo_server', 'Dev');
                            reloadServer()
                        }}
                    />
                </RadioGroup>
            </FormControl>
            <Typography style={{ marginLeft: 10, fontSize: 10, marginTop: 15 }}>Refresh page after switching server</Typography>

        </div>

    </div >
}

