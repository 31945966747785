import React, { useState } from 'react'
import { SectionTitle } from './WaldoStyle'

import {
    WoodLoadQuery_woodLoadById_woodLoadCommentsByWoodLoadId_nodes as Comment,
} from './generated/WoodLoadQuery'
import { Paper, TextField, Typography } from '@material-ui/core'
import { PrimaryButton } from './styles'
import AvatarWithName from './AvatarWithName'
import { Link } from 'react-router-dom'
import { formatDistance } from 'date-fns'

interface Props {
    comments: Omit<Comment, '__typename'>[]
    createComment: (content: string) => Promise<any>
}

export default function CommentSection(props: Props) {

    const [text, setText] = useState<string>('')

    return <div style={{ width: '100%', textAlign: 'left' }}>
        <SectionTitle>COMMENTS</SectionTitle>
        {
            props.comments.length === 0 ? <>
                <Typography style={{ fontStyle: 'italic' }}>No comments yet!</Typography>
            </> : <Paper style={{ padding: 8, marginBottom: 8 }}>
                {
                    props.comments.map(c => <div style={{ marginBottom: 3, display: 'grid', gridTemplateColumns: 'min-content auto' }}>
                        <AvatarWithName
                            name={''}
                            linkTo={`/Users/${c.userByCreatorId?.id}`}
                            avatarUrl={c.userByCreatorId?.avatarUrl || ''}
                        />
                        <div>
                            <Link to={`/Users/${c.userByCreatorId?.id}`}>{c.userByCreatorId?.username}</Link>
                            <Typography>{c.content}</Typography>
                            <Typography style={{ color: 'grey', fontStyle: 'italic', fontSize: 12 }}>{formatDistance(new Date(c.createdAt), new Date(), { addSuffix: true })}</Typography>
                        </div>
                    </div>)
                }
            </Paper>
        }
        <div style={{ display: 'grid', gridTemplateColumns: 'auto min-content', columnGap: 5 }}>
            <TextField
                variant="outlined"
                placeholder="Add new comment..."
                value={text}
                multiline
                onChange={(event) => {
                    setText(event.target.value)
                }}
                style={{ backgroundColor: 'white' }}
            />
            <PrimaryButton
                onClick={async () => {
                    if (!text) {
                        return
                    }
                    await props.createComment(text)
                    setText('')
                }}

            >POST!</PrimaryButton>
        </div>
    </div>
}