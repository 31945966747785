import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            width: 120,
            height: 120,
            backgroundColor: 'white',
            borderWidth: 3,
            borderColor: 'lightgrey',
            borderStyle: 'solid',
            marginRight: 20,
            marginTop: 20,
        },
        image: {
            position: 'relative',
            height: 55,
            marginBottom: 4,
            objectFit: 'contain'
        },
    }),
);

interface Props {
    title: string
    image: any
    to: string
}

export default function HomeScreenButton(props: Props) {
    const classes = useStyles();

    return (
        <Link to={props.to} style={{ textDecoration: 'none' }}>
            <span className={classes.root}>
                <div
                    key={props.title}
                    className={classes.image}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                        justifyContent: 'flex-start',
                        marginTop: 10,
                        marginBottom: 5,
                        textAlign: 'center'
                    }}
                >
                    <img alt="button" src={props.image} className={classes.image} />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', color: 'black' }}>
                        <Typography style={{ color: '#4d4c4c', fontSize: 13, fontWeight: 700, marginLeft: 3, marginRight: 3 }}>{props.title}</Typography>
                    </div>
                </div>
            </span>
        </Link>
    );
}
