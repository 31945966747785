import React, { useState } from 'react'
import { TextField, Typography, Dialog } from '@material-ui/core'
import { useStripe } from '@stripe/react-stripe-js';
import Axios from 'axios';
import { server_url } from '../createClient';
import { PrimaryButton, SecondaryButton } from '../styles';


interface Props {
    visible: boolean
    onClose: () => void
    companyId: number
}

export default function BankModal(props: Props) {

    const [accountHolderName, setAccountHolderName] = useState<string>('')
    const [routingNumber, setRoutingNumber] = useState<string>('')
    const [accountNumber, setAccountNumber] = useState<string>('')

    const [errorMessage, setErrorMessage] = useState<string>('')

    const stripe = useStripe()




    return <Dialog
        title="Bank Setup"
        open={props.visible}
        style={{ width: 400, margin: 'auto' }}

    >

        <div style={{}}>


            <TextField
                placeholder="Account Holder Name"
                margin="dense"
                variant="outlined"
                style={{ width: 250, marginLeft: 50, marginTop: 20 }}
                value={accountHolderName}
                onChange={(val) => setAccountHolderName(val.target.value)}
            />
            <TextField
                placeholder="Routing Number"
                margin="dense"
                variant="outlined"
                style={{ width: 250, marginLeft: 50 }}
                value={routingNumber}
                onChange={(val) => setRoutingNumber(val.target.value)}
            />

            <TextField
                placeholder="Account Number"
                margin="dense"
                variant="outlined"
                style={{ width: 250, marginLeft: 50, marginBottom: 20 }}
                value={accountNumber}
                onChange={(val) => setAccountNumber(val.target.value)}
            />

            <Typography style={{ color: 'red' }}>{errorMessage}</Typography>
        </div>


        <div style={{ display: 'grid', gridAutoFlow: 'column', justifyContent: 'right', marginBottom: 10 }}>
            <SecondaryButton

                style={{ width: 25, marginRight: 10 }}
                onClick={() => props.onClose()}
            >cancel</SecondaryButton>

            <PrimaryButton
                style={{ width: 20, marginRight: 10 }}
                onClick={async () => {

                    if (stripe) {
                        const res = await stripe.createToken('bank_account', {
                            country: 'US',
                            currency: 'usd',
                            routing_number: routingNumber,
                            account_number: accountNumber,
                            account_holder_name: accountHolderName,
                            account_holder_type: 'company'
                        })

                        if (res.error) {
                            setErrorMessage('Stripe Error: ' + (res.error.message || ''))
                            return
                        }

                        try {

                            // might already be created, let er rip anyway
                            await Axios.post(`${server_url}/api/create_stripe_customer`, {
                                companyId: Number(props.companyId)
                            },
                                {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.getItem('token')}`
                                    }
                                })

                            const res2 = await Axios.post(`${server_url}/api/setup_stripe_ach_account_via_stripe`, {
                                companyId: props.companyId,
                                stripeToken: res.token?.id
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem('token')}`
                                }
                            })

                            if (res2.data.error) {
                                setErrorMessage(res2.data.error)
                            }
                        } catch (e: any) {
                            console.log(e)
                            setErrorMessage(e.response.data.error)
                            return
                        }




                        props.onClose()

                    } else {
                        setErrorMessage('Uh Oh, Stripe failed to load!')
                    }




                }} >Ok</PrimaryButton>



        </div>
    </Dialog >

}

