import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Typography, Paper, Divider } from '@material-ui/core'
import { AllNotifications, AllNotificationsVariables, AllNotifications_allNotifications_nodes as Notification } from './generated/AllNotifications'
import { formatDistanceStrict } from 'date-fns/esm'
import HistoryContext from './HistoryContext'




const NOTIFICATIONS = gql`query AllNotifications($first: Int!) {
    allNotifications(orderBy: CREATED_AT_DESC, first: $first) {
      nodes {
        createdAt
        id
        message
        params
        type2
        viewed
      }
    }
  }`


interface Props {
    first: number
}

export default function Notifications(props: Props) {
    const historyContext = useContext(HistoryContext)

    const { data } = useQuery<AllNotifications, AllNotificationsVariables>(NOTIFICATIONS, {
        variables: {
            first: props.first
        }
    })

    function getNotifications(): Notification[] {
        const notifications: Notification[] = []
        data?.allNotifications?.nodes.forEach(e => { e && notifications.push(e) })
        return notifications
    }


    return <div>
        <Paper>

            <List>
                {getNotifications().map((value) => {
                    const labelId = `checkbox-list-secondary-label-${value}`
                    return (
                        <><ListItem key={value.id} button onClick={() => {
                            if (value.params.wood_load_id) { historyContext.history?.push(`/wood_loads/${value.params.wood_load_id}`) }
                            else if (value.params?.application_id) {
                                historyContext.history?.push(`/applications/${value.params?.application_id}`)
                            }


                        }}>
                            <ListItemAvatar>
                                <Avatar
                                    src={value?.params.avatar_url} />
                            </ListItemAvatar>
                            <div style={{ display: 'grid', }}>
                                <ListItemText id={labelId} primary={`${value.message}`} />
                                <Typography style={{ fontSize: 15, color: '#bec1c1' }}>{formatDistanceStrict(new Date(value?.createdAt), new Date(), { addSuffix: true })}</Typography>
                            </div>
                            <ListItemSecondaryAction />
                        </ListItem>
                            <Divider /></>
                    )
                })}
            </List>
        </Paper>

    </div>
}