import React from 'react'
import { useQuery } from '@apollo/react-hooks'


import gql from 'graphql-tag'
import {
    GetCompanyDestinations,
    GetCompanyDestinationsVariables,
    GetCompanyDestinations_companyById_woodLoadDestinationsByCompanyId_nodes as Destination
} from '../generated/GetCompanyDestinations';
import { Typography } from '@material-ui/core';
import WaldoTable from '../WaldoTable';
import { isPresent } from '../utils';
import { SecondaryButton } from '../styles';
import { FiDownload } from 'react-icons/fi';
import papaParse from 'papaparse'
import { filterNulls } from '../WoodLoads/WoodLoadsTable';



export const QUERY = gql`query GetCompanyDestinations($companyId: Int!) {
    companyById(id: $companyId) {
      id
      name
      woodLoadDestinationsByCompanyId {
        nodes {
          id
          name
          address
          woodLoadsByWoodLoadDestinationId {
            totalCount
          }
        }
      }
    }
  }
  `


interface Props {
    companyId: string
}



export default function Destinations(props: Props) {


    const { data } = useQuery<GetCompanyDestinations, GetCompanyDestinationsVariables>(QUERY, {
        variables: {
            companyId: parseInt(props.companyId, 10)
        }
    })

    async function csvFile() {

        // const csvLoads: WoodLoad[] = []
        // woodloads.data.allWoodLoads?.nodes.forEach(n => n && csvLoads.push(n))
        // csvLoads.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

        const csvColumns: { title: string, getValue: (w: Destination) => string }[] = [
            {
                title: 'Destination ID',
                getValue: (w) => w.id.toString() || ''
            },
            {
                title: 'Name',
                getValue: (w) => w.name
            },
            {
                title: 'Address',
                getValue: (w) => w.address
            }
        ]

        const csvString = papaParse.unparse({
            fields: csvColumns.map(c => c.title),
            data: filterNulls(data?.companyById?.woodLoadDestinationsByCompanyId.nodes || []).map(node => csvColumns.map(c => c.getValue(node).replaceAll(',', '')))
        });
        const blob = new Blob([csvString]);
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = `destinations.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }



    return <div>
        <Typography>Destinations for <b>{data?.companyById?.name}</b></Typography>

        <WaldoTable
            data={data?.companyById?.woodLoadDestinationsByCompanyId.nodes.filter(isPresent) || []}
            columns={[
                {
                    header: 'Name',
                    render: r => r.name
                },
                {
                    header: 'Address',
                    render: r => r.address
                },
                {
                    header: 'Wood Loads',
                    render: r => r.woodLoadsByWoodLoadDestinationId.totalCount
                }
            ]}
        />

        <SecondaryButton

            variant="outlined"
            style={{ marginBottom: 5, marginTop: 5 }}
            onClick={() => {
                csvFile()
            }
            }>Download CSV <FiDownload /></SecondaryButton>
    </div>
}