import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { CrewsByJobId, CrewsByJobIdVariables } from './generated/CrewsByJobId'
import { Paper, List, ListItem, Typography, Divider, ListItemText } from '@material-ui/core'
import HistoryContext from './HistoryContext'

const CREWS_BY_COMAPANY_ID = gql`query CrewsByJobId($id: Int!) {
  jobById(id: $id) {
    jobCrewsByJobId {
      nodes {
        crewByCrewId {
          name
          id
          crewMembersByCrewId {
            totalCount
          }
        }
      }
    }
  }
}

  `
interface Props {
  jobId: string
}
interface Crew {
  name: string
  members: number
  id: number
}

export default function Crew(props: Props) {
  const historyContext = useContext(HistoryContext)

  const { data } = useQuery<CrewsByJobId, CrewsByJobIdVariables>(CREWS_BY_COMAPANY_ID, {
    variables: {
      id: Number(props.jobId)
    }
  })
  function listOfCrews(): Crew[] {
    const crews: Crew[] = []
    data?.jobById?.jobCrewsByJobId.nodes.forEach(n => n?.crewByCrewId?.name && crews.push({ name: n.crewByCrewId.name, members: n.crewByCrewId.crewMembersByCrewId.totalCount, id: n.crewByCrewId.id }))
    return crews
  }



  return <div>
    <Paper style={{ height: 100 }}>
      <List>
        <ListItem><Typography style={{ fontSize: 15, fontWeight: 'bold' }}>Crews</Typography></ListItem>
        <Divider />
        {listOfCrews().map((crew) => {
          return (
            <><><ListItem onClick={() => {

              historyContext.history?.push(`/crew/${crew.id}`)

            }} style={{ backgroundColor: 'white' }}>

              <ListItemText style={{ marginLeft: 10 }} primary={crew?.name} />
            </ListItem>
              <Divider />
            </>
            </>
          );
        })}
      </List>
    </Paper>


  </div>
}