import React, { useContext } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Avatar, Typography, Paper } from '@material-ui/core'
import { UserContext } from './UserContext';
import { dateToString } from './utils';
import { formatDistance } from 'date-fns/esm'
import { TruckerApplication, TruckerApplicationVariables } from './generated/TruckerApplication'
import { AcceptTruckerApplication, AcceptTruckerApplicationVariables } from './generated/AcceptTruckerApplication'
import { TruckerWithdrawApplication, TruckerWithdrawApplicationVariables } from './generated/TruckerWithdrawApplication'
import { TruckerCancelApplication, TruckerCancelApplicationVariables } from './generated/TruckerCancelApplication'
import { Link } from 'react-router-dom'
import { DeclineButton, PrimaryButton, } from './styles'
const APPLICATION = gql`query TruckerApplication($id: Int!) {
    truckerLoadApplicationById(id: $id) {
      createdAt
      estimatedPickupDate
      id
      userByUserId {
        avatarUrl
        id
        username
      }
      userId
      woodLoadId
      woodLoadByWoodLoadId {
        acceptedApplicationId
        id
        loggerUserId
        jobByJobId {
          allowTruckerAcceptApplications
          jobCrewsByJobId {
            nodes {
              crewId
              crewByCrewId {
                id
                crewMembersByCrewId {
                  nodes {
                    userId
                  }
                }
              }
            }
          }
          contractName
        }
        pickedUpAt
        idOnJob
        userByLoggerUserId {
          avatarUrl
          username
        }
      }
      companyByCompanyId {
        avatarUrl
        id
        name
      }
      cancelledAt
      cancelledBy
      userByCancelledBy {
        avatarUrl
        username
      }
    }
  }
  
  `
const ACCEPT_APPLICATION = gql`mutation AcceptTruckerApplication($woodLoadId: Int!, $applicationId: Int!) {
    __typename
    updateWoodLoadById(
      input: {
        woodLoadPatch: { acceptedApplicationId: $applicationId }
        id: $woodLoadId
      }
    ) {
      clientMutationId
    }
  }
`
const WITHDRAW_APPLICATION = gql`mutation TruckerWithdrawApplication(
    $applicationId: Int!
    $canceledBy: Int!
    $canceledAt: Datetime!
  ) {
    __typename
    updateTruckerLoadApplicationById(
      input: {
        truckerLoadApplicationPatch: {
          cancelledAt: $canceledAt
          cancelledBy: $canceledBy
        }
        id: $applicationId
      }
    ) {
      clientMutationId
    }
  }
  `
const CANCEL_APPLICATION = gql`mutation TruckerCancelApplication(
    $acceptedApplicationId: Int
    $woodLoadId: Int!
  ) {
    updateWoodLoadById(
      input: {
        woodLoadPatch: { acceptedApplicationId: $acceptedApplicationId }
        id: $woodLoadId
      }
    ) {
      clientMutationId
    }
  }`

interface Props {
  applicationId: string
}


export default function Application(props: Props) {

  function divider() {
    return <div style={{
      marginTop: 5,
      marginBottom: 5,
      height: 2,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      gridColumnStart: '1',
      gridColumnEnd: '3',
      borderColor: 'lightgrey'
    }} />
  }

  const { data, refetch } = useQuery<TruckerApplication, TruckerApplicationVariables>(APPLICATION, {
    variables: {
      id: Number(props.applicationId)
    }
  })
  const [acceptApplication] = useMutation<AcceptTruckerApplication, AcceptTruckerApplicationVariables>(ACCEPT_APPLICATION)
  const [withdrawApplication] = useMutation<TruckerWithdrawApplication, TruckerWithdrawApplicationVariables>(WITHDRAW_APPLICATION)
  const [cancelApplication] = useMutation<TruckerCancelApplication, TruckerCancelApplicationVariables>(CANCEL_APPLICATION)
  const userContext = useContext(UserContext)

  function canAccept(): boolean {
    let bool = false
    if (
      (data?.truckerLoadApplicationById?.woodLoadByWoodLoadId?.loggerUserId === userContext?.currentUser.currentUser?.id) ||
      (data?.truckerLoadApplicationById?.woodLoadByWoodLoadId?.jobByJobId?.jobCrewsByJobId.nodes.some(n => n?.crewByCrewId?.crewMembersByCrewId.nodes.some(n2 => n2?.userId === userContext?.currentUser.currentUser?.id))) ||
      (data?.truckerLoadApplicationById?.woodLoadByWoodLoadId?.jobByJobId?.allowTruckerAcceptApplications && userContext?.currentUser.currentUser?.id) === data?.truckerLoadApplicationById?.userId) {
      if (!data?.truckerLoadApplicationById?.cancelledAt && !data?.truckerLoadApplicationById?.woodLoadByWoodLoadId?.acceptedApplicationId) {
        bool = true
      }
    }
    return bool
  }
  return <div style={{ textAlign: 'center', }}>
    <Paper className="paperComponent">
      <div style={{ display: 'inline-grid', gridTemplateColumns: '200px 500px 200px', padding: 15 }}>
        <div style={{ display: 'inline-grid', gridTemplateColumns: '50% 50%', gridRowGap: 8, textAlign: 'left', alignItems: 'center', gridColumnGap: 10, gridColumn: 2, }}>
          <Typography style={{ gridColumn: '1' }}>Created At:</Typography>
          <Typography style={{ gridColumn: '2' }}>{formatDistance(new Date(data?.truckerLoadApplicationById?.createdAt || new Date()), new Date(), { addSuffix: true })}</Typography>
          {divider()}
          <Typography style={{ gridColumn: '1' }}>By:</Typography>
          <Typography style={{ gridColumn: '2' }}>{<Avatar style={{ marginRight: 5 }} src={data?.truckerLoadApplicationById?.userByUserId?.avatarUrl || ''} />} {data?.truckerLoadApplicationById?.userByUserId?.username}</Typography>
          {divider()}
          <Typography style={{ gridColumn: '1' }}>For:</Typography>
          <Typography style={{ gridColumn: '2' }}>{<Avatar style={{ marginRight: 5 }} src={data?.truckerLoadApplicationById?.companyByCompanyId?.avatarUrl || ''} />} {data?.truckerLoadApplicationById?.companyByCompanyId?.name}</Typography>
          {divider()}
          <Typography style={{ gridColumn: '1' }}>Estimated Pick Up:</Typography>
          <Typography style={{ gridColumn: '2' }}>{dateToString(new Date(data?.truckerLoadApplicationById?.estimatedPickupDate))}</Typography>
          {divider()}
          <Typography style={{ gridColumn: '1' }}>Job:</Typography>
          <Typography style={{ gridColumn: '2' }}>{data?.truckerLoadApplicationById?.woodLoadByWoodLoadId?.jobByJobId?.contractName}</Typography>
          {divider()}
          <Typography style={{ gridColumn: '1' }}>Id On Job:</Typography>
          <Typography style={{ gridColumn: '2' }}>{data?.truckerLoadApplicationById?.woodLoadByWoodLoadId?.idOnJob}</Typography>
          {divider()}
          <Typography style={{ gridColumn: '1' }}>View Wood Load:</Typography>
          <Typography style={{ gridColumn: '2' }}>{<Link to={`/wood_loads/${data?.truckerLoadApplicationById?.woodLoadId}`}>Wood Load</Link>}</Typography>


          {
            data?.truckerLoadApplicationById?.woodLoadByWoodLoadId?.acceptedApplicationId === Number(props.applicationId) ?
              <Typography>This application is accepted for this wood load.</Typography>
              : data?.truckerLoadApplicationById?.woodLoadByWoodLoadId?.acceptedApplicationId && <Typography> A different application was accepted for this wood load</Typography>
          }
          {data?.truckerLoadApplicationById?.woodLoadByWoodLoadId?.acceptedApplicationId && divider()}

          {data?.truckerLoadApplicationById?.cancelledAt && <Typography style={{ gridColumn: '1' }}>Cancelled </Typography>}
          {data?.truckerLoadApplicationById?.cancelledAt && <Typography style={{ gridColumn: '2' }}>{formatDistance(new Date(data?.truckerLoadApplicationById.cancelledAt || new Date()), new Date(), { addSuffix: true })}</Typography>}
          {data?.truckerLoadApplicationById?.cancelledAt && divider()}
          {data?.truckerLoadApplicationById?.cancelledAt && <Typography style={{ gridColumn: '1' }}>Cancelled By:</Typography>}
          {data?.truckerLoadApplicationById?.cancelledAt && <Typography style={{ gridColumn: '2' }}>{<Avatar style={{ marginRight: 5 }} src={data?.truckerLoadApplicationById?.userByCancelledBy?.avatarUrl || ''} />}{data?.truckerLoadApplicationById?.userByCancelledBy?.username}</Typography>}
          {data?.truckerLoadApplicationById?.cancelledAt && divider()}

          {canAccept() && <DeclineButton variant="outlined" onClick={async () => {
            await cancelApplication({
              variables: {
                acceptedApplicationId: null,
                woodLoadId: data?.truckerLoadApplicationById?.woodLoadId || -1
              }
            })
            await withdrawApplication({
              variables: {
                canceledAt: new Date(),
                canceledBy: userContext?.currentUser?.currentUser?.id || -1,
                applicationId: Number(props.applicationId) || -1
              }
            })
            await refetch()
          }}>Decline Application</DeclineButton>}
          {canAccept() && divider()}

          {canAccept() && <PrimaryButton style={{ marginBottom: 10 }} onClick={async () => {
            await acceptApplication({
              variables: {
                woodLoadId: data?.truckerLoadApplicationById?.woodLoadId || -1,
                applicationId: Number(props.applicationId) || -1
              }
            })
            await refetch()
          }}>Accept Application</PrimaryButton>}
        </div>
      </div>
    </Paper>
  </div>
}