import React from 'react'
import queryString from 'query-string'

import AllWoodLoadsComponent from './AllWoodLoads'
import TruckerWoodLoads from './TruckerWoodLoads'
import { Typography } from '@material-ui/core'


export default function WoodLoads() {

  const params = queryString.parse(window.location.search)

  if (params.logger_id || Object.keys(params).length === 0) {
    return <AllWoodLoadsComponent />
  } else if (params.trucker_id) {
    return <TruckerWoodLoads id={params.trucker_id as string} />
  } else {
    return <Typography style={{ color: 'red' }}>Failed to parse wood loads url correctly!</Typography>
  }
}