import {
    styled,
    Table,
    TableCell,
    TableHead,
    Typography,
} from '@material-ui/core';

export const PageTitle = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
});

export const SectionTitle = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
    marginTop: 50,
    marginBottom: 10,
});

export const FieldLabel = styled(Typography)({
    color: '#505050',
});

export const StyledWaldoTable = styled(Table)({
    borderSpacing: 0,
    width: '100%',
});

export const WaldoTableHead = styled(TableHead)({
    borderBottom: '2px solid #ddd',
    padding: '10px',
    background: '#7b9bad',
    textAlign: 'center',
});

export const WaldoTableCell = styled(TableCell)({
    color: 'white',
    fontWeight: 'bold',
});