import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Typography, Avatar, Paper } from '@material-ui/core';
import { UserQuery, UserQueryVariables } from './generated/UserQuery'
import { Link } from 'react-router-dom';

const USER_QUERY = gql`query UserQuery($userId: Int!) {
    userById(id: $userId) {
      avatarUrl
      bio
      id
      username
      phoneNumber
      createdAt
      woodLoadsByLoggerUserId {
        totalCount
      }
      companyName
      truckerLoadApplicationsByUserId {
        totalCount
      }
    }
  }
  
`

interface Props {
    userId: string
}
function formatPhoneNumber(n: string | null | undefined) {
    if (n) {
        n = n.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        return n
    }
    else { return '' }
}

export default function UserProfile(props: Props) {
    const { data } = useQuery<UserQuery, UserQueryVariables>(USER_QUERY, {
        variables: {
            userId: Number(props.userId)
        }
    });
    const id = data?.userById?.id
    return <div style={{ textAlign: 'center', }}>
        <Paper className="paperComponent" style={{ margin: 'auto', padding: 15 }}>
            <div style={{ paddingTop: 30, }}>
                <Avatar style={{ width: 150, height: 150, margin: 'auto' }} src={data?.userById?.avatarUrl || ''} />
            </div>
            <div>
                <Typography style={{ fontWeight: 600, marginTop: 20, }} >{data?.userById?.companyName} ({data?.userById?.username})</Typography>
            </div>
            <div>
                <Typography>{formatPhoneNumber(data?.userById?.phoneNumber)}</Typography>
            </div>
            <div>
                <Typography>{data?.userById?.bio}</Typography>
            </div>
            {(data?.userById?.woodLoadsByLoggerUserId?.totalCount !== (0)) && <Link to={'/wood_loads?logger_id=' + id}>
                <div
                    style={{ cursor: 'pointer', textAlign: 'center' }}
                >
                    <Typography style={{ color: 'blue', paddingBottom: 5 }}>View All Wood Loads (Logger)</Typography>
                </div>
            </Link >

            }
            {
                (data?.userById?.truckerLoadApplicationsByUserId.totalCount !== 0) && <Link to={'/wood_loads?trucker_id=' + id}>
                    <div
                        style={{ cursor: 'pointer', textAlign: 'center' }}
                    >
                        <Typography style={{ color: 'blue', paddingBottom: 40 }}>View All Wood Loads (Trucker)</Typography>
                    </div>
                </Link>
            }
        </Paper>
    </div >
}