import React, { useState, useContext } from 'react'
import { Typography, Button, IconButton, Checkbox } from '@material-ui/core'
import gql from 'graphql-tag'
import CreateEditWoodLoadState from './CreateEditWoodLoadState'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
    GetWoodLoadStates,
    GetWoodLoadStatesVariables,
    GetWoodLoadStates_companyById_woodLoadStatesByCompanyId_nodes as WoodLoadState
} from '../generated/GetWoodLoadStates'
import WaldoTable from '../WaldoTable'
import { isPresent } from '../utils'
import EditIcon from '@material-ui/icons/Edit'
import ArchiveIcon from '@material-ui/icons/Archive'
import ConfirmationDialog from '../ConfirmationDialog'
import { IncludeArchivedOption } from '../generated/globalTypes'
import { ArchiveWoodLoadState, ArchiveWoodLoadStateVariables } from '../generated/ArchiveWoodLoadState'
import HistoryContext from '../HistoryContext'
import { PageTitle } from '../WaldoStyle'


export const WOOD_LOAD_STATES = gql`query GetWoodLoadStates(
    $companyId: Int!
    $includeArchived: IncludeArchivedOption
  ) {
    companyById(id: $companyId) {
      woodLoadStatesByCompanyId(includeArchived: $includeArchived) {
        nodes {
          color
          createdAt
          id
          name
          woodLoadStateEntriesByStateId {
            totalCount
          }
        }
      }
    }
  }
  
  `

const ARCHIVE = gql`mutation ArchiveWoodLoadState($id: Int!, $archivedAt: Datetime) {
    __typename
    updateWoodLoadStateById(
      input: { woodLoadStatePatch: { archivedAt: $archivedAt }, id: $id }
    ) {
      clientMutationId
    }
  }
  `

interface Props {
    companyId: number
}




export default function WoodLoadStates(props: Props) {
    const historyContext = useContext(HistoryContext)

    const [newStateOpen, setNewStateOpen] = useState<boolean>(false)
    const [stateToEdit, setStateToEdit] = useState<WoodLoadState | undefined>(undefined)
    const [viewingArchived, setViewingArchived] = useState<boolean>(false)

    const { data, refetch } = useQuery<GetWoodLoadStates, GetWoodLoadStatesVariables>(WOOD_LOAD_STATES, {
        variables: {
            companyId: props.companyId,
            includeArchived: viewingArchived ? IncludeArchivedOption.EXCLUSIVELY : undefined
        }
    })

    const [stateToArchive, setStateToArchive] = useState<WoodLoadState | undefined>(undefined)

    const [archive] = useMutation<ArchiveWoodLoadState, ArchiveWoodLoadStateVariables>(ARCHIVE)


    return <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <PageTitle>WOOD LOAD STATES <Button
                color="primary"
                variant="contained"
                onClick={() => {
                    setNewStateOpen(true)
                }}
            >+ New Load State</Button></PageTitle>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography>View Archived</Typography>
                <Checkbox
                    value={viewingArchived}
                    onChange={event => setViewingArchived(event.target.checked)}
                />
            </div>
        </div>

        <WaldoTable
            data={data?.companyById?.woodLoadStatesByCompanyId.nodes.filter(isPresent) || []}
            columns={[{
                header: 'Name',
                render: (r) => r.name
            },
            {
                header: 'Color',
                render: (r) => <div>
                    <div style={{ backgroundColor: r.color || undefined, borderRadius: 3 }}>
                        <Typography>{r.color}</Typography>
                    </div>
                </div>
            },
            {
                header: 'Actions',
                render: (r) => <>
                    <IconButton
                        style={{ width: 60 }}
                        onClick={(event) => {
                            event.stopPropagation()
                            setStateToEdit(r)
                        }}>
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        style={{ width: 60 }}
                        onClick={(event) => {
                            event.stopPropagation()
                            setStateToArchive(r)
                        }}>
                        <ArchiveIcon />
                    </IconButton>
                </>
            }
            ]}
            onClickRow={r => historyContext?.history?.push(`/companies/${props.companyId}/wood_load_states/${r.id}`)}
        />

        {
            newStateOpen && <CreateEditWoodLoadState
                open={newStateOpen}
                setOpen={async () => {
                    await refetch()
                    setNewStateOpen(false)
                }}
                companyId={props.companyId}
            />
        }
        {
            stateToEdit !== undefined && <CreateEditWoodLoadState
                open={stateToEdit !== undefined}
                setOpen={async () => {
                    await refetch()
                    setStateToEdit(undefined)
                }}
                companyId={props.companyId}
                woodLoadState={stateToEdit}
            />
        }
        {stateToArchive !== undefined && <ConfirmationDialog
            title="Confirm"
            message={`Confirm ${viewingArchived ? 'un-archive' : 'archive'} of State`}
            onConfirm={async () => {
                await archive({
                    variables: {
                        id: stateToArchive?.id || -1,
                        archivedAt: viewingArchived ? null : new Date()
                    }
                })
            }}
            open={stateToArchive !== undefined}
            setOpen={async () => {
                await refetch()
                setStateToArchive(undefined)
            }}
        />
        }
    </div>
}