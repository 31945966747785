import React, { useState, useContext } from 'react'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Plot from 'react-plotly.js'
import {
    JobQuery,
    JobQueryVariables,
    JobQuery_jobById_companyByCompanyId_jobFieldSettingsByCompanyId_nodes as JobFieldSetting,
} from './generated/JobQuery'

import { Paper, Typography } from '@material-ui/core'
import { formatDistanceStrict } from 'date-fns'
import { WOOD_LOAD_FRAGMENT } from './AllWoodLoads'
import { isPresent } from './utils'
import lastDayOfMonth from 'date-fns/lastDayOfMonth'
import startOfWeek from 'date-fns/startOfWeek'
import subtractTime from 'date-fns/sub'
import { Link } from 'react-router-dom'
import WoodLoadsTable from './WoodLoads/WoodLoadsTable'
import { RectangleButton, SecondaryTitle } from './styles'
import { PageTitle } from './WaldoStyle'
import CommentSection from './CommentSection'
import {
    CreateJobComment,
    CreateJobCommentVariables,
} from './generated/CreateJobComment'
import { UserContext } from './UserContext'
import { ConfigurableJobField } from './Company/ConfigureJobFields'

const JOB = gql`
    query JobQuery($id: Int!, $before: Datetime, $after: Datetime) {
        jobById(id: $id) {
            contractName
            contractNumber
            jobCrewsByJobId {
                totalCount
            }
            companyByCompanyId {
                id
                name
                userJobFieldsByCompanyId {
                    nodes {
                        id
                        name
                    }
                }
                jobFieldSettingsByCompanyId {
                    nodes {
                        id
                        index
                        hide
                        nativeFieldName
                        userJobFieldId
                    }
                }
            }
            county
            createdAt
            id
            range2
            township
            section
            woodLoadsByJobId(
                orderBy: CREATED_AT_DESC
                filter: {
                    createdAt: {
                        greaterThanOrEqualTo: $after
                        lessThanOrEqualTo: $before
                    }
                }
            ) {
                nodes {
                    ...WoodLoadFragment
                }
                totalCount
            }
            jobStart
            jobEnd
            jobCommentsByJobId {
                nodes {
                    id
                    userByCreatorId {
                        id
                        username
                        avatarUrl
                    }
                    content
                    createdAt
                }
            }
            userJobFieldValuesByJobId {
                nodes {
                    id
                    userJobFieldId
                    value
                }
            }
        }
    }
    ${WOOD_LOAD_FRAGMENT}
`

const CREATE_COMMENT = gql`
    mutation CreateJobComment(
        $jobId: Int!
        $creatorId: Int!
        $content: String!
    ) {
        __typename
        createJobComment(
            input: {
                jobComment: {
                    jobId: $jobId
                    creatorId: $creatorId
                    content: $content
                }
            }
        ) {
            clientMutationId
        }
    }
`

interface Dates {
    start: Date
    end: Date
}

interface Props {
    jobId: number
}

export function pastSevenDays(): Dates {
    const start = subtractTime(new Date(), { days: 7 })
    start.setHours(0)
    start.setMinutes(0)
    start.setSeconds(0)
    const end = new Date()
    end.setHours(0)
    end.setMinutes(0)
    end.setSeconds(0)
    const startEnd: Dates = { start: start, end: end }
    return startEnd
}

export function lastWeek(): Dates {
    let lastWeekDate = new Date()
    lastWeekDate = subtractTime(lastWeekDate, { days: 7 })
    const start = startOfWeek(lastWeekDate, { weekStartsOn: 1 })
    start.setHours(0)
    start.setMinutes(1)
    const end = subtractTime(start, { days: -7 })
    const startEnd: Dates = { start: start, end: end }
    return startEnd
}

export function prevMonth(): Dates {
    const lastMonthDate = new Date()
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1)

    const start = new Date(lastMonthDate.getTime())
    start.setDate(1)
    start.setHours(0)
    start.setMinutes(1)

    let end = new Date(lastMonthDate.getTime())
    end = lastDayOfMonth(end)
    end.setHours(23)
    end.setMinutes(59)
    const startEnd: Dates = { start: start, end: end }

    return startEnd
}
function divider() {
    return (
        <div
            style={{
                height: 2,
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                gridColumnStart: '1',
                gridColumnEnd: '3',
                borderColor: 'lightgrey',
            }}
        />
    )
}

export default function SingleJob(props: Props) {
    const userContext = useContext(UserContext)

    const [woodLoadsChoice, setWoodLoadsChoice] = useState<
        'Past Seven Days' | 'Last Week' | 'Last Month' | 'All Wood Loads'
    >('All Wood Loads')

    const [startDate, setStartDate] = useState<Date | undefined>(undefined)
    const [endDate, setEndDate] = useState<Date | undefined>(undefined)

    const { data, refetch } = useQuery<JobQuery, JobQueryVariables>(JOB, {
        variables: {
            id: Number(props.jobId),
            before: endDate,
            after: startDate,
        },
    })

    const [createComment] = useMutation<
        CreateJobComment,
        CreateJobCommentVariables
    >(CREATE_COMMENT)

    function getXAxis(): Date[] {
        let start: Date
        let end: Date
        if (startDate && endDate) {
            start = new Date(startDate)
            end = new Date(endDate)
        } else {
            start = new Date(data?.jobById?.createdAt)
            end = new Date() //data?.jobById?.jobEnd)
        }
        let loop = start
        let increment: number
        const dateArray: Date[] = []
        let month = false
        if (subtractTime(end, { days: 8 }) < start) {
            increment = 1
        } else {
            increment = 7
            month = true
        }
        while (loop <= end) {
            dateArray.push(loop)
            const newDate = new Date(loop.getTime()).setDate(
                loop.getDate() + increment
            )
            loop = new Date(newDate)
        }
        month && dateArray.push(end)
        month && dateArray.push(end)
        return dateArray
    }

    function getYAxis() {
        const dates: Date[] = getXAxis()
        const loads: number[] = []
        let count = 1
        for (count; count < dates.length; count++) {
            let amount = 0
            for (
                let idx = 0;
                idx < (data?.jobById?.woodLoadsByJobId.nodes.length || 0);
                idx = idx + 1
            ) {
                const e = data?.jobById?.woodLoadsByJobId.nodes[idx]
                const temp = new Date(e?.createdAt)
                if (temp > dates[count - 1] && temp < dates[count]) {
                    amount++
                }
            }

            loads.push(amount)
        }
        return loads
    }

    function findJobFieldSetting(apiFieldName: ConfigurableJobField) {
        return (
            data?.jobById?.companyByCompanyId?.jobFieldSettingsByCompanyId.nodes.find(
                (n) => n?.nativeFieldName === apiFieldName
            ) ?? undefined
        )
    }

    let jobFields: {
        apiName?: ConfigurableJobField
        userJobFieldId?: number
        jobFieldSetting?: JobFieldSetting
        render: () => JSX.Element
    }[] = [
        {
            apiName: 'contractName',
            jobFieldSetting: findJobFieldSetting('contractName'),
            render: () => (
                <>
                    <Typography style={{ gridColumn: '1' }}>
                        Contract:
                    </Typography>
                    <Typography style={{ gridColumn: '2' }}>
                        {data?.jobById?.contractName}
                    </Typography>
                </>
            ),
        },
        {
            apiName: 'county',
            jobFieldSetting: findJobFieldSetting('county'),
            render: () => (
                <>
                    <Typography style={{ gridColumn: '1' }}>County:</Typography>
                    <Typography style={{ gridColumn: '2' }}>
                        {data?.jobById?.county}
                    </Typography>
                </>
            ),
        },
        {
            apiName: 'township',
            jobFieldSetting: findJobFieldSetting('township'),
            render: () => (
                <>
                    <Typography style={{ gridColumn: '1' }}>
                        Township:
                    </Typography>
                    <Typography style={{ gridColumn: '2' }}>
                        {data?.jobById?.township}
                    </Typography>
                </>
            ),
        },
        {
            apiName: 'range2',
            jobFieldSetting: findJobFieldSetting('range2'),

            render: () => (
                <>
                    <Typography style={{ gridColumn: '1' }}>Range:</Typography>
                    <Typography style={{ gridColumn: '2' }}>
                        {data?.jobById?.range2}
                    </Typography>
                </>
            ),
        },
        {
            apiName: 'section',
            jobFieldSetting: findJobFieldSetting('section'),

            render: () => (
                <>
                    <Typography style={{ gridColumn: '1' }}>
                        Section:
                    </Typography>
                    <Typography style={{ gridColumn: '2' }}>
                        {data?.jobById?.section}
                    </Typography>
                </>
            ),
        },
    ]

    for (const userField of data?.jobById?.companyByCompanyId?.userJobFieldsByCompanyId.nodes.filter(
        isPresent
    ) ?? []) {
        jobFields.push({
            userJobFieldId: userField.id,
            jobFieldSetting:
                data?.jobById?.companyByCompanyId?.jobFieldSettingsByCompanyId.nodes.find(
                    (n) => n?.userJobFieldId === userField.id
                ) ?? undefined,
            render: () => (
                <>
                    <Typography style={{ gridColumn: '1' }}>
                        {userField.name}:
                    </Typography>
                    <Typography style={{ gridColumn: '2' }}>
                        VALUE GOES HERE!!
                    </Typography>
                </>
            ),
        })
    }

    jobFields = jobFields.filter((jf) => !jf.jobFieldSetting?.hide)

    jobFields.sort((a, b) => {
        const aIdx = a.jobFieldSetting?.index
        const bIdx = b.jobFieldSetting?.index
        if (isPresent(aIdx) && isPresent(bIdx)) {
            return aIdx - bIdx
        } else if (aIdx) {
            return -1
        } else if (bIdx) {
            return 1
        } else {
            return 0
        }
    })

    return (
        <div>
            <PageTitle>JOB</PageTitle>
            <div style={{ textAlign: 'center', marginTop: 70 }}>
                <Paper className="paperComponent">
                    <div
                        style={{
                            display: 'inline-grid',
                            gridTemplateColumns: 'auto auto',
                            gridRowGap: 8,
                            textAlign: 'left',
                            alignItems: 'center',
                            gridColumnGap: 10,
                            padding: 15,
                        }}
                    >
                        <Typography style={{ gridColumn: '1' }}>
                            Company:
                        </Typography>
                        <Typography style={{ gridColumn: '2' }}>
                            <Link
                                to={`/companies/${data?.jobById?.companyByCompanyId?.id}`}
                            >
                                {' '}
                                {data?.jobById?.companyByCompanyId?.name}
                            </Link>
                        </Typography>
                        {divider()}
                        <Typography style={{ gridColumn: '1' }}>
                            Created:
                        </Typography>
                        <Typography style={{ gridColumn: '2' }}>
                            {data?.jobById?.contractName &&
                                formatDistanceStrict(
                                    new Date(data?.jobById?.createdAt),
                                    new Date(),
                                    { addSuffix: true }
                                )}
                        </Typography>
                        {divider()}

                        {jobFields.map((jf) => (
                            <>
                                {jf.render()}
                                {divider()}
                            </>
                        ))}

                        <Typography style={{ gridColumn: '1' }}>
                            Crews:
                        </Typography>
                        <Link
                            to={`/jobs/${data?.jobById?.id}/crews`}
                            style={{ gridColumn: '2' }}
                        >
                            {data?.jobById?.jobCrewsByJobId?.totalCount}
                        </Link>
                        {divider()}

                        <Link
                            to={`/jobs/${data?.jobById?.id}/map`}
                            style={{ gridColumnStart: '1', gridColumnEnd: '3' }}
                        >
                            View Map
                        </Link>
                    </div>
                </Paper>
                <div style={{ textAlign: 'left', marginTop: 50 }}>
                    <SecondaryTitle>Wood Loads</SecondaryTitle>
                </div>
                <div style={{ marginBottom: 5 }}>
                    <div style={{ marginTop: 10 }}>
                        <RectangleButton
                            variant="outlined"
                            style={{
                                color:
                                    woodLoadsChoice === 'Past Seven Days'
                                        ? 'white'
                                        : 'black',
                                backgroundColor:
                                    woodLoadsChoice === 'Past Seven Days'
                                        ? '#3172B4'
                                        : 'white',
                            }}
                            onClick={() => {
                                setWoodLoadsChoice('Past Seven Days')
                                setStartDate(pastSevenDays().start)
                                setEndDate(pastSevenDays().end)
                            }}
                        >
                            Past Seven Days
                        </RectangleButton>

                        <RectangleButton
                            variant="outlined"
                            style={{
                                color:
                                    woodLoadsChoice === 'Last Week'
                                        ? 'white'
                                        : 'black',
                                backgroundColor:
                                    woodLoadsChoice === 'Last Week'
                                        ? '#3172B4'
                                        : 'white',
                            }}
                            onClick={() => {
                                setWoodLoadsChoice('Last Week')
                                setStartDate(lastWeek().start)
                                setEndDate(lastWeek().end)
                            }}
                        >
                            Last Week
                        </RectangleButton>

                        <RectangleButton
                            variant="outlined"
                            style={{
                                color:
                                    woodLoadsChoice === 'Last Month'
                                        ? 'white'
                                        : 'black',
                                backgroundColor:
                                    woodLoadsChoice === 'Last Month'
                                        ? '#3172B4'
                                        : 'white',
                            }}
                            onClick={() => {
                                setWoodLoadsChoice('Last Month')
                                setStartDate(prevMonth().start)
                                setEndDate(prevMonth().end)
                            }}
                        >
                            Last Month
                        </RectangleButton>

                        <RectangleButton
                            variant="outlined"
                            style={{
                                color:
                                    woodLoadsChoice === 'All Wood Loads'
                                        ? 'white'
                                        : 'black',
                                backgroundColor:
                                    woodLoadsChoice === 'All Wood Loads'
                                        ? '#3172B4'
                                        : 'white',
                            }}
                            onClick={() => {
                                setWoodLoadsChoice('All Wood Loads')
                                setStartDate(undefined)
                                setEndDate(undefined)
                            }}
                        >
                            All Wood Loads
                        </RectangleButton>
                    </div>
                </div>

                <div style={{ textAlign: 'center' }}>
                    {data?.jobById?.woodLoadsByJobId?.nodes && (
                        <WoodLoadsTable
                            data={(
                                data?.jobById?.woodLoadsByJobId?.nodes || []
                            ).filter(isPresent)}
                            total={data.jobById.woodLoadsByJobId.totalCount}
                            setFirst={() => {}}
                            setFrom={() => {}}
                        />
                    )}
                </div>
            </div>
            <div style={{ textAlign: 'center', marginTop: 20 }}>
                <Plot
                    data={[
                        {
                            x: getXAxis(),
                            y: getYAxis(),
                            type: 'scatter',
                            mode: 'lines',
                            marker: { color: 'red' },
                        },
                    ]}
                    layout={{
                        title: 'Loads Over Time',
                        xaxis: { title: 'Time' },
                        yaxis: { title: 'Wood Loads' },
                        width: 600,
                        height: 400,
                        plot_bgcolor: '#F8F8F8',
                    }}
                />
            </div>

            <CommentSection
                comments={
                    data?.jobById?.jobCommentsByJobId.nodes.filter(isPresent) ||
                    []
                }
                createComment={async (text: string) => {
                    await createComment({
                        variables: {
                            jobId: props.jobId,
                            creatorId:
                                userContext?.currentUser.currentUser?.id || -1,
                            content: text,
                        },
                    })
                    await refetch()
                }}
            />
        </div>
    )
}
