import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import { Avatar, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import {
  GetCompanies,
  GetCompanies_currentUser_companiesByCreatorId_nodes as Company
} from '../generated/GetCompanies'
import { isPresent } from '../utils'
import { PageTitle } from '../WaldoStyle'
import HistoryContext from '../HistoryContext'

const COMPANIES = gql`query GetCompanies {
    currentUser {
      crewMembersByUserId {
        nodes {
          crewByCrewId {
            companyByCompanyId {
              id
              avatarUrl
              name
            }
          }
        }
      }
      companiesByCreatorId {
        nodes {
          avatarUrl
          id
          name
        }
      }
    }
  }
  
`




export default function CompaniesComponent() {
  const historyContext = useContext(HistoryContext)

  const { data } = useQuery<GetCompanies>(COMPANIES)

  function getCompanies(): Company[] {
    let Companies: Company[] = [
      ...(data?.currentUser?.companiesByCreatorId.nodes.filter(isPresent) || []),
      ...(data?.currentUser?.crewMembersByUserId.nodes.map(n => n?.crewByCrewId?.companyByCompanyId).filter(isPresent) || [])
    ]

    Companies = Companies.filter((el, idx, arr) => arr.findIndex(el2 => el2.id === el.id) === idx)

    return Companies
  }



  return <div>
    <PageTitle>COMPANIES</PageTitle>
    <List>
      {getCompanies().map((company) => {
        return (
          <><><ListItem style={{ backgroundColor: '#f2f2f2' }}><Typography style={{ fontSize: 15, color: 'black', marginBottom: 5 }}>Name</Typography></ListItem>
            <ListItem button onClick={() => {

              historyContext.history?.push(`/companies/${company.id}`)

            }}>
              <Avatar style={{ marginRight: 5 }} src={company?.avatarUrl || ''} />
              <ListItemText primary={`${company.name}`} />
            </ListItem></>
          </>
        );
      })}
    </List>
  </div>

}