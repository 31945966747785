import React, { useState, useCallback, useContext } from 'react'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { UserContext } from '../UserContext'
// import { Company, CompanyVariables } from '../generated/Company'
// import { UpdateCompany, UpdateCompanyVariables } from '../generated/UpdateCompany'
import { Button, Typography, TextField, Paper, Avatar, Checkbox } from '@material-ui/core'

import { server_url, server } from '../createClient'
import axios from 'axios'

import { usePlaidLink } from 'react-plaid-link';

import BankModal from './BankModal'
import { PrimaryButton } from '../styles'
import { Link } from 'react-router-dom'
import { JOB_OWNER_ROLE, TRUCKER_ROLE, LOGGER_ROLE } from './WoodLoads'
import { UpdateDefaultCompany, UpdateDefaultCompanyVariables } from '../generated/UpdateDefaultCompany'
// import { useElements, useStripe } from '@stripe/react-stripe-js'

const COMPANY = gql`query Company($id: Int!) {
    companyById(id: $id) {
      id
      bio
      name
      avatarUrl
      companyPrivateByCompanyId {
        companyId
        selectedStripePaymentMethodId
        stripeCustomerId
        stripeConnectedAccountId
        stripePaymentMethods {
          brand
          id
          last4
        }
        stripeLoginLink {
          created
          object
          url
        }
        stripeCustomer {
          defaultSourceBankAccount {
            bankName
            last4
            status
          }
          id
        }
      }
      jobsByCompanyId {
        totalCount
        nodes {
            woodLoadsByJobId {
                totalCount
            }
        }
      }
      truckerLoadApplicationsByCompanyId(
        filter: { woodLoadsByAcceptedApplicationIdExist: true }
      ) {
        totalCount
      }
      woodLoadsByLoggingCompanyId {
        totalCount
      }
      haulContractsByOwnerCompanyId {
        totalCount
      }
      woodLoadStatesByCompanyId {
        totalCount
      }
      woodLoadDestinationsByCompanyId {
        totalCount
      }
      woodSpeciesByCompanyId {
        totalCount
      }
      publicCompanyFilesByCompanyId {
        totalCount
      }
    }
    currentUser {
      id
      companyByDefaultCompanyId {
        id
        name
      }
    }
  }
  `

const UPDATE_DEFAULT_COMPANY = gql`mutation UpdateDefaultCompany($userId: Int!, $companyId: Int) {
    __typename
    updateUserById(
      input: { userPatch: { defaultCompanyId: $companyId }, id: $userId }
    ) {
      clientMutationId
    }
  }
  `


interface Props {
    companyId: string

}

function divider() {
    return <div style={{
        height: 2,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        gridColumnStart: '1',
        gridColumnEnd: '3',
        borderColor: 'lightgrey'
    }} />
}


export default function CompanyComponent(props: Props) {

    const userContext = useContext(UserContext)

    // const stripe = useStripe();
    // const elements = useElements();

    const { data, refetch } = useQuery<any, any>(COMPANY, {
        variables: {
            id: Number(props.companyId)
        },
        notifyOnNetworkStatusChange: true
    })
    const [connectBankModalOpen, setConnectBankModalOpen] = useState<boolean>(false)
    const [achErrorMessage, setAchErrorMessage] = useState<string>('')

    // const [updateCompany, { loading: updateLoading }] = useMutation<UpdateCompany, UpdateCompanyVariables>(UPDATE_COMPANY)
    const [errorMessage, setErrorMessage] = useState<string>('')
    // const [nameOnCard, setNameOnCard] = useState<string>('')
    // const [processingStripe, setProcessingStripe] = useState<boolean>(false)



    const [verificationAmount1, setVerificationAmount1] = useState<string>('')
    const [verificationAmount2, setVerificationAmount2] = useState<string>('')
    let woodLoadCount = 0;

    data?.companyById?.jobsByCompanyId?.nodes?.forEach((n:any) => {
        woodLoadCount += n.woodLoadsByJobId.totalCount
        console.log('n')
    })

    const [updateDefaultCompany] = useMutation<UpdateDefaultCompany, UpdateDefaultCompanyVariables>(UPDATE_DEFAULT_COMPANY)

    const onSuccess = useCallback(async (token, metadata) => {

        // might already be created, let er rip anyway

        await axios.post(`${server_url}/api/create_stripe_customer`, {
            companyId: Number(props.companyId)
        },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })


        const res = await axios.post(`${server_url}/api/setup_stripe_ach_account_via_plaid`,
            {
                companyId: Number(props.companyId),
                plaidLinkPublicToken: token,
                accountId: metadata.account_id
            }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })

            if (res.data.error) {

            setErrorMessage(res.data.error)
        }


    }, [props.companyId]);


    const { open, ready } = usePlaidLink({
        publicKey: '52844436d35b6ec826b23da831b400',
        onSuccess,
        clientName: 'Waldo',
        env: localStorage.getItem('waldo_server') === 'Prod' ? 'development' : 'sandbox',
        product: ['auth'],
    });


    let stripeKey = 'ca_HcFhTnQeHuSy7dJ30pEzTaULsAtmvbXu'

    if (server !== 'Prod') {
        stripeKey = 'ca_HcFhnkuPsIwSxqqd2G6U4kbtZRHXQj5w'
    }

    // const handleSubmit = async (event: any) => {
    //     setProcessingStripe(true)
    //     console.log('here')
    //      We don't want to let default form submission happen here,
    //     which would refresh the page.
    //     event.preventDefault();

    //     if (!stripe || !elements) {
    //         Stripe.js has not yet loaded.
    //         Make sure to disable form submission until Stripe.js has loaded.
    //         setErrorMessage('Stripe is not loaded!')
    //         return;
    //     }

    //     try {
    //         const res1 = await axios.post(`${server_url}/api/create_stripe_customer`, {
    //             companyId: Number(props.companyId)
    //         },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${localStorage.getItem('token')}`
    //                 }
    //             })

    //         if (res1.data.error) {
    //             throw new Error(res1.data.error)
    //             setErrorMessage(res1.data.error)
    //         }


    //         get stripe token from endpoint
    //         const res = await axios.post(`${server_url}/api/create_stripe_setup_intent`, {
    //             companyId: Number(props.companyId)
    //         },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${localStorage.getItem('token')}`
    //                 }
    //             })

    //         if (res.data.error) {
    //             throw new Error(res.data.error)
    //         }


    //         const el = elements.getElement(CardElement)

    //         if (el) {
    //             const result = await stripe.confirmCardSetup(res.data.client_secret, {
    //                 payment_method: {
    //                     card: el,
    //                     billing_details: {
    //                         //name: nameOnCard,
    //                     },
    //                 }
    //             });

    //             await refetch()

    //             if (result.error) {
    //                 Display result.error.message in your UI.
    //                 setErrorMessage(result.error.message || '')
    //             } else {
    //                 The setup has succeeded. Display a success message and send
    //                 result.setupIntent.payment_method to your server to save the
    //                 card to a Customer
    //                 setErrorMessage('')
    //             }
    //         }

    //         setProcessingStripe(false)
    //     } catch (e) {
    //         setErrorMessage(e.toString())
    //     }




    // };



    const activatedAccount = data?.companyById?.companyPrivateByCompanyId?.stripeCustomer?.defaultSourceBankAccount


    return <div style={{ width: 600, margin: 'auto', paddingTop: 30 }}>
        <div style={{ justifyContent: 'center', display: 'flex' }}>
            <Avatar style={{ marginRight: 5, marginBottom: 15, width: 50, height: 50 }} src={data?.companyById?.avatarUrl || ''} />
            <div style={{ marginTop: 15, fontSize: 15, fontWeight: 'bold' }}>
                {data?.companyById?.name}
            </div>
        </div>
        <Paper>
            <div style={{ textAlign: 'center', maxWidth: 500, width: '95%', margin: 'auto' }}>
                <div style={{ marginTop: 20, display: 'inline-grid', gridTemplateColumns: 'auto auto', gridRowGap: 8, textAlign: 'left', alignItems: 'center', gridColumnGap: 10 }}>
                    <Typography style={{ gridColumn: '1' }}>Name:</Typography>
                    <Typography style={{ gridColumn: '2' }}>{data?.companyById?.name}</Typography>
                    {divider()}
                    <Typography style={{ gridColumn: '1' }}>Bio:</Typography>
                    <Typography style={{ gridColumn: '2' }}>{data?.companyById?.bio}</Typography>
                    {divider()}
                    <Typography style={{ gridColumn: '1' }}>My Default Company:</Typography>
                    <div style={{ gridColumn: '2', alignSelf: 'left' }}>
                        <Checkbox
                            checked={data?.currentUser?.companyByDefaultCompanyId?.id === parseInt(props.companyId, 10)}
                            onChange={async (event) => {
                                await updateDefaultCompany({
                                    variables: {
                                        companyId: event.target.checked ? parseInt(props.companyId, 10) : null,
                                        userId: userContext?.currentUser.currentUser?.id || -1
                                    }
                                })
                                await refetch()
                            }}
                        />
                    </div>
                    {divider()}
                    {(data?.companyById?.jobsByCompanyId.totalCount || 0) > 0 && <>
                        <Link to={`/companies/${props.companyId}/jobs`}>Jobs ({data?.companyById?.jobsByCompanyId.totalCount})</Link>
                        {divider()}
                    </>}

                    <Link to={`/companies/${props.companyId}/destinations`}>Destinations ({data?.companyById?.woodLoadDestinationsByCompanyId.totalCount})</Link>
                    {divider()}

                    <Link to={`/companies/${props.companyId}/species`}>Species ({data?.companyById?.woodSpeciesByCompanyId.totalCount})</Link>
                    {divider()}

                    <Link to={`/companies/${props.companyId}/haul_contracts`}>Haul Contracts ({data?.companyById?.haulContractsByOwnerCompanyId.totalCount})</Link>
                    {divider()}

                    {(data?.companyById?.jobsByCompanyId.totalCount || 0) > 0 && <>
                        <Link to={`/companies/${props.companyId}/wood_loads?role=${JOB_OWNER_ROLE}`}>Wood Loads ({woodLoadCount})</Link>
                        {divider()}
                    </>
                    }

                    {
                        (data?.companyById?.truckerLoadApplicationsByCompanyId.totalCount || 0) > 0 && <>
                            <Link to={`/companies/${props.companyId}/wood_loads?role=${TRUCKER_ROLE}`}>Trucker Loads ({data?.companyById?.truckerLoadApplicationsByCompanyId.totalCount})</Link>
                            {divider()}
                        </>
                    }

                    {
                        (data?.companyById?.woodLoadsByLoggingCompanyId.totalCount || 0) > 0 && <>
                            <Link to={`/companies/${props.companyId}/wood_loads?role=${LOGGER_ROLE}`}>Logger Loads ({data?.companyById?.woodLoadsByLoggingCompanyId.totalCount})</Link>
                            {divider()}
                        </>
                    }


                    <Link to={`/companies/${props.companyId}/wood_load_states`}>Load States ({data?.companyById?.woodLoadStatesByCompanyId.totalCount})</Link>
                    {divider()}

                    <Link to={`/companies/${props.companyId}/public_files`}>Public Files ({data?.companyById?.publicCompanyFilesByCompanyId.totalCount})</Link>
                    {divider()}

                    <Link to={`/companies/${props.companyId}/job_fields`}>Configure Job Fields</Link>
                    {divider()}
                    <Link to={`/companies/${props.companyId}/wood_load_fields`}>Configure Wood Load Fields</Link>
                    {divider()}
                </div>

                <div style={{ display: 'none' }}>
                {/* <div style={{
                    width: '90%',

                    alignSelf: 'center',
                    marginTop: 20,
                    display: 'inline-block',
                    borderWidth: 1,
                    borderColor: 'grey',
                    borderRadius: 5,
                    borderStyle: 'solid',
                    padding: 10,
                }}>
                    <Typography>Make Payments on Waldo:</Typography>

                    <List
                        style={{ marginBottom: 40 }}
                        header={<div>Cards on File:</div>}
                        bordered
                        dataSource={data?.companyById?.companyPrivateByCompanyId?.stripePaymentMethods.filter(isPresent) || []}
                        renderItem={item => (
                            <List.Item>
                                <Typography>{item.brand} ending in {item.last4} {
                                    data?.companyById?.companyPrivateByCompanyId?.selectedStripePaymentMethodId === item.id &&
                                    <Typography style={{ color: 'green' }}>Activated</Typography>
                                }</Typography>





                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {data?.companyById?.companyPrivateByCompanyId?.selectedStripePaymentMethodId !== item.id ?
                                        <Button
                                            disabled={updateLoading || networkStatus !== NetworkStatus.ready}
                                            style={{ marginRight: 10 }} onClick={async () => {
                                                await updateCompany({
                                                    variables: {
                                                        companyId: Number(props.companyId),
                                                        selectedStripePaymentMethodId: item.id
                                                    }
                                                })
                                                await refetch()
                                            }}>Set As Activated Card</Button>
                                        :
                                        <Button
                                            disabled={updateLoading || networkStatus !== NetworkStatus.ready}
                                            style={{ marginRight: 10 }} onClick={async () => {
                                                await updateCompany({
                                                    variables: {
                                                        companyId: Number(props.companyId),
                                                        selectedStripePaymentMethodId: null
                                                    }
                                                })
                                                await refetch()
                                            }}>Deactivate Card</Button>
                                    }
                                </div>
                            </List.Item>
                        )}
                    />

                    <form onSubmit={handleSubmit}>
                        <CardElement options={CARD_ELEMENT_OPTIONS} />
                        <Typography>Name:</Typography>
                        <TextField placeholder="John Doe" style={{ marginTop: 15 }} value={nameOnCard} onChange={(event) => { setNameOnCard(event.target.value) }} />
                        <Button
                            disabled={!stripe || processingStripe}
                            style={{ marginTop: 20 }}
                        >Submit Payment Method</Button>
                    </form>

                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    {!stripe && <p style={{ color: 'red' }}>Loading Stripe...</p>}
                    {processingStripe && <p style={{ color: 'red' }}>Processing Card...</p>}


                </div> */}
             </div>

                <div style={{
                    width: '90%',
                    display: 'inline-block',
                    alignSelf: 'center',
                    marginTop: 20,
                    borderWidth: 1,
                    borderColor: 'grey',
                    borderRadius: 5,
                    borderStyle: 'solid',
                    padding: 10,
                }}>
                    <Typography style={{ fontSize: 25, color: 'black' }}>Make Payments with ACH:</Typography>

                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    {
                        activatedAccount && <div>
                            <Typography>Activated Account: {activatedAccount.bankName} ending in {activatedAccount.last4} has status "{activatedAccount.status}"</Typography>
                        </div>
                    }

                    {!activatedAccount && <PrimaryButton
                        style={{ marginTop: 5, marginBottom: 5 }}
                        onClick={() => open()}
                        disabled={!ready}>
                        Connect Bank With Plaid (Easiest)
                    </PrimaryButton>}

                    {!activatedAccount && <PrimaryButton
                        style={{ marginTop: 5 }}
                        onClick={() => setConnectBankModalOpen(true)}
                    >
                        Connect Bank Manually
                    </PrimaryButton>}

                    {
                        activatedAccount && (activatedAccount.status === 'new' || activatedAccount.status === 'verification_failed') && <div>
                            <Typography style={{ color: 'red' }}>Waiting on Account Verification:</Typography>

                            <Typography>Check your bank account for 2 microdeposits with description "AMTS" and enter them below: (it may take 1-2 days for amounts to appear)</Typography>

                            <Typography>Amount 1:</Typography>
                            <TextField
                                style={{ marginBottom: 5 }}
                                value={verificationAmount1}
                                onChange={(val) => setVerificationAmount1(val.target.value)}
                            />
                            <Typography>Amount 2:</Typography>
                            <TextField
                                style={{ marginBottom: 5 }}
                                value={verificationAmount2}
                                onChange={(val) => setVerificationAmount2(val.target.value)}
                            />
                            <Button
                                onClick={async () => {
                                    try {
                                        await axios.post(`${server_url}/api/verify_stripe_bank_account`, {
                                            companyId: parseInt(props.companyId, 10),
                                            amounts: [parseInt(verificationAmount1.replace('.', ''), 10), parseInt(verificationAmount2.replace('.', ''), 10)]
                                        }, {
                                            headers: {
                                                Authorization: `Bearer ${localStorage.getItem('token')}`
                                            }
                                        })

                                    } catch (e: any) {
                                        console.log(e)
                                        setAchErrorMessage(e?.response?.data?.error || '')
                                    }

                                    await refetch()



                                }}
                            >Verify Bank</Button>

                            {achErrorMessage && <Typography style={{ color: 'red' }}>{achErrorMessage}</Typography>}
                        </div>
                    }

                </div>

                <div style={{
                    width: '90%',
                    marginBottom: 20,
                    alignSelf: 'center',
                    marginTop: 20,
                    display: 'inline-block',
                    borderWidth: 1,
                    borderColor: 'grey',
                    borderRadius: 5,
                    borderStyle: 'solid',
                    padding: 10
                }}>
                    <Typography style={{ fontSize: 25, color: 'black' }}>Accept Payments on Waldo:</Typography>

                    {data?.companyById?.companyPrivateByCompanyId?.stripeConnectedAccountId ?
                        <p style={{ color: 'green' }}>You are successfully set up to receive payment through Stripe!</p>
                        :
                        <a href={`https://connect.stripe.com/express/oauth/authorize?redirect_uri=${window.location.origin}/stripe_oauth_redirect&client_id=${stripeKey}&state=${props.companyId}&stripe_user[business_type]=company`}>Connect With Stripe</a>
                    }

                    <PrimaryButton
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                            window.open(data?.companyById?.companyPrivateByCompanyId?.stripeLoginLink?.url)
                        }}>Stripe Dashboard</PrimaryButton>

                </div>
            </div>

            {
                connectBankModalOpen && <BankModal
                    visible={connectBankModalOpen}
                    onClose={async () => {
                        await refetch()
                        setConnectBankModalOpen(false)
                    }}
                    companyId={parseInt(props.companyId, 10)}
                />
            }
        </Paper>
    </div >

}
