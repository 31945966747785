import React, { useContext } from 'react'
import { AllWoodLoads_allWoodLoads_nodes as WoodLoad } from '../generated/AllWoodLoads'
import {
    Table,
    TableHead,
    TableCell,
    TableRow,
    Paper,
    TableBody,
    TableContainer,
    Typography,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { TableCellWaldo, SecondaryButton } from '../styles'
import { isPresent } from '../utils'
import papaParse from 'papaparse'
import { FiDownload } from 'react-icons/fi'
import AvatarWithName from '../AvatarWithName'
import { WoodLoadUnit } from '../generated/globalTypes'
import { useApolloClient } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
    CompanyHaulContracts,
    CompanyHaulContractsVariables,
} from '../generated/CompanyHaulContracts'
import HistoryContext from '../HistoryContext'
import { format } from 'date-fns/esm'

const COMPANY_HAUL_CONTRACTS = gql`
    query CompanyHaulContracts($companyId: Int!) {
        companyById(id: $companyId) {
            id
            haulContractsByOwnerCompanyId {
                nodes {
                    id
                    name
                    companyByHaulCompanyId {
                        id
                    }
                }
            }
        }
    }
`

interface Props {
    data: WoodLoad[]
    total?: number
    setFirst?: (val: number) => void
    setFrom?: (val: number) => void
}

export const columns: {
    title: string
    render: (w: WoodLoad) => any
    getValue: (w: WoodLoad) => any
}[] = [
        {
            title: 'Logging Company',
            render: (woodLoad: WoodLoad) => {
                const company = woodLoad.companyByLoggingCompanyId
                return (
                    company && (
                        <AvatarWithName
                            name={company.name || ''}
                            avatarUrl={company.avatarUrl || undefined}
                            linkTo={`/companies/${company.id}`}
                        />
                    )
                )
            },
            getValue: (woodLoad: WoodLoad) =>
                woodLoad.companyByLoggingCompanyId?.name || '',
        },
        {
            title: 'Logger',
            render: (woodLoad: WoodLoad) => {
                const user = woodLoad.userByLoggerUserId
                return (
                    user && (
                        <AvatarWithName
                            name={user?.username || ''}
                            avatarUrl={user?.avatarUrl || undefined}
                            linkTo={`/users/${user?.id}`}
                        />
                    )
                )
            },
            getValue: (woodLoad: WoodLoad) =>
                woodLoad.userByLoggerUserId?.username || '',
        },
        {
            title: 'Job',
            render: (woodLoad: WoodLoad) => (
                <Link
                    to={`/jobs/${woodLoad.jobByJobId?.id}`}
                    onClick={(event) => event.stopPropagation()}
                >
                    {woodLoad.jobByJobId?.contractName}
                </Link>
            ),
            getValue: (woodLoad: WoodLoad) =>
                woodLoad.jobByJobId?.contractName || '',
        },
        {
            title: 'Load Id',
            render: (woodLoad: WoodLoad) => {
                // 89 is Lyme Great Lakes, should build in a more extendable but for now this will do
                const company = woodLoad.jobByJobId?.companyByCompanyId
                return company?.id === 89 ? (
                    <div>{woodLoad.loadId}</div>
                ) : (
                    <div>{woodLoad.id}</div>
                )
            },
            getValue: (woodLoad: WoodLoad) => {
                const company = woodLoad.jobByJobId?.companyByCompanyId
                return company?.id === 89
                    ? woodLoad?.loadId?.toString() || ''
                    : woodLoad?.id?.toString() || ''
            },
        },
        {
            title: 'Trip Ticket',
            render: (woodLoad: WoodLoad) => <div>{woodLoad.tripTicketNumber}</div>,
            getValue: (woodLoad: WoodLoad) => woodLoad.tripTicketNumber || '',
        },
        {
            title: 'Product',
            render: (woodLoad: WoodLoad) => (
                <div>{woodLoad.woodLoadTypeByLoadType?.name}</div>
            ),
            getValue: (woodLoad: WoodLoad) =>
                woodLoad.woodLoadTypeByLoadType?.name || '',
        },
        {
            title: 'Species Group',
            render: (woodLoad: WoodLoad) => <div>{woodLoad.speciesGroupName}</div>,
            getValue: (woodLoad: WoodLoad) => woodLoad.speciesGroupName || '',
        },
        {
            title: 'Species',
            render: (woodLoad: WoodLoad) => (
                <div>{woodLoad.woodSpecyByWoodSpeciesId?.name}</div>
            ),
            getValue: (woodLoad: WoodLoad) =>
                woodLoad.woodSpecyByWoodSpeciesId?.name || '',
        },
        {
            title: 'Destination',
            render: (woodLoad: WoodLoad) => (
                <div>
                    {woodLoad.woodLoadDestinationByWoodLoadDestinationId?.name}
                </div>
            ),
            getValue: (woodLoad: WoodLoad) =>
                woodLoad.woodLoadDestinationByWoodLoadDestinationId?.name || '',
        },
        {
            title: 'Created',
            render: (woodLoad: WoodLoad) => (
                <div>
                    {format(new Date(woodLoad.createdAt), 'yyyy/MM/dd hh:mm:ss')}
                </div>
            ),
            getValue: (woodLoad: WoodLoad) =>
                new Date(woodLoad.createdAt).toISOString() || '',
        },
        {
            title: 'Load #',
            render: (woodLoad: WoodLoad) => <div>{woodLoad.idOnJob}</div>,
            getValue: (woodLoad: WoodLoad) => woodLoad.idOnJob?.toString() || '',
        },
        {
            title: 'Trucking Company',
            render: (woodLoad: WoodLoad) => {
                const company =
                    woodLoad.truckerLoadApplicationByAcceptedApplicationId
                        ?.companyByCompanyId
                return (
                    company && (
                        <AvatarWithName
                            name={company.name || ''}
                            avatarUrl={company.avatarUrl || undefined}
                            linkTo={`/companies/${company?.id}`}
                        />
                    )
                )
            },
            getValue: (woodLoad: WoodLoad) =>
                woodLoad.truckerLoadApplicationByAcceptedApplicationId
                    ?.companyByCompanyId?.name || '',
        },
        {
            title: 'Trucker',
            render: (woodLoad: WoodLoad) => {
                const user = woodLoad.truckerLoadApplicationByAcceptedApplicationId
                return (
                    user && (
                        <AvatarWithName
                            name={user?.userByUserId?.username || ''}
                            avatarUrl={user?.userByUserId?.avatarUrl || undefined}
                            linkTo={`/users/${user?.userByUserId?.id}`}
                        />
                    )
                )
            },
            getValue: (woodLoad: WoodLoad) =>
                woodLoad.truckerLoadApplicationByAcceptedApplicationId?.userByUserId
                    ?.username || '',
        },
        {
            title: 'Picked Up',
            render: (woodLoad: WoodLoad) => (
                <div>
                    {woodLoad.pickedUpAt
                        ? format(
                            new Date(woodLoad.pickedUpAt),
                            'yyyy/MM/dd hh:mm:ss'
                        )
                        : ''}
                </div>
            ),
            getValue: (woodLoad: WoodLoad) =>
                new Date(woodLoad.pickedUpAt).toISOString() || '',
        },
        {
            title: 'Dropped Off',
            render: (woodLoad: WoodLoad) => (
                <div>
                    {woodLoad.droppedOffAt
                        ? format(
                            new Date(woodLoad.droppedOffAt),
                            'yyyy/MM/dd hh:mm:ss'
                        )
                        : ''}
                </div>
            ),
            getValue: (woodLoad: WoodLoad) =>
                new Date(woodLoad.droppedOffAt).toISOString() || '',
        },
        {
            title: 'State',
            render: (woodLoad: WoodLoad) => (
                <div
                    style={{
                        backgroundColor:
                            woodLoad.woodLoadStateEntriesByWoodLoadId.nodes[0]
                                ?.woodLoadStateByStateId?.color || undefined,
                        borderRadius: 3,
                    }}
                >
                    <Typography>
                        {
                            woodLoad.woodLoadStateEntriesByWoodLoadId.nodes[0]
                                ?.woodLoadStateByStateId?.name
                        }
                    </Typography>
                </div>
            ),
            getValue: (woodLoad: WoodLoad) =>
                woodLoad.woodLoadStateEntriesByWoodLoadId.nodes[0]
                    ?.woodLoadStateByStateId?.name || '',
        },
    ]

export function filterNulls<T>(vals: (T | null)[]): T[] {
    const res: T[] = []
    vals.forEach((v) => {
        if (v) {
            res.push(v)
        } 
    })
    return res
}

export default function WoodLoadsTable(props: Props) {
    const historyContext = useContext(HistoryContext)

    const client = useApolloClient()

    async function csvFile() {
        const jobCompanyIds = props.data
            .map((d) => d.jobByJobId?.companyByCompanyId?.id)
            .filter(isPresent)
            .filter(
                (el, idx, arr) => arr.findIndex((el2) => el2 === el) === idx
            )

        const companyHaulContracts: { [key: number]: CompanyHaulContracts } = {}

        for (const id of jobCompanyIds) {
            const res = await client.query<
                CompanyHaulContracts,
                CompanyHaulContractsVariables
            >({
                query: COMPANY_HAUL_CONTRACTS,
                variables: {
                    companyId: id,
                },
            })

            companyHaulContracts[id] = res.data
        }

        // const csvLoads: WoodLoad[] = []
        // woodloads.data.allWoodLoads?.nodes.forEach(n => n && csvLoads.push(n))
        // csvLoads.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

        const csvColumns: {
            title: string
            getValue: (w: WoodLoad) => string
        }[] = [
                {
                    title: 'Waldo Load ID',
                    getValue: (w) => {
                        const company = w?.jobByJobId?.companyByCompanyId
                        return company?.id === 89
                            ? w?.loadId?.toString() || ''
                            : w?.id?.toString() || ''
                    },
                },
                {
                    title: 'Waldo Load Link',
                    getValue: (w) => `https://app.waldologs.com/wood_loads/${w.id}`,
                },
                ...columns,
                {
                    title: 'Destination External ID',
                    getValue: (w) =>
                        w.woodLoadDestinationByWoodLoadDestinationId?.externalId ||
                        '',
                },
                {
                    title: 'Estimated Volume',
                    getValue: (w) => w.estimatedVolume?.toString() || '',
                },
                {
                    title: `Load Value`,
                    getValue: (w) => w.value?.toString() || '',
                },
                {
                    title: 'Load Unit',
                    getValue: (w) => w.unit || '',
                },
                {
                    title: 'Weight (Tons)',
                    getValue: (w) =>
                        w.unit === WoodLoadUnit.TON
                            ? w.value?.toString() || ''
                            : '',
                },
                {
                    title: 'Cords',
                    getValue: (w) =>
                        w.unit === WoodLoadUnit.CORD
                            ? w.value?.toString() || ''
                            : '',
                },
                {
                    title: 'MBF',
                    getValue: (w) =>
                        w.unit === WoodLoadUnit.MBF
                            ? w.value?.toString() || ''
                            : '',
                },
                {
                    title: 'Scale Ticket #',
                    getValue: (w) => w.scaleTicketNumber || '',
                },
                {
                    title: 'Haul Contract',
                    getValue: (w) => {
                        const contracts =
                            companyHaulContracts[
                                w.jobByJobId?.companyByCompanyId?.id || -1
                            ]?.companyById?.haulContractsByOwnerCompanyId.nodes.filter(
                                (n) =>
                                    n?.companyByHaulCompanyId?.id ===
                                    w.truckerLoadApplicationByAcceptedApplicationId
                                        ?.companyByCompanyId?.id
                            ) || []
                        if (contracts.length === 0) {
                            const loggerHaulContracts =
                                companyHaulContracts[
                                    w.jobByJobId?.companyByCompanyId?.id || -1
                                ]?.companyById?.haulContractsByOwnerCompanyId.nodes.filter(
                                    (n) =>
                                        n?.companyByHaulCompanyId?.id ===
                                        w.companyByLoggingCompanyId?.id
                                ) || []
                            if (loggerHaulContracts.length === 1) {
                                return loggerHaulContracts[0]?.name || ''
                            } else {
                                return ''
                            }
                        } else if (contracts.length === 1) {
                            return contracts[0]?.name || ''
                        } else {
                            return 'Error! Mulitple Haul Contracts Found!'
                        }
                    },
                },
                {
                    title: 'Contract Number',
                    getValue: (w) => w.jobByJobId?.contractNumber || '',
                },
                {
                    title: 'Service Contract ID',
                    getValue: (w) => w.jobByJobId?.serviceContractName || '',
                },
                {
                    title: 'Notes',
                    getValue: (w) => w.notes || '',
                },
                {
                    title: 'County',
                    getValue: (w) => w.jobByJobId?.county || '',
                },
                {
                    title: 'Township',
                    getValue: (w) => w.jobByJobId?.township || '',
                },
                {
                    title: 'Range',
                    getValue: (w) => w.jobByJobId?.range2 || '',
                },
                {
                    title: 'Section',
                    getValue: (w) => w.jobByJobId?.section || '',
                },
            ]

        const csvString = papaParse.unparse({
            fields: csvColumns.map((c) => c.title),
            data: filterNulls(props.data || []).map((node) =>
                csvColumns.map((c) => c.getValue(node).replaceAll(',', ''))
            ),
        })
        const blob = new Blob([csvString])
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = `wood_loads${props.data.length}.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    return (
        <div style={{ marginBottom: 10 }}>
            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((c) => (
                                    <TableCellWaldo key={c.title}>
                                        {c.title}
                                    </TableCellWaldo>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.data.map((woodLoad) => (
                                <TableRow
                                    key={woodLoad.id}
                                    hover
                                    onClick={() => {
                                        historyContext.history?.push(
                                            `/wood_loads/${woodLoad.id}`
                                        )
                                    }}
                                >
                                    {columns.map((c) => (
                                        <TableCell key={c.title}>
                                            {c.render(woodLoad)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <SecondaryButton
                variant="outlined"
                style={{ marginBottom: 5, marginTop: 5 }}
                onClick={() => {
                    csvFile()
                }}
            >
                Download CSV <FiDownload />
            </SecondaryButton>
        </div>
    )
}
