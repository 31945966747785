import React from 'react';
import {
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@material-ui/core';

import {
    StyledWaldoTable,
    WaldoTableCell,
    WaldoTableHead,
} from './WaldoStyle';

interface Column<T> {
    header: string;
    render: (t: T) => any;
}

interface Props<T> {
    columns: Column<T>[];
    data: T[];
    onClickRow?: (t: T) => void;
}

export default function WaldoTable<T>(props: Props<T>) {
    return (
        <TableContainer
            component={Paper}
            style={{ marginTop: 10, marginBottom: 20 }}
        >
            <StyledWaldoTable size="small">
                <WaldoTableHead>
                    <TableRow>
                        {props.columns.map((c, idx) => (
                            <WaldoTableCell key={idx}>{c.header}</WaldoTableCell>
                        ))}
                    </TableRow>
                </WaldoTableHead>
                <TableBody>
                    {props.data.map((row, idx) => {
                        return (
                            <TableRow
                                key={idx}
                                hover
                                onClick={() => {
                                    props.onClickRow && props.onClickRow(row);
                                }}
                            >
                                {props.columns.map((c) => (
                                    <TableCell key={c.header}>{c.render(row)}</TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </StyledWaldoTable>
        </TableContainer>
    );
}