import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { TruckerWoodLoadsVariables, TruckerWoodLoads } from './generated/TruckerWoodLoads'
import { WOOD_LOAD_FRAGMENT } from './AllWoodLoads'
import {
  AllWoodLoads_allWoodLoads_nodes as WoodLoad
} from './generated/AllWoodLoads'
import WoodLoadsTable, { } from './WoodLoads/WoodLoadsTable';

const TRUCKER_WOODLOADS = gql`query TruckerWoodLoads($userId: Int!) {
  userById(id: $userId) {
    truckerLoadApplicationsByUserId(filter: {woodLoadsByAcceptedApplicationIdExist: true}) {
      nodes {
        id
        woodLoadsByAcceptedApplicationId {
          nodes {
            ...WoodLoadFragment
          }
        }
      }
    }
  }
}


  ${WOOD_LOAD_FRAGMENT}
  `
interface Props {
  id: string
}
export default function TruckerWoodLoadsComponent(props: Props) {

  const { data } = useQuery<TruckerWoodLoads, TruckerWoodLoadsVariables>(TRUCKER_WOODLOADS, {
    variables: {
      userId: Number(props.id),
    }
  })
  const loads: WoodLoad[] = []
  // data?.allTruckerLoadApplications?.edges.forEach((node) => node?.node?.woodLoadByWoodLoadId && loads.push(node.node?.woodLoadByWoodLoadId))
  // const total = data?.allTruckerLoadApplications?.totalCount

  data?.userById?.truckerLoadApplicationsByUserId.nodes.forEach(node => node?.woodLoadsByAcceptedApplicationId.nodes.forEach(wl => wl && loads.push(wl)))



  return <div style={{ textAlign: 'center', marginBottom: 35 }}>
    <WoodLoadsTable
      data={loads}
    />
  </div >

}