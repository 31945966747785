import React, { useState } from 'react'
import { Grid, Dialog, TextField } from '@material-ui/core';
import { Authenticate, AuthenticateVariables } from './generated/Authenticate'
import ResetPasswordModal from './ResetPasswordModal'
import { useMutation } from '@apollo/react-hooks';
import { AUTHENTICATE } from './RegisterLogin'
import { LinkButton, PrimaryButton, SecondaryButton, SecondaryTitle } from './styles';


interface Props {
    refetch: () => void
}

function divider() {
    return <div style={{
        height: 2,
        width: 500,
        alignContent: 'center',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        gridColumnStart: '1',
        gridColumnEnd: '3',
        borderColor: 'lightgrey'
    }} />
}

export default function LoginModal(props: Props) {
    const [reset, setReset] = useState<boolean>(false)
    const [fail, setFail] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const [authenticate] = useMutation<Authenticate, AuthenticateVariables>(AUTHENTICATE)

    // const onFinish = async (values: any) => {
    //     const { username, password }: { username: string, password: string } = values
    //     setEmail(username)
    // };



    return <div style={{ display: 'flex', justifyContent: 'center' }}>

        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

            <div style={{ textAlign: 'center', maxWidth: 700, minWidth: 500, }}>
                <SecondaryTitle style={{ paddingTop: 20 }}>Login</SecondaryTitle>
                <Grid>
                    <Grid item>
                        <TextField
                            style={{ width: 250 }}
                            margin="dense"
                            variant="outlined"
                            placeholder="Email"
                            value={email}
                            onChange={(event) => {
                                setEmail(event.target.value)
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            style={{ width: 250 }}
                            id="standard-password-input"
                            type="password"
                            autoComplete="current-password"
                            margin="dense"
                            variant="outlined"
                            placeholder="Password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                    </Grid>
                    {fail && <div style={{ color: 'red' }}>Please enter correct email and password</div>}
                    <Grid item style={{ marginBottom: 5 }}>

                        <PrimaryButton

                            onClick={async () => {
                                localStorage.removeItem('token')
                                const res = await authenticate({
                                    variables: {
                                        email: email,
                                        password: password
                                    }
                                })
                                if (res.data?.authenticate?.jwtToken) {
                                    localStorage.setItem('token', res.data?.authenticate?.jwtToken);
                                    localStorage.removeItem('Wood_Load_From_Date');
                                    localStorage.removeItem('Wood_Load_To_Date');
                                    await props.refetch()
                                } else {
                                    setFail(true)
                                }
                            }}
                            style={{ marginTop: 5, marginBottom: 5 }}>

                            Login

                        </PrimaryButton>
                    </Grid>
                    <Grid item>
                        <LinkButton onClick={async () => {
                            setReset(true)
                        }}
                        >Reset Password</LinkButton>
                        <Dialog title="Reset Password"
                            open={reset}>
                            <ResetPasswordModal />
                            <div style={{ display: 'grid', gridAutoFlow: 'column', justifyContent: 'right' }}>
                                <SecondaryButton style={{ flexDirection: 'row', width: 25, marginRight: 10, marginBottom: 5 }} variant="outlined" onClick={() => { setReset(false) }}>cancel</SecondaryButton>
                                <PrimaryButton style={{ width: 20, marginRight: 10, marginBottom: 5 }} onClick={() => { setReset(false) }}>ok</PrimaryButton>
                            </div>
                        </Dialog>


                    </Grid>
                </Grid>
                {divider()}


                <div >


                    {(reset && (email.length > 0)) && <div>Sent reset password to {email}</div>}

                </div>
            </div>

        </div >

    </div >
}