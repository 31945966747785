import React, { useState } from 'react'

import { Grid, TextField, Typography } from '@material-ui/core';
import { RequestPasswordReset, RequestPasswordResetVariables } from './generated/RequestPasswordReset'
import { useMutation } from '@apollo/react-hooks';
import { REQUEST_PASSWORD_RESET } from './RegisterLogin'
import { PrimaryButton } from './styles';



export default function ResetPasswordModal() {
    const [mailAddress, setMailAddress] = useState<string>('')
    const [requestPasswordReset] = useMutation<RequestPasswordReset, RequestPasswordResetVariables>(REQUEST_PASSWORD_RESET)
    const [emailSent, setEmailSent] = useState<boolean>(false)



    return <div style={{ backgroundColor: 'white', height: 200, width: 400, alignContent: 'center', textAlign: 'center' }}>
        {/* Send reset password to _______, SEND,  CANCEL */}



        <Grid>
            <Typography style={{ marginTop: 20 }}>Enter email to send password reset</Typography>
            <Grid item>
                <TextField
                    style={{ width: 300 }}
                    margin="dense"
                    variant="outlined"
                    placeholder="Email"
                    value={mailAddress}
                    onChange={(event) => {
                        setMailAddress(event.target.value)
                    }}


                />
            </Grid>
            <Grid item>
                {<PrimaryButton
                    style={{ marginTop: 5 }}
                    onClick={async () => {
                        console.log(mailAddress)
                        await requestPasswordReset({
                            variables: {
                                email: mailAddress
                            }
                        }).then(() => setEmailSent(true))
                    }}
                >Send Email</PrimaryButton>}
            </Grid>
        </Grid>
        {emailSent && <div>Email succesfully sent to {mailAddress}</div>}
    </div >

}