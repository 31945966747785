import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { WOOD_LOAD_FRAGMENT } from '../AllWoodLoads'
import { TextField, Typography } from '@material-ui/core'
import { useApolloClient } from '@apollo/react-hooks'
import {
    WoodLoadsJobOwner,
    WoodLoadsJobOwnerVariables,
} from '../generated/WoodLoadsJobOwner'
import { WoodLoadFragment } from '../generated/WoodLoadFragment'
import WoodLoadsTable from '../WoodLoads/WoodLoadsTable'
import { isPresent } from '../utils'
import queryString from 'query-string'

import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import {
    WoodLoadsTrucker,
    WoodLoadsTruckerVariables,
} from '../generated/WoodLoadsTrucker'
import {
    WoodLoadsLogger,
    WoodLoadsLoggerVariables,
} from '../generated/WoodLoadsLogger'

interface Props {
    companyId: string
}

const JOB_OWNER_QUERY = gql`
    query WoodLoadsJobOwner($companyId: Int!, $from: Datetime!, $to: Datetime) {
        companyById(id: $companyId) {
            id
            name
            jobsByCompanyId {
                nodes {
                    woodLoadsByJobId(
                        filter: {
                            and: [
                                { droppedOffAt: { isNull: false } }
                                {
                                    droppedOffAt: {
                                        greaterThanOrEqualTo: $from
                                    }
                                }
                                { droppedOffAt: { lessThanOrEqualTo: $to } }
                            ]
                        }
                    ) {
                        nodes {
                            ...WoodLoadFragment
                        }
                    }
                }
            }
        }
    }

    ${WOOD_LOAD_FRAGMENT}
`

const TRUCKER_QUERY = gql`
    query WoodLoadsTrucker($companyId: Int!, $from: Datetime, $to: Datetime) {
        companyById(id: $companyId) {
            id
            name
            truckerLoadApplicationsByCompanyId {
                nodes {
                    woodLoadsByAcceptedApplicationId(
                        filter: {
                            and: [
                                { droppedOffAt: { isNull: false } }
                                {
                                    droppedOffAt: {
                                        greaterThanOrEqualTo: $from
                                    }
                                }
                                { droppedOffAt: { lessThanOrEqualTo: $to } }
                            ]
                        }
                    ) {
                        nodes {
                            ...WoodLoadFragment
                        }
                    }
                }
            }
        }
    }

    ${WOOD_LOAD_FRAGMENT}
`

const LOGGER_QUERY = gql`
    query WoodLoadsLogger($companyId: Int!, $from: Datetime, $to: Datetime) {
        companyById(id: $companyId) {
            id
            name
            woodLoadsByLoggingCompanyId(
                filter: {
                    and: [
                        { droppedOffAt: { isNull: false } }
                        { droppedOffAt: { greaterThanOrEqualTo: $from } }
                        { droppedOffAt: { lessThanOrEqualTo: $to } }
                    ]
                }
            ) {
                nodes {
                    ...WoodLoadFragment
                }
            }
        }
    }
    ${WOOD_LOAD_FRAGMENT}
`

export const JOB_OWNER_ROLE = 'job_owner'
export const TRUCKER_ROLE = 'trucker'
export const LOGGER_ROLE = 'logger'

export default function WoodLoads(props: Props) {
    const client = useApolloClient()
    const params = queryString.parse(window.location.search)

    function lastWeekMondayToSunday(): { monday: Date; sunday: Date } {
        const prevMonday = new Date()
        // prevMonday.setDate((prevMonday.getDate() - 7) - (prevMonday.getDay() + 6) % 7);
        prevMonday.setDate(prevMonday.getDate() - 7)
        while (prevMonday.getDay() !== 1) {
            prevMonday.setDate(prevMonday.getDate() - 1)
        }

        prevMonday.setHours(0)
        prevMonday.setMinutes(0)
        prevMonday.setSeconds(0)

        const prevSunday = new Date()

        while (prevSunday.getDay() !== 0) {
            prevSunday.setDate(prevSunday.getDate() - 1)
        }

        prevSunday.setHours(24)
        prevSunday.setMinutes(0)
        prevSunday.setSeconds(0)
        // prevSunday.setDate((prevSunday.getDate() - 7) )
        return { monday: prevMonday, sunday: prevSunday }
    }

    const [fromDate, setFromDate] = useState<Date | undefined>(undefined)
    const [toDate, setToDate] = useState<Date | undefined>(undefined)
    const [tripTicketNumber, setTripTicketNumber] = useState('')

    const [data, setData] = useState<
        { loads: WoodLoadFragment[]; companyName: string } | undefined
    >(undefined)

    function setDateFilter(date: any, key: string, fDate: any) {
        const newDate = new Date(date)
        if (fDate) {
            setFromDate(newDate)
        } else {
            newDate?.setHours(23)
            newDate?.setMinutes(59)
            setToDate(newDate)
        }

        localStorage.setItem(key, date)
    }

    useEffect(() => {
        const dates = lastWeekMondayToSunday()

        let fDate = localStorage.getItem('Wood_Load_From_Date')
        let tDate = localStorage.getItem('Wood_Load_To_Date')
        if (fDate === null) {
            fDate = new Date(dates.monday).toString()
        }

        if (tDate === null) {
            //setting defaults based upon from date value
            if (fDate === new Date(dates.monday).toString()) {
                tDate = dates.sunday.toString()
            } else {
                tDate = new Date(fDate + 7).toString()
            }
        }

        setDateFilter(new Date(fDate), 'Wood_Load_From_Date', true)
        setDateFilter(new Date(tDate), 'Wood_Load_To_Date', false)
    }, [])

    const currentRole = (params.role as string) || JOB_OWNER_ROLE

    useEffect(() => {
        async function doQuery() {
            if (!fromDate && !toDate) {
                return
            }

            if (currentRole === JOB_OWNER_ROLE) {
                const res = await client.query<
                    WoodLoadsJobOwner,
                    WoodLoadsJobOwnerVariables
                >({
                    query: JOB_OWNER_QUERY,
                    variables: {
                        companyId: parseInt(props.companyId, 10),
                        from: fromDate,
                        to: toDate,
                    },
                })
                setData({
                    loads:
                        res.data?.companyById?.jobsByCompanyId.nodes
                            .map((j) =>
                                j?.woodLoadsByJobId.nodes.filter(isPresent)
                            )
                            .flat()
                            .filter(isPresent)
                            .filter(
                                (x) =>
                                    tripTicketNumber === '' ||
                                    x.tripTicketNumber?.includes(
                                        tripTicketNumber
                                    ) ||
                                    x.id.toString().includes(tripTicketNumber)
                            ) || [],
                    companyName: res.data.companyById?.name || '',
                })
            } else if (currentRole === TRUCKER_ROLE) {
                const res = await client.query<
                    WoodLoadsTrucker,
                    WoodLoadsTruckerVariables
                >({
                    query: TRUCKER_QUERY,
                    variables: {
                        companyId: parseInt(props.companyId, 10),
                        from: fromDate,
                        to: toDate,
                    },
                })

                setData({
                    loads:
                        res.data.companyById?.truckerLoadApplicationsByCompanyId.nodes
                            .map(
                                (n) => n?.woodLoadsByAcceptedApplicationId.nodes
                            )
                            .flat()
                            .filter(isPresent)
                            .filter(
                                (x) =>
                                    tripTicketNumber === '' ||
                                    x.tripTicketNumber?.includes(
                                        tripTicketNumber
                                    ) ||
                                    x.id.toString().includes(tripTicketNumber)
                            ) || [],
                    companyName: res.data.companyById?.name || '',
                })
            } else if (currentRole === LOGGER_ROLE) {
                const res = await client.query<
                    WoodLoadsLogger,
                    WoodLoadsLoggerVariables
                >({
                    query: LOGGER_QUERY,
                    variables: {
                        companyId: parseInt(props.companyId, 10),
                        from: fromDate,
                        to: toDate,
                    },
                })

                setData({
                    loads:
                        res.data.companyById?.woodLoadsByLoggingCompanyId.nodes
                            .filter(isPresent)
                            .filter(
                                (x) =>
                                    tripTicketNumber === '' ||
                                    x.tripTicketNumber?.includes(
                                        tripTicketNumber
                                    ) ||
                                    x.id.toString().includes(tripTicketNumber)
                            ) || [],
                    companyName: res.data.companyById?.name || '',
                })
            }
        }

        doQuery()
    }, [
        fromDate,
        toDate,
        currentRole,
        tripTicketNumber,
        props.companyId,
        client,
    ])

    data?.loads.sort(
        (a, b) =>
            new Date(b.droppedOffAt).getTime() -
            new Date(a.droppedOffAt).getTime()
    )

    return (
        <div>
            <Typography>
                Wood Loads for <b>{data?.companyName}</b> as{' '}
                <b>
                    {
                        (
                            {
                                [JOB_OWNER_ROLE]: 'Job Manager',
                                [TRUCKER_ROLE]: 'Trucker',
                                [LOGGER_ROLE]: 'Logging Contractor',
                            } as any
                        )[currentRole]
                    }
                </b>
            </Typography>

            <div
                style={{
                    display: 'inline-grid',
                    gridTemplateColumns: 'auto auto',
                    columnGap: 5,
                    marginTop: 20,
                    marginBottom: 20,
                }}
            >
                <Typography
                    style={{ gridColumnStart: '1', gridColumnEnd: '3' }}
                >
                    <u>Filter By Dropped Off Date:</u>
                </Typography>
                <Typography style={{ gridColumn: '1' }}>From: </Typography>
                <div style={{ gridColumn: '2' }}>
                    <DatePicker
                        selected={fromDate}
                        onChange={(date) => {
                            setDateFilter(
                                date as Date,
                                'Wood_Load_From_Date',
                                true
                            )
                        }}
                    />
                </div>
                <Typography style={{ gridColumn: '1' }}>To: </Typography>
                <div style={{ gridColumn: '2' }}>
                    <DatePicker
                        selected={toDate}
                        onChange={(date) =>
                            setDateFilter(
                                date as Date,
                                'Wood_Load_To_Date',
                                false
                            )
                        }
                    />
                </div>
            </div>
            <br />
            <div
                style={{
                    display: 'inline-grid',
                    gridTemplateColumns: 'auto auto',
                    columnGap: 5,
                    marginTop: 20,
                    marginBottom: 20,
                }}
            >
                <Typography
                    style={{ gridColumnStart: '1', gridColumnEnd: '3' }}
                >
                    <u>Trip Ticket Number or Load Id:</u>
                </Typography>
                <TextField
                    variant="outlined"
                    placeholder=""
                    value={tripTicketNumber}
                    onChange={(event) => {
                        setTripTicketNumber(event.target.value)
                    }}
                    size="small"
                    style={{ backgroundColor: 'white', padding: '0px' }}
                />
            </div>

            <WoodLoadsTable data={data?.loads || []} />
        </div>
    )
}
