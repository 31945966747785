
import React, { useRef, useEffect } from 'react'
import gql from 'graphql-tag'
import { JobMapViewQuery, JobMapViewQueryVariables, JobMapViewQuery_jobById_jobCrewsByJobId_nodes_crewByCrewId_crewMembersByCrewId_nodes_userByUserId as User } from '../generated/JobMapViewQuery'
import { useQuery } from '@apollo/react-hooks'
import { isPresent } from '../utils';
import { formatDistanceStrict } from 'date-fns'
import { HEADER_HEIGHT, BODY_TOP_PADDING } from '../App';
import { BREADCRUMBS_HEIGHT, BREADCRUMBS_PADDING } from '../Breadcrumbs';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

// @ts-ignore
// eslint-disable-next-line 
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


mapboxgl.accessToken = 'pk.eyJ1IjoibWFya2hhbG9uZW4iLCJhIjoiY2tjejc0cXRhMGgxMjJ2bGlld2xkYWV0YiJ9.8eKCKoTv3eRaeQWx6kAYvQ'

const JOB_MAP_VIEW_QUERY = gql`query JobMapViewQuery($id: Int!) {
    jobById(id: $id) {
      jobCrewsByJobId {
        nodes {
          crewByCrewId {
            id
            crewMembersByCrewId {
              nodes {
                userByUserId {
                  id
                  avatarUrl
                  username
                  latestUserLocationByUserId {
                    coordinate {
                      latitude
                      longitude
                    }
                    heading
                    createdAt
                  }
                }
              }
            }
          }
        }
      }
      id
      creatorId
      woodLoadsByJobId(filter: { pickedUpAt: { isNull: true } }) {
        nodes {
          createdAt
          id
          idOnJob
          logRoadId
          pickUpLocation {
            latitude
            longitude
          }
          loggerUserId
          acceptedApplicationId
          truckerLoadApplicationByAcceptedApplicationId {
            id
            userByUserId {
              id
              avatarUrl
              latestUserLocationByUserId {
                coordinate {
                  latitude
                  longitude
                }
                createdAt
              }
            }
          }
        }
      }
      jobForesterPlanMapsByJobId {
        nodes {
          id
          tilesUrl
          originalFileName
        }
      }
      jobEquipmentLocationTracesByJobId {
        nodes {
          equipmentType
          id
          jobEquipmentLocationTraceCoordinatesByJobEquipmentLocationTraceId {
            nodes {
              coordinate {
                latitude
                longitude
              }
              recordedAt
              id
              accuracy
            }
          }
        }
      }
      logRoadByLogRoadId {
        id
        logRoadCoordinatesByLogRoadId(orderBy: PRIMARY_KEY_ASC) {
          nodes {
            coordinate {
              latitude
              longitude
            }
            id
          }
        }
      }
    }
  }
  `

interface LatLng {
  latitude: number
  longitude: number
}


interface Props {
  jobId: number
}

export default function JobMap(props: Props) {

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  const { data } = useQuery<JobMapViewQuery, JobMapViewQueryVariables>(JOB_MAP_VIEW_QUERY,
    {
      variables: {
        id: props.jobId
      }
    }
  )



  useEffect(() => {
    function allLatLngsInMap(): LatLng[] {
      const coords: LatLng[] = []
      data?.jobById?.logRoadByLogRoadId?.logRoadCoordinatesByLogRoadId.nodes.forEach(n => n?.coordinate && coords.push(n.coordinate))
      data?.jobById?.woodLoadsByJobId.nodes.forEach(e => e?.truckerLoadApplicationByAcceptedApplicationId?.userByUserId?.latestUserLocationByUserId?.coordinate && coords.push(e?.truckerLoadApplicationByAcceptedApplicationId?.userByUserId?.latestUserLocationByUserId.coordinate))
      data?.jobById?.woodLoadsByJobId.nodes.forEach(w => (w?.pickUpLocation) && coords.push(w?.pickUpLocation))
      return coords
    }
    if (mapRef.current) {
      return; // initialize map only once
    }
    const c = mapContainer.current
    if (c && data) {

      const latLongs = allLatLngsInMap()

      const minLat = latLongs.length > 0 ? Math.min(...latLongs.map(ll => ll.latitude)) : 46.236533
      const maxLat = latLongs.length > 0 ? Math.max(...latLongs.map(ll => ll.latitude)) : 48.236533
      const minLong = latLongs.length > 0 ? Math.min(...latLongs.map(ll => ll.longitude)) : -89.447016
      const maxLong = latLongs.length > 0 ? Math.max(...latLongs.map(ll => ll.longitude)) : -87.447016

      mapRef.current = new mapboxgl.Map({
        container: c,
        style: 'mapbox://styles/mapbox/streets-v11',
        bounds: new mapboxgl.LngLatBounds(
          new mapboxgl.LngLat(minLong, minLat),
          new mapboxgl.LngLat(maxLong, maxLat)
        )
      });

      const map = mapRef.current

      if (map) {
        map.on('load', function () {


          for (const pdf of data.jobById?.jobForesterPlanMapsByJobId.nodes || []) {
            map.addSource('forestry-pdf', {
              'type': 'raster',
              'tiles': [
                `${pdf?.tilesUrl || ''}/{z}/{x}/{y}.png`
              ],
            });

            map.addLayer({
              'id': 'forestry-pdf',
              'source': 'forestry-pdf',
              'type': 'raster'
            });
          }

          map.addSource('logroad', {
            'type': 'geojson',
            'data': {
              'type': 'Feature',
              'properties': {},
              'geometry': {
                'type': 'LineString',
                'coordinates': [
                  ...(data.jobById?.logRoadByLogRoadId?.logRoadCoordinatesByLogRoadId.nodes.map(n => [n?.coordinate?.longitude || 0, n?.coordinate?.latitude || 0]) || [])
                ]
              }
            }
          });
          map.addLayer({
            'id': 'logroad',
            'type': 'line',
            'source': 'logroad',
            'layout': {
              'line-join': 'round',
              'line-cap': 'round'
            },
            'paint': {
              'line-color': 'black',
              'line-width': 2
            }
          });

          for (const trace of data.jobById?.jobEquipmentLocationTracesByJobId.nodes.filter(isPresent) || []) {
            map.addSource(`equipmenttrace-${trace.id}`, {
              'type': 'geojson',
              'data': {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                  'type': 'LineString',
                  'coordinates': [
                    ...(trace?.jobEquipmentLocationTraceCoordinatesByJobEquipmentLocationTraceId.nodes.map(n => [n?.coordinate?.longitude || 0, n?.coordinate?.latitude || 0]) || [])
                  ]
                }
              }
            });
            map.addLayer({
              'id': `equipmenttrace-${trace.id}`,
              'type': 'line',
              'source': `equipmenttrace-${trace.id}`,
              'layout': {
                'line-join': 'round',
                'line-cap': 'round'
              },
              'paint': {
                'line-color': trace.equipmentType === 'PROCESSOR' ? 'green' : 'blue',
                'line-width': 2
              }
            });
          }

          // Show Wood Loads

          const woodLoadJson: { type: string, features: { type: string, properties: any, geometry: { type: string, coordinates: [number, number] } }[] } = {
            type: 'FeatureCollection',

            features: data.jobById?.woodLoadsByJobId.nodes.map(wl => ({

              type: 'Feature',
              properties: {
                woodLoadId: wl?.id,
                idOnJob: wl?.idOnJob
              },
              geometry: {
                type: 'Point',
                coordinates: [wl?.pickUpLocation?.longitude || 0, wl?.pickUpLocation?.latitude || 0]
              }
            })) || []
          };

          // Add markers to the map.
          woodLoadJson.features.forEach(function (marker) {
            // Create a DOM element for each marker.
            const el = document.createElement('a');
            el.href = `/wood_loads/${marker.properties.woodLoadId}`
            el.className = 'marker';
            el.style.backgroundColor = 'black'
            el.style.width = 30 + 'px';
            el.style.height = 30 + 'px';
            el.style.backgroundSize = '100%';
            el.style.borderRadius = '15px'

            const text = document.createTextNode(`${marker.properties.idOnJob}`)
            el.style.color = 'white'
            el.style.textAlign = 'center'
            el.style.display = 'flex'
            el.style.alignItems = 'center'
            el.style.justifyContent = 'center'
            // text.children.
            el.appendChild(text)

            // Add markers to the map.
            new mapboxgl.Marker(el)
              .setLngLat(marker.geometry.coordinates)
              .addTo(map);
          });

          // show crew locations

          const users: User[] = []

          data.jobById?.jobCrewsByJobId.nodes.filter(isPresent).forEach(n => n?.crewByCrewId?.crewMembersByCrewId.nodes.forEach(n2 => n2?.userByUserId && users.push(n2.userByUserId)))

          const userLocations: { type: string, features: { type: string, properties: { user: User }, geometry: { type: string, coordinates: [number, number] } }[] } = {
            type: 'FeatureCollection',

            features: users.map(u => ({

              type: 'Feature',
              properties: {
                user: u,
              },
              geometry: {
                type: 'Point',
                coordinates: [u.latestUserLocationByUserId?.coordinate.longitude || 0, u.latestUserLocationByUserId?.coordinate.latitude || 0]
              }
            })) || []
          };

          // Add markers to the map.
          userLocations.features.forEach(function (marker) {
            // Create a DOM element for each marker.
            const containerDiv = document.createElement('div')
            containerDiv.style.display = 'flex'
            containerDiv.style.flexDirection = 'column'
            containerDiv.style.color = 'black'
            containerDiv.style.textAlign = 'center'
            containerDiv.style.display = 'flex'
            containerDiv.style.alignItems = 'center'
            containerDiv.style.justifyContent = 'center'

            const el = document.createElement('a');
            el.href = `/users/${marker.properties.user.id}`
            el.className = 'marker';

            el.style.backgroundImage = `url(${marker.properties.user.avatarUrl || ''})`;
            el.style.width = 30 + 'px';
            el.style.height = 30 + 'px';
            el.style.backgroundSize = '100%';
            el.style.borderRadius = '15px'

            containerDiv.appendChild(el)

            if (marker.properties.user.latestUserLocationByUserId?.createdAt) {
              const text = document.createTextNode(`${formatDistanceStrict(new Date(marker.properties.user.latestUserLocationByUserId?.createdAt), new Date(), { addSuffix: true })} `)

              containerDiv.appendChild(text)
            }


            // Add markers to the map.
            new mapboxgl.Marker(containerDiv)
              .setLngLat(marker.geometry.coordinates)
              .addTo(map);
          });

        });
      }




    }

  }, [mapContainer, data]);



  const currentYPos = HEADER_HEIGHT + BODY_TOP_PADDING + BREADCRUMBS_HEIGHT + BREADCRUMBS_PADDING

  return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <div ref={mapContainer} className="map-container" style={{ height: `calc(100vh - ${currentYPos}px)` }} />
  </div>
}