import React, { useState, useEffect } from 'react'

import { Typography, Radio, FormControl, RadioGroup, FormControlLabel, Paper } from '@material-ui/core';
import gql from 'graphql-tag';

import LoginModal from './Login'
import { Server } from './Home';
import RegisterModal from './Register'
import { PrimaryButton, SecondaryButton } from './styles';
export const AUTHENTICATE = gql`mutation Authenticate($email: String!, $password: String!) {
    __typename
    authenticate(input: {email: $email, password: $password}) {
      clientMutationId
      jwtToken
    }
  }`
export const REQUEST_PASSWORD_RESET = gql`mutation RequestPasswordReset($email: String!) {
    __typename
    requestPasswordReset(input: {email_: $email}) {
      clientMutationId
      boolean
    }
  }`

export const REGISTER_MUTATION = gql`
mutation RegisterUserMutation($username: String!, $email: String!, $password: String!) {
    registerUser(input: {username: $username, email: $email, password: $password}) {
      clientMutationId
    }
  }
`

interface Props {
    refetch: () => void
}

export default function RegisterLogin(props: Props) {
    const [loginMode, setLoginMode] = useState<boolean>(true)
    const [server, setServer] = useState<Server>('Prod')
    function reloadServer() {
        const val = localStorage.getItem('waldo_server')

        if (val === 'Dev') {
            setServer('Dev')
        } else if (val === 'Prod') {
            setServer('Prod')
        }
    }

    useEffect(() => {

        reloadServer()

    }, [])

    function serverMode() {



        return <div style={{ display: 'flex', marginTop: 50, marginBottom: 20 }}>
            <Typography style={{ flexDirection: 'row', marginRight: 15, marginTop: 9 }}>Server:</Typography>
            <FormControl>
                <RadioGroup style={{ flexDirection: 'row' }}>
                    <FormControlLabel
                        checked={server === 'Prod'}
                        control={<Radio style={{ color: 'grey' }} />}
                        label="Normal"
                        onChange={() => {
                            localStorage.setItem('waldo_server', 'Prod')
                            reloadServer()
                        }}
                    />
                    <FormControlLabel
                        checked={server === 'Dev'}
                        control={<Radio style={{ color: 'grey' }} />}
                        label="Test"
                        onChange={() => {
                            localStorage.setItem('waldo_server', 'Dev')
                            reloadServer()
                        }}
                    />
                </RadioGroup>
            </FormControl>
            <Typography style={{ marginLeft: 10, fontSize: 10, marginTop: 15 }}>Refresh page after switching server</Typography>

        </div>
    }
    return (
        loginMode ?
            <div style={{ margin: 'auto', paddingTop: 150, width: 600, }}>
                <Paper>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <LoginModal refetch={props.refetch} />
                        <div style={{ marginBottom: 40 }} />
                        <PrimaryButton onClick={() => { setLoginMode(false) }
                        }> Create New Account</PrimaryButton>
                        < div > {serverMode()}</div >
                    </div >
                </Paper>
            </div>
            :

            <div style={{ margin: 'auto', paddingTop: 150, width: 600, }}>
                <Paper>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <RegisterModal refetch={props.refetch} />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <SecondaryButton style={{ marginTop: 20, }} onClick={() => { setLoginMode(true) }}> Sign In</SecondaryButton>
                        <div style={{ marginLeft: 100, paddingBottom: 5 }}>{serverMode()}</div> </div>
                </Paper>
            </div>

    )
}
