import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import {
  AllJobs2, AllJobs2Variables,
} from './generated/AllJobs2'
import { UserContext } from './UserContext'
import { JobFragment as Job } from './generated/JobFragment'
import JobsTable from './JobsTable'
import { JOB_FRAGMENT } from './Crew'

export const ALL_JOBS = gql`query AllJobs2($userId: Int!, $first: Int) {
  allJobs(
    orderBy: ID_DESC
    filter: {
      or: [
        { creatorId: { equalTo: $userId } }
        {
          jobCrewsByJobId: {
            some: {
              crewByCrewId: {
                crewMembersByCrewId: { some: { userId: { equalTo: $userId } } }
              }
            }
          }
        }
      ]
    }
    first: $first
  ) {
    nodes {
      ...JobFragment
    }
  }
}

${JOB_FRAGMENT}

  `
interface Props {
  first?: number
}

function getNegativeOne() {
  return -1
}

export default function Jobs(props: Props) {
  const userContext = useContext(UserContext)
  const { data } = useQuery<AllJobs2, AllJobs2Variables>(ALL_JOBS, {
    variables: {
      userId: userContext?.currentUser.currentUser?.id || getNegativeOne(),
      first: props.first
    }
  })
  const dataSource: Job[] = []
  data?.allJobs?.nodes.map((node) =>
    node && dataSource.push(node)
  )
  return <div>
    <JobsTable data={dataSource} />
  </div>


}