import React, { } from 'react';
import './App.css';

import { CurrentUser } from './generated/CurrentUser'
import gql from 'graphql-tag'
import Routes from './Routes'
import RegisterLogin from './RegisterLogin';
import { ApolloProvider, useQuery } from '@apollo/react-hooks';
import { UserContext } from './UserContext';
import { createClient } from './createClient'
import ResetPassword from './ResetPassword';
import { Avatar, createMuiTheme, ThemeProvider } from '@material-ui/core';
import { NetworkStatus } from 'apollo-client';
import logo from './waldo_logo.png'

export const CURRENT_USER = gql`query CurrentUser {
  currentUser {
    id
    username
    avatarUrl
  }
}`


export const HEADER_HEIGHT = 70
export const BODY_TOP_PADDING = 10

const client = createClient()

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(49, 114, 180)',
    },
    secondary: {
      main: '#7b9bad',
    },
    background: {
      default: '#f0f0f0',
    },
  },
});

function App() {
  const { data, loading, refetch, networkStatus, error } = useQuery<CurrentUser>(CURRENT_USER)

  let body = <></>

  if (error) {
    localStorage.removeItem('token')
    body = <RegisterLogin refetch={refetch} />
  }
  else if (loading || networkStatus !== NetworkStatus.ready) {
    body = <div>Loading...</div>
  }
  else if (window.location.pathname.startsWith('/password_reset')) {
    body = <ResetPassword />
  }
  else if (data?.currentUser?.id) {
    body = <UserContext.Provider value={data ? { currentUser: data } : undefined}>

      <Routes refetch={refetch} />
    </UserContext.Provider>

  } else {
    // localStorage.removeItem('token')
    body = <RegisterLogin refetch={refetch} />
  }

  return <>
    <div style={{ display: 'flex', flexFlow: 'rowWrap', justifyContent: 'space-between', height: HEADER_HEIGHT, backgroundColor: '#3172B4', alignItems: 'center', paddingLeft: 10 }}>
      <img style={{}} src={logo} height={50} alt="logo" />
      <div>

        {data?.currentUser?.username && <a href="/my_profile"><Avatar
          style={{ width: 50, height: 50, marginRight: 10 }}
          onClick={() => {

          }}
          src={data.currentUser.avatarUrl || ''}

        /></a>
        }
      </div>
    </div>
    <div style={{ padding: 20, paddingTop: BODY_TOP_PADDING }}>
      {body}
    </div>
  </>

}



function AppWrapper() {

  // const { data } = useQuery<UserQuery, UserQueryVariables>(USER_QUERY, {
  //   variables: {
  //     userId: Number(props.userId)
  //   }
  // });
  // const id = data?.userById?.id

  return <ApolloProvider client={client}>

    <ThemeProvider theme={theme}>

      <div style={{ paddingTop: 0, backgroundColor: '#e6e6e6', minHeight: window.innerHeight }}>
        <App />
      </div>
    </ThemeProvider>
  </ApolloProvider>
}


export default AppWrapper;
