import { useMutation, useQuery, } from '@apollo/react-hooks'
import { IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff, ArrowUpward, ArrowDownward } from '@material-ui/icons'
import gql from 'graphql-tag'
import React, { useContext, useState } from 'react'

import {
    ConfigureWoodLoadFieldsQuery,
    ConfigureWoodLoadFieldsQueryVariables,
    ConfigureWoodLoadFieldsQuery_companyById_userWoodLoadFieldsByCompanyId_nodes as UserWoodLoadField,
    ConfigureWoodLoadFieldsQuery_companyById_woodLoadFieldSettingsByCompanyId_nodes as WoodLoadFieldSetting
} from '../generated/ConfigureWoodLoadFieldsQuery'
import { CreateWoodLoadFieldSetting, CreateWoodLoadFieldSettingVariables } from '../generated/CreateWoodLoadFieldSetting'
import { UpdateWoodLoadFieldSetting, UpdateWoodLoadFieldSettingVariables } from '../generated/UpdateWoodLoadFieldSetting'
import { UserContext } from '../UserContext'
import { PageTitle } from '../WaldoStyle'
import WaldoTable from '../WaldoTable'
import { isPresent } from '../utils'
import { RectangleButton } from '../styles'
import { UpdateWoodLoadVariables } from '../generated/UpdateWoodLoad'
import CreateEditUserWoodLoadFieldDialog from './CreateEditUserWoodLoadFieldDialog'



const QUERY = gql`query ConfigureWoodLoadFieldsQuery($companyId: Int!) {
    companyById(id: $companyId) {
      id
      name
      userWoodLoadFieldsByCompanyId {
        nodes {
          id
          name
        }
      }
      woodLoadFieldSettingsByCompanyId {
        nodes {
          id
          index
          hide
          nativeFieldName
          userWoodLoadFieldId
        }
      }
    }
  }
  `


const CREATE_WOOD_LOAD_FIELD_SETTING = gql`mutation CreateWoodLoadFieldSetting(
    $companyId: Int!
    $creatorId: Int!
    $hide: Boolean
    $index: Int
    $nativeFieldName: String
    $userWoodLoadFieldId: Int
  ) {
    __typename
    createWoodLoadFieldSetting(
      input: {
        woodLoadFieldSetting: {
          creatorId: $creatorId
          companyId: $companyId
          hide: $hide
          index: $index
          nativeFieldName: $nativeFieldName
          userWoodLoadFieldId: $userWoodLoadFieldId
        }
      }
    ) {
      clientMutationId
    }
  }
  `

const UPDATE_WOOD_LOAD_FIELD_SETTING = gql`mutation UpdateWoodLoadFieldSetting(
    $id: Int!
    $hide: Boolean
    $index: Int
  ) {
    __typename
    updateWoodLoadFieldSettingById(
      input: {
        woodLoadFieldSettingPatch: {
          hide: $hide
          index: $index
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
  `

export const UPDATE_WOOD_LOAD = gql`mutation UpdateWoodLoad(
    $id: Int!
    $pickUpLocation: GeoJSON
    $notes: String
    $loadType: String
    $speciesId: Int
    $speciesGroupName: String
    $published: Boolean
    $jobId: Int
    $woodLoadDestinationId: Int
    $haulRate: Float
    $sendPublishedNotification: Boolean
    $unit: WoodLoadUnit
    $mileage: Float
    $tripTicketNumber: String
    $value: Float
    $scaleTicketNumber: String
    $estimatedDropOff: Datetime
    $loggingCompanyId: Int
    $estimatedVolume: Float
    $woodOrderId: Int
  ) {
    __typename
    updateWoodLoadById(
      input: {
        woodLoadPatch: {
          notes: $notes
          loadType: $loadType
          published: $published
          jobId: $jobId
          woodLoadDestinationId: $woodLoadDestinationId
          pickUpLocation: $pickUpLocation
          haulRate: $haulRate
          sendPublishedNotification: $sendPublishedNotification
          mileage: $mileage
          unit: $unit
          woodSpeciesId: $speciesId
          speciesGroupName: $speciesGroupName
          tripTicketNumber: $tripTicketNumber
          value: $value
          scaleTicketNumber: $scaleTicketNumber
          estimatedDroppedOffAt: $estimatedDropOff
          loggingCompanyId: $loggingCompanyId
          estimatedVolume: $estimatedVolume
          woodOrderId: $woodOrderId
        }
        id: $id
      }
    ) {
      clientMutationId
      woodLoad {
        id
        pickUpLocation {
          latitude
          longitude
        }
      }
    }
  }
  `


export type ConfigurableWoodLoadField = keyof Omit<UpdateWoodLoadVariables, 'id' | 'archivedAt'>


interface NativeWoodLoadField {
    displayName: string
    apiName: ConfigurableWoodLoadField
}

const NATIVE_WOOD_LOAD_FIELDS: NativeWoodLoadField[] = [
    {
        displayName: 'Wood Order',
        apiName: 'woodOrderId'
    },
    {
        displayName: 'Species Group',
        apiName: 'speciesGroupName'
    },
    {
        displayName: 'Species',
        apiName: 'speciesId'
    },
    {
        displayName: 'Destination',
        apiName: 'woodLoadDestinationId'
    },
    {
        displayName: 'Haul Rate',
        apiName: 'haulRate'
    },
    {
        displayName: 'Mileage',
        apiName: 'mileage'
    },
    {
        displayName: 'Load Unit',
        apiName: 'unit'
    },
    {
        displayName: 'Notes',
        apiName: 'notes'
    }
]

interface WoodLoadFieldRow {
    nativeField?: NativeWoodLoadField
    userField?: UserWoodLoadField
    woodLoadFieldSetting?: WoodLoadFieldSetting
}

export default function ConfigureWoodLoadFields(props: { companyId: number }) {
    const userContext = useContext(UserContext)

    const { data, refetch } = useQuery<ConfigureWoodLoadFieldsQuery, ConfigureWoodLoadFieldsQueryVariables>(QUERY, {
        variables: {
            companyId: props.companyId
        }
    })

    const [updateSetting] = useMutation<UpdateWoodLoadFieldSetting, UpdateWoodLoadFieldSettingVariables>(UPDATE_WOOD_LOAD_FIELD_SETTING)
    const [createSetting] = useMutation<CreateWoodLoadFieldSetting, CreateWoodLoadFieldSettingVariables>(CREATE_WOOD_LOAD_FIELD_SETTING)

    const [newFieldOpen, setNewFieldOpen] = useState<boolean>(false)

    const rows: WoodLoadFieldRow[] = NATIVE_WOOD_LOAD_FIELDS.map(njf => {
        return {
            nativeField: njf,
            woodLoadFieldSetting: data?.companyById?.woodLoadFieldSettingsByCompanyId.nodes.find(n => n?.nativeFieldName === njf.apiName) ?? undefined
        }
    })

    for (const userField of data?.companyById?.userWoodLoadFieldsByCompanyId.nodes.filter(isPresent) || []) {
        rows.push({
            userField: userField,
            woodLoadFieldSetting: data?.companyById?.woodLoadFieldSettingsByCompanyId.nodes.find(n => n?.userWoodLoadFieldId === userField.id) ?? undefined
        })
    }



    rows.sort((a, b) => {
        const aIdx = a.woodLoadFieldSetting?.index
        const bIdx = b.woodLoadFieldSetting?.index
        if (isPresent(aIdx) && isPresent(bIdx)) {
            if (aIdx < bIdx) {
                return -1
            } else {
                return 1
            }
        } else if (b.woodLoadFieldSetting?.index) {
            return 1
        } else if (a.woodLoadFieldSetting?.index) {
            return -1
        } else {
            return 0
        }
    })


    async function updateIndexes() {
        for (let idx = 0; idx < rows.length; idx++) {
            const el = rows[idx]
            if (el.woodLoadFieldSetting) {
                if (el.woodLoadFieldSetting.index !== idx) {
                    await updateSetting({
                        variables: {
                            id: el.woodLoadFieldSetting.id,
                            index: idx
                        }
                    })
                }
            } else {
                await createSetting({
                    variables: {
                        creatorId: userContext?.currentUser.currentUser?.id ?? -1,
                        companyId: props.companyId,
                        ...(el.nativeField ? { nativeFieldName: el.nativeField.apiName } : { userWoodLoadFieldId: el.userField?.id }),
                        index: idx
                    }
                })
            }
        }
    }

    return <div>
        <PageTitle>Configure Wood Load Fields for "{data?.companyById?.name}"</PageTitle>

        <RectangleButton
            onClick={() => {
                setNewFieldOpen(true)
            }}
            variant="contained"
            color="primary"
        >CREATE NEW WOOD LOAD FIELD</RectangleButton>

        <WaldoTable
            data={rows.map((r, idx) => ({ ...r, idx }))}
            columns={[
                {
                    header: 'Name',
                    render: r => r.nativeField ? r.nativeField.displayName : r.userField?.name
                },
                {
                    header: 'is native',
                    render: r => Boolean(r.woodLoadFieldSetting)
                },

                {
                    header: 'Visibility',
                    render: r => <IconButton onClick={async () => {
                        if (r.woodLoadFieldSetting) {
                            await updateSetting({
                                variables: {
                                    id: r.woodLoadFieldSetting.id,
                                    hide: !r.woodLoadFieldSetting.hide
                                }
                            })
                        } else {
                            await createSetting({
                                variables: {
                                    creatorId: userContext?.currentUser.currentUser?.id ?? -1,
                                    companyId: props.companyId,
                                    ...(r.nativeField ? { nativeFieldName: r.nativeField.apiName } : { userWoodLoadFieldId: r.userField?.id }),
                                    hide: true
                                }
                            })
                        }
                        await refetch()
                    }}>
                        {r.woodLoadFieldSetting?.hide ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                },
                {
                    header: 'Order',
                    render: r => <>
                        <IconButton onClick={async () => {
                            if (r.idx === 0 || rows.length === 1) {
                                return
                            }

                            // Swap
                            const s = rows[r.idx - 1]
                            rows[r.idx - 1] = rows[r.idx]
                            rows[r.idx] = s

                            await updateIndexes()

                            await refetch()
                        }}>
                            <ArrowUpward />
                        </IconButton>

                        <IconButton onClick={async () => {
                            if (r.idx === rows.length - 1) {
                                return
                            }

                            // Swap
                            const s = rows[r.idx + 1]
                            rows[r.idx + 1] = rows[r.idx]
                            rows[r.idx] = s

                            await updateIndexes()

                            await refetch()
                        }}>
                            <ArrowDownward />
                        </IconButton>
                    </>
                }
            ]}
        />

        {
            newFieldOpen && <CreateEditUserWoodLoadFieldDialog
                open={newFieldOpen}
                setOpen={async () => {
                    await refetch()
                    setNewFieldOpen(false)
                }}
                companyId={props.companyId}
            />
        }

    </div>
}