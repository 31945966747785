import React, { useState, useContext } from 'react';
import gql from 'graphql-tag';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@material-ui/core';

import {
    CreateWoodLoadState,
    CreateWoodLoadStateVariables,
} from '../generated/CreateWoodLoadState';


import {
    GetWoodLoadStates_companyById_woodLoadStatesByCompanyId_nodes as WoodLoadState,
} from '../generated/GetWoodLoadStates';
import { UpdateWoodLoadState, UpdateWoodLoadStateVariables } from '../generated/UpdateWoodLoadState';
import { UserContext } from '../UserContext';
import { useMutation } from '@apollo/react-hooks';

export const CREATE = gql`mutation CreateWoodLoadState(
    $companyId: Int!
    $name: String!
    $creatorId: Int!
    $color: String
  ) {
    __typename
    createWoodLoadState(
      input: {
        woodLoadState: {
          companyId: $companyId
          name: $name
          creatorId: $creatorId
          color: $color
        }
      }
    ) {
      clientMutationId
    }
  }
  `;

const UPDATE = gql`mutation UpdateWoodLoadState($id: Int!, $name: String!, $color: String) {
    __typename
    updateWoodLoadStateById(
      input: { id: $id, woodLoadStatePatch: { name: $name, color: $color } }
    ) {
      clientMutationId
    }
  }
  `

interface Props {
    open: boolean;
    setOpen: (val: boolean) => void;
    companyId: number
    woodLoadState?: WoodLoadState
}

export default function CreateEditWoodLoadStateDialog(props: Props) {
    const userContext = useContext(UserContext);

    const [create] =
        useMutation<CreateWoodLoadState, CreateWoodLoadStateVariables>(CREATE);

    const [name, setName] = useState<string>(props.woodLoadState?.name || '');
    const [color, setColor] = useState<string>(props.woodLoadState?.color || '');

    const [update] = useMutation<UpdateWoodLoadState, UpdateWoodLoadStateVariables>(UPDATE)

    return (
        <Dialog
            disableBackdropClick
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{props.woodLoadState ? 'Edit' : 'Create'} State</DialogTitle>
            <DialogContent>
                <div style={{ display: 'grid', alignItems: 'center', columnGap: 10, rowGap: 10 }}>
                    <Typography style={{ gridColumn: '1' }}>Name:</Typography>
                    <TextField
                        autoFocus
                        variant="outlined"
                        style={{ gridColumn: '2' }}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />

                    <Typography style={{ gridColumn: '1' }}>Color:</Typography>
                    <input type="color"
                        value={color} onChange={event => setColor(event.target.value)} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpen(false)} variant="outlined">
                    Cancel
                </Button>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            if (props.woodLoadState) {
                                await update({
                                    variables: {
                                        id: props.woodLoadState.id,
                                        name,
                                        color: color || null
                                    }
                                })
                            } else {
                                await create({
                                    variables: {
                                        name,
                                        creatorId: userContext?.currentUser.currentUser?.id || -1,
                                        companyId: props.companyId,
                                        color: color || null
                                    },
                                });
                            }

                            props.setOpen(false);
                        }}
                    >
                        Save!
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}