import React from 'react';
import { Breadcrumbs, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface NamedLink {
    label: string
    location: string
}

export const BREADCRUMBS_HEIGHT = 40
export const BREADCRUMBS_PADDING = 10

export default function SimpleBreadcrumbs({ history, prefix }: { history: any, prefix: string }) {

    let path: string = history.location.pathname
    path = path.replace(/\/$/, '')
    path = path.replace(/\//, '')

    if (path.startsWith(prefix)) {
        path = path.slice(prefix.length)
    }

    const links: NamedLink[] = []
    links.push({
        label: 'Home',
        location: '/'
    })


    let currentPath = ''
    path.split('/').forEach(val => {
        if (val) {
            currentPath += '/' + val
            links.push({
                label: val,
                location: currentPath
            })
        }

    })

    return (
        <div style={{ display: 'flex', backgroundColor: '#e6e6e6', alignItems: 'center', paddingBottom: BREADCRUMBS_PADDING, }}>
            <Paper style={{ width: '100%', height: BREADCRUMBS_HEIGHT, display: 'flex', alignItems: 'center' }}>


                <Breadcrumbs style={{ paddingLeft: 15 }}>

                    {links.map((link) => (

                        <Link to={link.location}>{link.label}</Link>


                    ))}
                </Breadcrumbs>

            </Paper>
            <br />
        </div>
    );
}