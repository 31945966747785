import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Avatar, Divider, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core'
import { CrewByCrewId, CrewByCrewIdVariables, CrewByCrewId_crewById_crewMembersByCrewId_nodes_userByUserId as User } from './generated/CrewByCrewId'
import JobsTable from './JobsTable'
import { JobFragment as Job } from './generated/JobFragment'
import { RectangleButton } from './styles'
import HistoryContext from './HistoryContext'



export const JOB_FRAGMENT = gql`fragment JobFragment on Job {
    id
    creatorId
    contractName
    county
    createdAt
    jobMembersByJobId {
      totalCount
    }
    township
    woodLoadsByJobId {
      totalCount
    }
    companyByCompanyId {
      id
      name
    }
    jobCrewsByJobId {
      totalCount
    }
  }`

const CREW = gql`query CrewByCrewId($id: Int!) {
    crewById(id: $id) {
      crewMembersByCrewId {
        nodes {
          userByUserId {
            avatarUrl
            id
            username
          }
        }
      }
      creatorId
      companyByCompanyId {
        name
        avatarUrl
      }
      name
      type2
      jobCrewsByCrewId {
        nodes {
          jobByJobId {
            ...JobFragment
          }
        }
      }
    }
  }
  ${JOB_FRAGMENT}
  `
interface Props {
  crewId: string
}

export default function Crew(props: Props) {

  const historyContext = useContext(HistoryContext)

  const [viewMembers, setViewMembers] = useState<boolean>(true)
  const { data } = useQuery<CrewByCrewId, CrewByCrewIdVariables>(CREW, {
    variables: {
      id: Number(props.crewId)
    }
  })
  const dataSource: Job[] = []
  data?.crewById?.jobCrewsByCrewId.nodes?.map(e =>
    e?.jobByJobId && dataSource.push(e.jobByJobId)
  )
  function getMembers(): User[] {
    const users: User[] = []
    data?.crewById?.crewMembersByCrewId.nodes.forEach(n => n?.userByUserId && users.push(n.userByUserId))
    return users
  }



  return <div>

    <div style={{ justifyContent: 'center', display: 'flex' }}>
      <Avatar style={{ marginRight: 5, marginBottom: 15 }} src={data?.crewById?.companyByCompanyId?.avatarUrl || ''} />
      <div style={{ marginTop: 10, fontSize: 15, fontWeight: 'bold' }}>
        {data?.crewById?.companyByCompanyId?.name}
      </div>
    </div>
    <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{data?.crewById?.name}</div>
    <div style={{ textAlign: 'center' }}>Crew type: {data?.crewById?.type2}</div>
    <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>


      <RectangleButton
        variant="outlined"
        style={{ color: viewMembers ? 'white' : 'black', backgroundColor: viewMembers ? '#3172B4' : 'white' }}
        onClick={() => {
          setViewMembers(true)
        }}>Members</RectangleButton>


      <RectangleButton variant="outlined"
        style={{ color: viewMembers ? 'black' : 'white', backgroundColor: viewMembers ? 'white' : '#3172B4' }}
        onClick={() => {
          setViewMembers(false)
        }}>Jobs</RectangleButton>



    </div>

    {
      viewMembers &&
      <Paper style={{ height: 100 }}>
        <List>
          <ListItem><Typography style={{ fontSize: 15, fontWeight: 'bold' }}>Crew Members</Typography></ListItem>
          <Divider />
          {getMembers().map((user) => {
            return (
              <><><ListItem button onClick={() => {

                historyContext.history?.push(`/users/${user.id}`)

              }} style={{ backgroundColor: 'white' }}>
                <Avatar src={user?.avatarUrl || ''} />
                <ListItemText style={{ marginLeft: 10 }} primary={user?.username} />
              </ListItem>
                <Divider />
              </>
              </>
            );
          })}
        </List>
      </Paper>
    }
    {!viewMembers && <JobsTable data={dataSource} />}
  </div >
}