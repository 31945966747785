import { useMutation, useQuery, } from '@apollo/react-hooks'
import { Checkbox, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff, ArrowUpward, ArrowDownward } from '@material-ui/icons'
import gql from 'graphql-tag'
import React, { useContext, useState } from 'react'

import {
    ConfigureJobFieldsQuery,
    ConfigureJobFieldsQueryVariables,
    ConfigureJobFieldsQuery_companyById_jobFieldSettingsByCompanyId_nodes as JobFieldSetting,
    ConfigureJobFieldsQuery_companyById_userJobFieldsByCompanyId_nodes as UserJobField
} from '../generated/ConfigureJobFieldsQuery'
import { CreateJobFieldSetting, CreateJobFieldSettingVariables } from '../generated/CreateJobFieldSetting'
import { UpdateJobVariables } from '../generated/UpdateJob'
import { UpdateJobFieldSetting, UpdateJobFieldSettingVariables } from '../generated/UpdateJobFieldSetting'
import { UserContext } from '../UserContext'
import { PageTitle } from '../WaldoStyle'
import WaldoTable from '../WaldoTable'
import { isPresent } from '../utils'
import { RectangleButton } from '../styles'
import CreateEditUserJobFieldDialog from './CreateEditUserJobFieldDialog'



const QUERY = gql`query ConfigureJobFieldsQuery($companyId: Int!) {
    companyById(id: $companyId) {
      id
      name
      userJobFieldsByCompanyId {
        nodes {
          id
          name
        }
      }
      jobFieldSettingsByCompanyId {
        nodes {
          id
          index
          hide
          nativeFieldName
          userJobFieldId
          isPrimary
        }
      }
    }
  }
  `

// eslint-disable-next-line
const UPDATE_JOB = gql`mutation UpdateJob(
    $id: Int!
    $companyId: Int
    $contractName: String
    $contractNumber: String
    $county: String
    $jobStart: Datetime
    $jobEnd: Datetime
    $range2: String
    $township: String
    $section: String
    $archived: Boolean
    $logRoadId: Int
    $pausedBy: Int
    $allowTruckerAcceptApplications: Boolean
    $purchasedMbf: Float
    $allowIndependentTruckerEditDestination: Boolean
    $allowPublicLoads: Boolean
    $serviceContractName: String
  ) {
    __typename
    updateJobById(
      input: {
        jobPatch: {
          contractName: $contractName
          contractNumber: $contractNumber
          county: $county
          jobEnd: $jobEnd
          jobStart: $jobStart
          range2: $range2
          township: $township
          section: $section
          companyId: $companyId
          archived: $archived
          logRoadId: $logRoadId
          pausedBy: $pausedBy
          allowTruckerAcceptApplications: $allowTruckerAcceptApplications
          purchasedMbf: $purchasedMbf
          allowIndependentTruckerEditDestination: $allowIndependentTruckerEditDestination
          allowPublicLoads: $allowPublicLoads
          serviceContractName: $serviceContractName
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
  `

const CREATE_JOB_FIELD_SETTING = gql`mutation CreateJobFieldSetting(
    $companyId: Int!
    $creatorId: Int!
    $hide: Boolean
    $index: Int
    $nativeFieldName: String
    $userJobFieldId: Int
    $isPrimary: Boolean
  ) {
    __typename
    createJobFieldSetting(
      input: {
        jobFieldSetting: {
          creatorId: $creatorId
          companyId: $companyId
          hide: $hide
          index: $index
          nativeFieldName: $nativeFieldName
          userJobFieldId: $userJobFieldId
          isPrimary: $isPrimary
        }
      }
    ) {
      clientMutationId
    }
  }
  `

const UPDATE_JOB_FIELD_SETTING = gql`mutation UpdateJobFieldSetting($id: Int!, $hide: Boolean, $index: Int, $isPrimary: Boolean) {
    __typename
    updateJobFieldSettingById(
      input: { jobFieldSettingPatch: { hide: $hide, index: $index, isPrimary: $isPrimary }, id: $id }
    ) {
      clientMutationId
    }
  }
  `


export type ConfigurableJobField = keyof Omit<UpdateJobVariables, 'id' | 'archivedAt'>


interface NativeJobField {
    displayName: string
    apiName: ConfigurableJobField
}

const NATIVE_JOB_FIELDS: NativeJobField[] = [
    {
        displayName: 'Contract Name',
        apiName: 'contractName'
    },
    {
        displayName: 'Contract Number',
        apiName: 'contractNumber'
    },
    {
        displayName: 'Service Contract ID',
        apiName: 'serviceContractName'
    },
    {
        displayName: 'County',
        apiName: 'county'
    },
    {
        displayName: 'Township',
        apiName: 'township'
    },
    {
        displayName: 'Range',
        apiName: 'range2'
    },
    {
        displayName: 'Section',
        apiName: 'section'
    },
    {
        displayName: 'Start',
        apiName: 'jobStart'
    },
    {
        displayName: 'End',
        apiName: 'jobEnd'
    },
    {
        displayName: 'Purchased MBF',
        apiName: 'purchasedMbf'
    },
    {
        displayName: 'Log Road',
        apiName: 'logRoadId'
    }
]

interface JobFieldRow {
    nativeField?: NativeJobField
    userField?: UserJobField
    jobFieldSetting?: JobFieldSetting
}

export default function ConfigureJobFields(props: { companyId: number }) {
    const userContext = useContext(UserContext)

    const { data, refetch } = useQuery<ConfigureJobFieldsQuery, ConfigureJobFieldsQueryVariables>(QUERY, {
        variables: {
            companyId: props.companyId
        }
    })

    const [updateSetting] = useMutation<UpdateJobFieldSetting, UpdateJobFieldSettingVariables>(UPDATE_JOB_FIELD_SETTING)
    const [createSetting] = useMutation<CreateJobFieldSetting, CreateJobFieldSettingVariables>(CREATE_JOB_FIELD_SETTING)

    const [newFieldOpen, setNewFieldOpen] = useState<boolean>(false)

    const rows: JobFieldRow[] = NATIVE_JOB_FIELDS.map(njf => {
        return {
            nativeField: njf,
            jobFieldSetting: data?.companyById?.jobFieldSettingsByCompanyId.nodes.find(n => n?.nativeFieldName === njf.apiName) ?? undefined
        }
    })

    for (const userField of data?.companyById?.userJobFieldsByCompanyId.nodes.filter(isPresent) || []) {
        rows.push({
            userField: userField,
            jobFieldSetting: data?.companyById?.jobFieldSettingsByCompanyId.nodes.find(n => n?.userJobFieldId === userField.id) ?? undefined
        })
    }



    rows.sort((a, b) => {
        const aIdx = a.jobFieldSetting?.index
        const bIdx = b.jobFieldSetting?.index
        if (isPresent(aIdx) && isPresent(bIdx)) {
            if (aIdx < bIdx) {
                return -1
            } else {
                return 1
            }
        } else if (b.jobFieldSetting?.index) {
            return 1
        } else if (a.jobFieldSetting?.index) {
            return -1
        } else {
            return 0
        }
    })


    async function updateIndexes() {
        for (let idx = 0; idx < rows.length; idx++) {
            const el = rows[idx]
            if (el.jobFieldSetting) {
                if (el.jobFieldSetting.index !== idx) {
                    await updateSetting({
                        variables: {
                            id: el.jobFieldSetting.id,
                            index: idx
                        }
                    })
                }
            } else {
                await createSetting({
                    variables: {
                        creatorId: userContext?.currentUser.currentUser?.id ?? -1,
                        companyId: props.companyId,
                        ...(el.nativeField ? { nativeFieldName: el.nativeField.apiName } : { userJobFieldId: el.userField?.id }),
                        index: idx
                    }
                })
            }
        }
    }

    return <div>
        <PageTitle>Configure Job Fields for "{data?.companyById?.name}"</PageTitle>

        <RectangleButton
            onClick={() => {
                setNewFieldOpen(true)
            }}
            variant="contained"
            color="primary"
        >CREATE NEW JOB FIELD</RectangleButton>

        <WaldoTable
            data={rows.map((r, idx) => ({ ...r, idx }))}
            columns={[
                {
                    header: 'Name',
                    render: r => r.nativeField ? r.nativeField.displayName : r.userField?.name
                },
                {
                    header: 'is native',
                    render: r => Boolean(r.jobFieldSetting)
                },
                {
                    header: 'is Primary',
                    render: r => <Checkbox
                        checked={Boolean(r.jobFieldSetting?.isPrimary)}
                        onChange={async event => {
                            if (r.jobFieldSetting) {
                                await updateSetting({
                                    variables: {
                                        id: r.jobFieldSetting.id,
                                        isPrimary: event.target.checked
                                    }
                                })
                            } else {
                                await createSetting({
                                    variables: {
                                        creatorId: userContext?.currentUser.currentUser?.id ?? -1,
                                        companyId: props.companyId,
                                        ...(r.nativeField ? { nativeFieldName: r.nativeField.apiName } : { userJobFieldId: r.userField?.id }),
                                        isPrimary: event.target.checked
                                    }
                                })
                            }
                            await refetch()
                        }}
                    />
                },
                {
                    header: 'Visibility',
                    render: r => <IconButton onClick={async () => {
                        if (r.jobFieldSetting) {
                            await updateSetting({
                                variables: {
                                    id: r.jobFieldSetting.id,
                                    hide: !r.jobFieldSetting.hide
                                }
                            })
                        } else {
                            await createSetting({
                                variables: {
                                    creatorId: userContext?.currentUser.currentUser?.id ?? -1,
                                    companyId: props.companyId,
                                    ...(r.nativeField ? { nativeFieldName: r.nativeField.apiName } : { userJobFieldId: r.userField?.id }),
                                    hide: true
                                }
                            })
                        }
                        await refetch()
                    }}>
                        {r.jobFieldSetting?.hide ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                },
                {
                    header: 'Order',
                    render: r => <>
                        <IconButton onClick={async () => {
                            if (r.idx === 0 || rows.length === 1) {
                                return
                            }

                            // Swap
                            const s = rows[r.idx - 1]
                            rows[r.idx - 1] = rows[r.idx]
                            rows[r.idx] = s

                            await updateIndexes()

                            await refetch()
                        }}>
                            <ArrowUpward />
                        </IconButton>

                        <IconButton onClick={async () => {
                            if (r.idx === rows.length - 1) {
                                return
                            }

                            // Swap
                            const s = rows[r.idx + 1]
                            rows[r.idx + 1] = rows[r.idx]
                            rows[r.idx] = s

                            await updateIndexes()

                            await refetch()
                        }}>
                            <ArrowDownward />
                        </IconButton>
                    </>
                }
            ]}
        />

        {
            newFieldOpen && <CreateEditUserJobFieldDialog
                open={newFieldOpen}
                setOpen={async () => {
                    await refetch()
                    setNewFieldOpen(false)
                }}
                companyId={props.companyId}
            />
        }

    </div>
}