import gql from 'graphql-tag'
import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks';
import {
  CompanyWoodLoads, CompanyWoodLoadsVariables,
  CompanyWoodLoads_allWoodLoads_edges_node as WoodLoad,
} from './generated/CompanyWoodLoads'
import { WOOD_LOAD_FRAGMENT } from './AllWoodLoads'
import { UserContext } from './UserContext'
import WoodLoadsTable from './WoodLoads/WoodLoadsTable';

const COMPANY_WOODLOAD = gql`query CompanyWoodLoads($userId: Int!, $first: Int!, $from: Int!) {
  allWoodLoads(
    filter: {
      jobByJobId: {
        jobCrewsByJobId: {
          some: {
            crewByCrewId: {
              crewMembersByCrewId: { some: { userId: { equalTo: $userId } } }
            }
          }
        }
      }
    }
    first: $first
    offset: $from
    orderBy: CREATED_AT_DESC
  ) {
    edges {
      node {
        ...WoodLoadFragment
      }
    }
    totalCount
  }
}


${WOOD_LOAD_FRAGMENT}
`
interface Props {
  first: number
}
function getNegativeOne() {
  return -1
}



export default function RecentWoodLoads(props: Props) {
  const userContext = useContext(UserContext)
  const [first, setFirst] = useState<number>(props.first)
  const [from, setFrom] = useState<number>(0)

  let total = 0

  const { data } = useQuery<CompanyWoodLoads, CompanyWoodLoadsVariables>(COMPANY_WOODLOAD, {
    variables: {
      userId: userContext?.currentUser?.currentUser?.id || getNegativeOne(),
      first: first,
      from: from,
    }
  })


  total = data?.allWoodLoads?.totalCount || -1
  const loads: WoodLoad[] = []
  data?.allWoodLoads?.edges.forEach(t => t.node && loads.push(t.node))
  loads.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())


  return <div style={{ textAlign: 'center', marginBottom: 35 }}>

    <WoodLoadsTable
      total={total}
      data={loads}
      setFirst={(val) => setFirst(val)}
      setFrom={(val) => setFrom(val)}

    />

  </div>


}



