const options: Intl.DateTimeFormatOptions = { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };

export function dateToTimestamp(date: Date): number {
    return Math.floor(date.getTime() / 1000)
}

export function timestampToDate(timestamp: number): Date {
    return new Date(timestamp * 1000)
}

export function dateToString(date: Date): string {
    return date.toLocaleDateString('en-US', options)
}

export function timestampToString(timestamp: number): string {
    return timestampToDate(timestamp).toLocaleDateString('en-US', options)
}

export function now(): number {
    return dateToTimestamp(new Date())
}

export function parseFloatDefault<T>(s: string, defaultValue: T) {
    const val = parseFloat(s)
    if (isNaN(val)) {
        return defaultValue
    }
    return val
}


export function isPresent<T>(val: T | undefined | null): val is T {
    return val !== null && val !== undefined
}

