import React, { useState } from 'react';
import gql from 'graphql-tag';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@material-ui/core';

import { useMutation } from '@apollo/react-hooks';
import { UpdatePublicCompanyFile, UpdatePublicCompanyFileVariables } from '../generated/UpdatePublicCompanyFile';

import {
    GetCompanyFiles_companyById_publicCompanyFilesByCompanyId_nodes as CompanyFile
} from '../generated/GetCompanyFiles'


export const UPDATE = gql`mutation UpdatePublicCompanyFile(
    $id: Int!
    $archivedAt: Datetime
    $name: String
  ) {
    __typename
    updatePublicCompanyFileById(
      input: {
        publicCompanyFilePatch: { archivedAt: $archivedAt, name: $name }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
  
  `

interface Props {
    open: boolean;
    setOpen: (val: boolean) => void;
    file: CompanyFile
}

export default function EditFileDialog(props: Props) {

    const [update] =
        useMutation<UpdatePublicCompanyFile, UpdatePublicCompanyFileVariables>(UPDATE);

    const [name, setName] = useState<string>(props.file.name || '');

    return (
        <Dialog
            disableBackdropClick
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Edit File</DialogTitle>
            <DialogContent>
                <div style={{ display: 'grid', alignItems: 'center', columnGap: 10, rowGap: 10 }}>
                    <Typography style={{ gridColumn: '1' }}>Name:</Typography>
                    <TextField
                        autoFocus
                        variant="outlined"
                        style={{ gridColumn: '2' }}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />

                    <Button
                        variant="outlined"
                        style={{ color: 'red' }}
                        onClick={async () => {
                            await update({
                                variables: {
                                    id: props.file.id,
                                    archivedAt: new Date()
                                }
                            })
                            await props.setOpen(false)
                        }}>DELETE FILE</Button>

                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpen(false)} variant="outlined">
                    Cancel
                </Button>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            await update({
                                variables: {
                                    id: props.file.id,
                                    name
                                }
                            })

                            props.setOpen(false);
                        }}
                    >
                        Save!
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}