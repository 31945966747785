import React, { useState } from 'react'
import { TextField } from '@material-ui/core';

import { ReplacePasswordFromResetToken, ReplacePasswordFromResetTokenVariables } from './generated/ReplacePasswordFromResetToken'
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import queryString from 'query-string'
import { PrimaryButton, SecondaryTitle } from './styles';




const RESET_PASSWORD = gql`mutation ReplacePasswordFromResetToken($token: String!, $password: String!) {
    __typename
    replacePasswordFromResetToken(
      input: { password_: $password, token_: $token }
    ) {
      clientMutationId
      boolean
    }
  }`

export default function ResetPassword() {
    const [newPassword, setNewPassword] = useState<string>('')

    const [replacePassword] = useMutation<ReplacePasswordFromResetToken, ReplacePasswordFromResetTokenVariables>(RESET_PASSWORD)

    const [result, setResult] = useState<undefined | boolean>(undefined)

    function getBody() {
        if (result === true) {
            return <p>Password reset successful.</p>
        } else if (result === false) {
            return <p style={{ color: 'red' }}>Password reset failed!</p>
        } else {
            return <>
                <div style={{ margin: 'auto' }}>
                    <TextField style={{ width: 300 }} placeholder="New Password" margin="dense" variant="outlined" type="password" value={newPassword} onChange={(text) => setNewPassword(text.target.value)} />
                </div>
                <PrimaryButton style={{ marginTop: 10 }} onClick={async () => {
                    const res = await replacePassword({
                        variables: {
                            token: queryString.parse(window.location.search).token as string,
                            password: newPassword
                        }
                    })

                    setResult(res.data?.replacePasswordFromResetToken?.boolean === null ? undefined : res.data?.replacePasswordFromResetToken?.boolean)
                }}>Submit</PrimaryButton>
            </>
        }
    }

    return <div style={{ textAlign: 'center', paddingTop: 20, paddingBottom: 20 }}>
        <SecondaryTitle>Password Reset</SecondaryTitle>
        {getBody()}
    </div>
}