import React, { useState, useContext } from 'react';
import gql from 'graphql-tag';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@material-ui/core';


import { UserContext } from '../UserContext';
import { useMutation } from '@apollo/react-hooks';
import { CreateUserWoodLoadField, CreateUserWoodLoadFieldVariables } from '../generated/CreateUserWoodLoadField';

const CREATE = gql`mutation CreateUserWoodLoadField(
    $companyId: Int!
    $creatorId: Int!
    $name: String!
  ) {
    __typename
    createUserWoodLoadField(
      input: {
        userWoodLoadField: {
          name: $name
          creatorId: $creatorId
          companyId: $companyId
        }
      }
    ) {
      clientMutationId
    }
  }
  
  `


interface Props {
    open: boolean;
    setOpen: (val: boolean) => void;
    companyId: number
}



export default function CreateEditUserWoodLoadFieldDialog(props: Props) {
    const userContext = useContext(UserContext);

    const [create] = useMutation<CreateUserWoodLoadField, CreateUserWoodLoadFieldVariables>(CREATE);

    const [name, setName] = useState<string>('')

    return (
        <Dialog
            disableBackdropClick
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Create Wood Load Field</DialogTitle>
            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: 'min-content auto', alignItems: 'center', columnGap: 10, rowGap: 10, minWidth: 300 }}>


                    <Typography style={{ gridColumn: 1 }}>Field Name:</Typography>
                    <TextField
                        variant="outlined"
                        value={name}
                        multiline
                        onChange={event => setName(event.target.value)}
                        placeholder="WoodLoad Color"
                    />
                    <div style={{ height: 100 }} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpen(false)} variant="outlined">
                    Cancel
                </Button>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {

                            await create({
                                variables: {
                                    creatorId: userContext?.currentUser.currentUser?.id || -1,
                                    name,
                                    companyId: props.companyId
                                },
                            });

                            props.setOpen(false);
                        }}
                    >
                        Save!
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}