import React, { useEffect, useState, useContext } from 'react'
import Axios from 'axios';
import { server_url } from './createClient';
import HistoryContext from './HistoryContext';



export default function StripeOAuth() {
    const historyContext = useContext(HistoryContext)
    const [errorMessage, setErrorMessage] = useState('')


    useEffect(() => {

        async function doWork() {
            const url = new URL(window.location.href);
            const searchParams = new URLSearchParams(url.search);
            const stripeCode = searchParams.get('code');
            const companyId = searchParams.get('state')

            try {
                if (stripeCode) {
                    const res = await Axios.post(`${server_url}/api/create_stripe_connected_account`, {
                        companyId,
                        stripeCode
                    },
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                            }
                        })

                    if (res.data.error) {
                        throw new Error(res.data.error)
                    }
                    historyContext.history?.push(`/companies/${companyId}`)
                }

            } catch (e: any) {
                setErrorMessage(e.toString())
            }


        }


        doWork()
    }, [])


    return <div>
        <div>
            Creating Stripe Connected Account...
        </div>
        <p style={{ color: 'red' }}>
            {errorMessage}
        </p>
    </div>
}