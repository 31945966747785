import React, { useContext } from 'react'
import { JobFragment as Job } from './generated/JobFragment'
import { formatDistanceStrict } from 'date-fns'
import { Link } from 'react-router-dom'
import { Table, Paper, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { TableCellWaldo } from './styles'
import HistoryContext from './HistoryContext'

interface Props {
    data: Job[]
}




export default function JobsTable(props: Props) {

    const historyContext = useContext(HistoryContext)


    return (
        <Paper>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellWaldo>Contract</TableCellWaldo>
                        <TableCellWaldo>County</TableCellWaldo>
                        <TableCellWaldo>Created</TableCellWaldo>
                        <TableCellWaldo># of Crews</TableCellWaldo>
                        <TableCellWaldo>Township</TableCellWaldo>
                        <TableCellWaldo>Company</TableCellWaldo>
                        <TableCellWaldo># of WoodLoads</TableCellWaldo>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        props.data.map(job => <TableRow hover onClick={() => {
                            historyContext.history?.push(`/jobs/${job.id}`)
                        }}>
                            <TableCell component="th" scope="row">{job?.contractName}</TableCell>
                            <TableCell>{job?.county}</TableCell>
                            <TableCell>{formatDistanceStrict(new Date(job?.createdAt), new Date(), { addSuffix: true })}</TableCell>
                            <TableCell>{job?.jobCrewsByJobId?.totalCount}</TableCell>
                            <TableCell>{job?.township}</TableCell>
                            <TableCell><Link onClick={(event) => event.stopPropagation()} to={`/companies/${job?.companyByCompanyId?.id}`}>{job?.companyByCompanyId?.name}</Link></TableCell>
                            <TableCell>{job?.woodLoadsByJobId?.totalCount}</TableCell>
                        </TableRow>)
                    }



                </TableBody>

            </Table>
        </Paper>
    );

}
