import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
    name: string
    avatarUrl?: string
    linkTo: string
}

export default function AvatarWithName(props: Props) {
    return <Link
        to={props.linkTo}
        onClick={(event) => {
            event.stopPropagation()
        }}>

        <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}

        >{props.avatarUrl ? <img height={30} src={props.avatarUrl || ''} style={{ borderRadius: 15, marginRight: 5 }} alt="trucker" />
            : <div style={{
                height: 30,
                width: 30,
                alignItems: 'center',
                textAlign: 'center',
                backgroundColor: 'lightgrey',
                borderRadius: 40 / 2,
                alignSelf: 'center',
                marginRight: 10,
            }}>
                {props.name.slice(0, 1)}
            </div>
            }{props.name}</div>
    </Link>
}