import React, { useState, useContext } from 'react'

import { useMutation, useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import {
    GetCompanyFiles,
    GetCompanyFilesVariables,
    GetCompanyFiles_companyById_publicCompanyFilesByCompanyId_nodes as CompanyFile
} from '../generated/GetCompanyFiles'
import { PageTitle } from '../WaldoStyle'
import WaldoTable from '../WaldoTable'

import {
    IconButton,
} from '@material-ui/core';
import { server_url } from '../createClient'
import { CreatePublicCompanyFile, CreatePublicCompanyFileVariables } from '../generated/CreatePublicCompanyFile'
import { UserContext } from '../UserContext'
import EditIcon from '@material-ui/icons/Edit'
import { isPresent } from '../utils'
import EditFileDialog from './EditFileDialog'


const QUERY = gql`query GetCompanyFiles($companyId: Int!) {
    companyById(id: $companyId) {
      publicCompanyFilesByCompanyId {
        nodes {
          id
          name
          fileUrl
        }
      }
    }
  }
  `


const CREATE_FILE = gql`mutation CreatePublicCompanyFile(
    $companyId: Int!
    $fileUrl: String!
    $creatorId: Int!
    $name: String!
  ) {
    __typename
    createPublicCompanyFile(
      input: {
        publicCompanyFile: {
          companyId: $companyId
          fileUrl: $fileUrl
          creatorId: $creatorId
          name: $name
        }
      }
    ) {
      clientMutationId
    }
  }
  `


export default function PublicFiles(props: { companyId: number }) {
    const userContext = useContext(UserContext)

    const { data, refetch } = useQuery<GetCompanyFiles, GetCompanyFilesVariables>(QUERY, {
        variables: {
            companyId: props.companyId
        }
    })

    const [create] = useMutation<CreatePublicCompanyFile, CreatePublicCompanyFileVariables>(CREATE_FILE)

    const [fileToEdit, setFileToEdit] = useState<CompanyFile | undefined>(undefined)


    return <div>
        <PageTitle>PUBLIC FILES <input
            value=""
            type="file"
            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
            onChange={async (e) => {
                const profileImg = e.target?.files ? e.target?.files[0] : null;

                const formData = new FormData();

                formData.append('user_file', profileImg as Blob);


                const res = await fetch(`${server_url}/api/upload_file`, {
                    method: 'POST',
                    headers: {
                        ContentType: 'multipart/form-data',
                    },
                    body: formData,
                });

                const j = await res.json();

                await create({
                    variables: {
                        name: profileImg?.name || 'userfile',
                        fileUrl: j.fileUrl,
                        creatorId:
                            userContext?.currentUser.currentUser?.id || -1,
                        companyId: props.companyId
                    },
                });

                await refetch()


            }}
        />
        </PageTitle>

        <WaldoTable
            data={data?.companyById?.publicCompanyFilesByCompanyId.nodes.filter(isPresent) || []}
            columns={[
                {
                    header: 'Name',
                    render: r => <a href={r?.fileUrl} target="_blank">{r?.name}</a>
                },
                {
                    header: 'Actions',
                    render: r => <>
                        <IconButton
                            style={{ width: 60 }}
                            onClick={(event) => {
                                event.stopPropagation()
                                setFileToEdit(r)
                            }}>
                            <EditIcon />
                        </IconButton>
                    </>
                }
            ]}
        />

        {
            fileToEdit !== undefined && <EditFileDialog
                open={fileToEdit !== undefined}
                setOpen={async () => {
                    await refetch()
                    setFileToEdit(undefined)
                }}
                file={fileToEdit}
            />
        }
    </div>
}