import React, { useState } from 'react'
import gql from 'graphql-tag'
import queryString from 'query-string'
import { AllWoodLoads, AllWoodLoadsVariables } from './generated/AllWoodLoads'
import { useQuery } from '@apollo/react-hooks'
import WoodLoadsTable from './WoodLoads/WoodLoadsTable'
import { isPresent } from './utils'

export const WOOD_LOAD_FRAGMENT = gql`
    fragment WoodLoadFragment on WoodLoad {
        createdAt
        droppedOffAt
        pickedUpAt
        id
        value
        unit
        scaleTicketNumber
        tripTicketNumber
        userByLoggerUserId {
            avatarUrl
            id
            username
        }
        woodLoadDestinationByWoodLoadDestinationId {
            name
            externalId
            id
        }
        woodLoadTypeByLoadType {
            name
        }
        woodSpecyByWoodSpeciesId {
            name
        }
        estimatedVolume
        idOnJob
        loadId
        truckerLoadApplicationByAcceptedApplicationId {
            id
            userByUserId {
                avatarUrl
                id
                username
                latestUserLocationByUserId {
                    coordinate {
                        latitude
                        longitude
                    }
                    createdAt
                }
            }
            companyByCompanyId {
                id
                name
                avatarUrl
            }
        }
        jobByJobId {
            id
            contractName
            contractNumber
            serviceContractName
            county
            range2
            section
            township
            companyByCompanyId {
                id
            }
        }
        pickUpLocation {
            latitude
            longitude
        }
        notes
        companyByLoggingCompanyId {
            id
            name
            avatarUrl
        }
        speciesGroupName
        woodLoadStateEntriesByWoodLoadId(orderBy: ID_DESC, first: 1) {
            nodes {
                id
                woodLoadStateByStateId {
                    color
                    id
                    name
                }
            }
        }
    }
`

const WOOD_LOADS = gql`
    query AllWoodLoads($loggerId: Int, $first: Int, $from: Int) {
        allWoodLoads(
            condition: { loggerUserId: $loggerId }
            orderBy: CREATED_AT_DESC
            first: $first
            offset: $from
        ) {
            nodes {
                ...WoodLoadFragment
            }
            totalCount
        }
    }

    ${WOOD_LOAD_FRAGMENT}
`

export default function AllWoodLoadsComponent() {
    const params = queryString.parse(window.location.search)

    const [first, setFirst] = useState<number>(10)
    const [from, setFrom] = useState<number>(10)
    const { data } = useQuery<AllWoodLoads, AllWoodLoadsVariables>(WOOD_LOADS, {
        variables: {
            loggerId: params.logger_id
                ? parseInt(params.logger_id as string, 10)
                : undefined,
            first: first,
            from: from,
        },
    })

    return (
        <div style={{ textAlign: 'center', marginBottom: 35 }}>
            <WoodLoadsTable
                data={data?.allWoodLoads?.nodes.filter(isPresent) || []}
                setFirst={(val) => setFirst(val)}
                setFrom={(val) => setFrom(val)}
            />
        </div>
    )
}
