import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  RouteComponentProps,
} from 'react-router-dom';

import Home from './Home';
import SimpleBreadcrumbs from './Breadcrumbs';
import WoodLoads from './WoodLoads';
import WoodLoad from './WoodLoad';


import Crew from './Crew';
import SingleJob from './Job';


import Companies from './Company/Companies';
import CompanyComponent from './Company/Company';
import Crews from './Crews';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeOAuth from './StripeOAuth';
import Notifications from './Notifications';
import Application from './Application';
import UserProfile from './UserProfile';
import MyProfile from './MyProfile';
import WoodLoadsCompany from './Company/WoodLoads';
import JobsCompany from './Company/Jobs';
import JobMap from './Job/JobMap'
import HistoryContext from './HistoryContext';
import { History } from 'history';
import HaulContracts from './HaulContracts/HaulContracts';
import WoodLoadStates from './WoodLoadState/WoodLoadStates';
import WoodLoadState from './WoodLoadState/WoodLoadState';
import JobsPage from './Job/JobsPage';
import Destinations from './Company/Destinations';
import Species from './Company/Species';
import PublicFiles from './Company/PublicFiles';
import ConfigureJobFields from './Company/ConfigureJobFields';
import ConfigureWoodLoadFields from './Company/ConfigureWoodLoadFields';


export function WithHistory(history: History, component: JSX.Element) {
  return (
    <HistoryContext.Provider value={{ history }}>
      {component}
    </HistoryContext.Provider>
  );
}


const server = localStorage.getItem('waldo_server');

let stripePromise = loadStripe(
  'pk_live_51H0AJyKI4x6OXXCwQjmadiAyHh1liVVs9PObBKUrTSS6m2EHMvieESfPcGKncfkgEYT0fncCsdLMAELZJdQvgQ9x007bZiPhCs'
);

if (server !== 'Prod') {
  stripePromise = loadStripe(
    'pk_test_51H0AJyKI4x6OXXCw9B51vFW8YcCyG7n8Xg75ewKehJybqUAuC0eRUrt5sLzk3tnkz5ZSBE4TUgGqdriGKOObUGb300b2N2jpFf'
  );
}

interface Props {
  refetch: () => void;
}

export default function Routes(props: Props) {
  return (
    <Router>
      <div>
        <Route path="/*" component={SimpleBreadcrumbs} />

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          {/* <Route
            path="/"
            exact
            component={(props2: RouteComponentProps<{}>) => {
              return (
                <>
                  <Home history={props2.history} refetch={props.refetch} />
                </>
              );
            }}
          /> */}


          <Route
            path="/"
            exact
            component={(props2: RouteComponentProps) => {
              return WithHistory(props2.history, <Home />)

            }}
          />

          <Route
            path="/wood_loads"
            exact
            component={(props2: RouteComponentProps) => {
              return WithHistory(props2.history, <WoodLoads />)
            }}
          />



          <Route
            path="/wood_loads/:id"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return WithHistory(props2.history, <WoodLoad
                woodLoadId={props2.match.params.id}
              />)
            }}
          />
          <Route
            path="/jobs"
            exact
            component={(props2: RouteComponentProps) => {
              return WithHistory(props2.history, <JobsPage />)
            }}
          />
          <Route
            path="/jobs/:id"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return WithHistory(props2.history, <SingleJob
                jobId={parseInt(props2.match.params.id, 10)}
              />)
            }}
          />

          <Route
            path="/jobs/:id/map"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return WithHistory(props2.history, <>
                <JobMap
                  jobId={parseInt(props2.match.params.id, 10)}
                />
              </>)
            }}
          />




          <Route
            path="/applications/:id"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return WithHistory(props2.history, <Application
                applicationId={props2.match.params.id}
              />)
            }}
          />


          <Route
            path="/users/:id"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return WithHistory(props2.history, <UserProfile
                userId={props2.match.params.id}
              />)
            }}
          />


          <Route
            path="/my_profile"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return WithHistory(props2.history, <MyProfile
                refetch={props.refetch}
              />)
            }}
          />

          <Route
            path="/notifications/"
            exact
            component={(props2: RouteComponentProps) => {
              return WithHistory(props2.history, <Notifications first={100} />)
            }}
          />


          <Route
            path="/companies"
            exact
            component={(props2: RouteComponentProps) => {
              return WithHistory(props2.history, <Companies />)
            }}
          />



          <Route
            path="/jobs/:id/crews"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return WithHistory(props2.history, <Crews
                jobId={props2.match.params.id}
              />)
            }}
          />

          <Route
            path="/crew/:id"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return WithHistory(props2.history, <Crew
                crewId={props2.match.params.id}
              />)
            }}
          />


          <Route
            path="/companies/:id"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return WithHistory(props2.history, <Elements stripe={stripePromise}>
                <CompanyComponent
                  companyId={props2.match.params.id}
                />
              </Elements>)
            }}
          />

          <Route
            path="/companies/:id/wood_loads"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return WithHistory(props2.history, <Elements stripe={stripePromise}>
                <WoodLoadsCompany
                  companyId={props2.match.params.id}
                />
              </Elements>)
            }}
          />


          <Route
            path="/companies/:id/jobs"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return WithHistory(props2.history, <Elements stripe={stripePromise}>
                <JobsCompany
                  companyId={props2.match.params.id}
                  first={10}
                />
              </Elements>)
            }}
          />

          <Route
            path="/companies/:id/destinations"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return WithHistory(props2.history, <Elements stripe={stripePromise}>
                <Destinations
                  companyId={props2.match.params.id}
                />
              </Elements>)
            }}
          />

          <Route
            path="/companies/:id/species"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return WithHistory(props2.history, <Elements stripe={stripePromise}>
                <Species
                  companyId={props2.match.params.id}
                />
              </Elements>)
            }}
          />

          <Route
            path="/companies/:id/haul_contracts"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return (
                WithHistory(props2.history, <>
                  <HaulContracts
                    companyId={parseInt(props2.match.params.id, 10)}
                  />
                </>)



              );
            }}
          />



          <Route
            path="/companies/:id/wood_load_states"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return (
                WithHistory(props2.history, <>
                  <WoodLoadStates
                    companyId={parseInt(props2.match.params.id, 10)}
                  />
                </>)
              );
            }}
          />

          <Route
            path="/companies/:companyId/wood_load_states/:id"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return (
                WithHistory(props2.history, <>
                  <WoodLoadState
                    id={parseInt(props2.match.params.id, 10)}
                  />
                </>)
              );
            }}
          />

          <Route
            path="/companies/:id/public_files"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return (
                WithHistory(props2.history, <>
                  <PublicFiles
                    companyId={parseInt(props2.match.params.id, 10)}
                  />
                </>)
              );
            }}
          />

          <Route
            path="/companies/:id/job_fields"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return (
                WithHistory(props2.history, <>
                  <ConfigureJobFields
                    companyId={parseInt(props2.match.params.id, 10)}
                  />
                </>)
              );
            }}
          />

          <Route
            path="/companies/:id/wood_load_fields"
            exact
            component={(props2: RouteComponentProps<{ id: string }>) => {
              return (
                WithHistory(props2.history, <>
                  <ConfigureWoodLoadFields
                    companyId={parseInt(props2.match.params.id, 10)}
                  />
                </>)
              );
            }}
          />




          <Route
            path="/stripe_oauth_redirect"
            exact
            component={(props2: RouteComponentProps) => (
              WithHistory(props2.history, <StripeOAuth />)
            )}
          />
        </Switch>
      </div>
    </Router>
  );
}
