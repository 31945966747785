import React from 'react'
import { useQuery } from '@apollo/react-hooks'


import 'react-datepicker/dist/react-datepicker.css';
import JobsTable from '../JobsTable'
import gql from 'graphql-tag'
import { GetCompanyJobs, GetCompanyJobsVariables } from '../generated/GetCompanyJobs';
import { JobFragment as Job } from '../generated/JobFragment'
import { JOB_FRAGMENT } from '../Crew';
import { Typography } from '@material-ui/core';


export const COMPANY_JOBS = gql`query GetCompanyJobs($companyId: Int!) {
  companyById(id: $companyId) {
    id
    name
    jobsByCompanyId {
      nodes {
        ...JobFragment
      }
    }
  }
}

  ${JOB_FRAGMENT}
  `


interface Props {
  companyId: string
  first: number
}



export default function JobsCompany(props: Props) {


  const { data } = useQuery<GetCompanyJobs, GetCompanyJobsVariables>(COMPANY_JOBS, {
    variables: {
      companyId: parseInt(props.companyId, 10)
    }
  })


  const dataSource: Job[] = []
  data?.companyById?.jobsByCompanyId.nodes.map((node) =>
    node && dataSource.push(node)
  )


  return <div>
    <Typography>Jobs for <b>{data?.companyById?.name}</b></Typography>
    <JobsTable data={dataSource} />

  </div>
}