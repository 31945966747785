import React from 'react'
import gql from 'graphql-tag'
import { OwnerHaulContracts, OwnerHaulContractsVariables } from '../generated/OwnerHaulContracts'
import { useQuery } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'
import WaldoTable from '../WaldoTable'
import { isPresent } from '../utils'
import AvatarWithName from '../AvatarWithName'

const QUERY = gql`query OwnerHaulContracts($companyId: Int!) {
    companyById(id: $companyId) {
      haulContractsByOwnerCompanyId {
        nodes {
          id
          name
          companyByHaulCompanyId {
            id
            name
            avatarUrl
          }
        }
      }
      name
    }
  }
  `

interface Props {
  companyId: number
}

export default function HaulContracts(props: Props) {

  const { data } = useQuery<OwnerHaulContracts, OwnerHaulContractsVariables>(QUERY, {
    variables: {
      companyId: props.companyId
    }
  })

  return <div>
    <Typography>Haul Contracts for <b>{data?.companyById?.name}</b>
    </Typography>

    <Typography style={{ fontStyle: 'italic', color: 'grey' }}>Contracts are configurable in the mobile app</Typography>

    <WaldoTable
      data={data?.companyById?.haulContractsByOwnerCompanyId.nodes.filter(isPresent) || []}
      columns={[{
        header: 'name',
        render: (r) => r.name
      },
      {
        header: 'Haul Company',
        render: (r) => <AvatarWithName
          name={r.companyByHaulCompanyId?.name || ''}
          linkTo={`/Companies/${r.companyByHaulCompanyId?.id}`}
          avatarUrl={r.companyByHaulCompanyId?.avatarUrl || ''}
        />
      }
      ]}
    />
  </div>
}