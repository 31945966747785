import React, { useState, useContext } from 'react';
import gql from 'graphql-tag';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@material-ui/core';



import {
    WoodLoadQuery_woodLoadById as WoodLoad,
} from '../generated/WoodLoadQuery';
import { UserContext } from '../UserContext';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CreateWoodLoadStateEntryVariables, CreateWoodLoadStateEntry } from '../generated/CreateWoodLoadStateEntry';
import { GetWoodLoadStates, GetWoodLoadStatesVariables } from '../generated/GetWoodLoadStates';
import { WOOD_LOAD_STATES } from '../WoodLoadState/WoodLoadStates'
import Select from 'react-select'
import { isPresent } from '../utils';
import ColoredLabel from '../WoodLoadState/ColoredLabel';

const CREATE = gql`mutation CreateWoodLoadStateEntry(
    $woodLoadId: Int!
    $stateId: Int!
    $creatorId: Int!
    $comment: String
  ) {
    __typename
    createWoodLoadStateEntry(
      input: {
        woodLoadStateEntry: {
          creatorId: $creatorId
          stateId: $stateId
          woodLoadId: $woodLoadId
          comment: $comment
        }
      }
    ) {
      clientMutationId
    }
  }
  
  `


interface Props {
    open: boolean;
    setOpen: (val: boolean) => void;
    woodLoad: WoodLoad
}



export default function CreateWoodLoadStateEntryDialog(props: Props) {
    const userContext = useContext(UserContext);

    const [create] = useMutation<CreateWoodLoadStateEntry, CreateWoodLoadStateEntryVariables>(CREATE);

    const { data } = useQuery<GetWoodLoadStates, GetWoodLoadStatesVariables>(WOOD_LOAD_STATES, {
        variables: {
            companyId: props.woodLoad.jobByJobId?.companyByCompanyId?.id || -1 // TODO: Can Trucking or Logging company manage state too?
        }
    })

    const [comment, setComment] = useState<string>('')

    const [selectedState, setSelectedState] = useState<{ value: number, label: string } | undefined>(undefined)

    return (
        <Dialog
            disableBackdropClick
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Update State</DialogTitle>
            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: 'min-content auto', alignItems: 'center', columnGap: 10, rowGap: 10, minWidth: 300 }}>
                    <Typography style={{ gridColumn: '1' }}>State:</Typography>
                    <div style={{ gridColumn: '2' }}>
                        <Select

                            value={selectedState}
                            onChange={(option) => {
                                setSelectedState(option || undefined)
                            }}
                            options={data?.companyById?.woodLoadStatesByCompanyId.nodes.filter(isPresent).map((n) => ({
                                value: n.id,
                                label: n.name,
                            })) || []}
                        />
                    </div>
                    {selectedState && <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}><ColoredLabel
                        color={data?.companyById?.woodLoadStatesByCompanyId.nodes.find(n => n?.id === selectedState?.value)?.color || ''}
                        text={selectedState?.label}
                    /></div>}

                    <Typography style={{ gridColumn: 1 }}>Comment:</Typography>
                    <TextField
                        variant="outlined"
                        value={comment}
                        multiline
                        onChange={event => setComment(event.target.value)}
                    />
                    <div style={{ height: 100 }} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpen(false)} variant="outlined">
                    Cancel
                </Button>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {

                            await create({
                                variables: {
                                    creatorId: userContext?.currentUser.currentUser?.id || -1,
                                    stateId: selectedState?.value || -1,
                                    woodLoadId: props.woodLoad.id,
                                    comment
                                },
                            });

                            props.setOpen(false);
                        }}
                    >
                        Save!
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}