import React from 'react'

import { Typography } from '@material-ui/core'
import gql from 'graphql-tag'
import { GetWoodLoadState, GetWoodLoadStateVariables } from '../generated/GetWoodLoadState'
import { useQuery } from '@apollo/react-hooks'
import WaldoTable from '../WaldoTable'
import { Link } from 'react-router-dom'
import { dateToString, isPresent } from '../utils'
import { printDistance } from '../WoodLoad'
import AvatarWithName from '../AvatarWithName'
import ColoredLabel from './ColoredLabel'
import { PageTitle } from '../WaldoStyle'

const QUERY = gql`query GetWoodLoadState($id: Int!) {
    woodLoadStateById(id: $id) {
    id
    color
    name
      woodLoadStateEntriesByStateId {
        nodes {
          id
          comment
          createdAt
          userByCreatorId {
            id
            avatarUrl
            username
          }
          woodLoadByWoodLoadId {
            id
            loadType
            woodLoadDestinationByWoodLoadDestinationId {
              id
              name
            }
            woodLoadStateEntriesByWoodLoadId(orderBy: ID_DESC, first: 1) {
              nodes {
                id
              }
            }
          }
        }
      }
    }
  }
  `


interface Props {
    id: number
}

export default function WoodLoadState(props: Props) {

    const { data } = useQuery<GetWoodLoadState, GetWoodLoadStateVariables>(QUERY, {
        variables: {
            id: props.id
        }
    })

    const loads = data?.woodLoadStateById?.woodLoadStateEntriesByStateId.nodes
        .filter(n => n?.woodLoadByWoodLoadId?.woodLoadStateEntriesByWoodLoadId.nodes[0]?.id === n?.id).filter(isPresent) || []

    return <div>
        <PageTitle>WOOD LOAD STATE</PageTitle>

        <ColoredLabel
            color={data?.woodLoadStateById?.color || ''}
            text={data?.woodLoadStateById?.name || ''}
        />

        <WaldoTable
            data={loads}
            columns={[{
                header: 'Wood Load',
                render: r => <Link to={`/wood_loads/${r.woodLoadByWoodLoadId?.id}`}>{r.woodLoadByWoodLoadId?.loadType} to {r.woodLoadByWoodLoadId?.woodLoadDestinationByWoodLoadDestinationId?.name} ({r.woodLoadByWoodLoadId?.id})</Link>
            },
            {
                header: 'Created',
                render: r => <Typography>{dateToString(new Date(r.createdAt))}{' '}
                    ({printDistance(r.createdAt)})</Typography>
            },
            {
                header: 'By',
                render: r => <AvatarWithName
                    linkTo={`/users/${r.userByCreatorId?.id}`}
                    avatarUrl={r.userByCreatorId?.avatarUrl || ''}
                    name={r.userByCreatorId?.username || ''}
                />
            },
            {
                header: 'Comment',
                render: r => r?.comment
            }
            ]}
        />
    </div>
}