import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';

const server = localStorage.getItem('waldo_server')
let server_url = 'https://waldo-autoserver.herokuapp.com'

// let server_url = 'http://localhost:8080'

if (server === 'Dev') {
    server_url = 'https://waldo-dev.herokuapp.com'
}

export { server_url, server }


const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, path }) =>
            console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`)
        );
    }

    if (networkError) {
        console.log(operation)
        console.log(networkError)
        console.log(
            `[Network error ${operation.operationName}]: ${networkError.message}`
        );
    }
});

export const createClient = () => {
    const cache = new InMemoryCache();

    const httpLink = new HttpLink({
        uri: `${server_url}/graphql`
    });

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = localStorage.getItem('token');

        // return the headers to the context so httpLink can read them
        if (token) {
            return {
                headers: {
                    ...headers,
                    Authorization: token ? `Bearer ${token}` : '',
                    mode: 'no-cors'
                }
            }
        } else {
            return headers
        }

    });

    const client = new ApolloClient({
        cache,
        link: ApolloLink.from([errorLink, authLink, httpLink]),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        }
    });

    return client;
};