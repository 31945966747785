/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Indicates whether archived items should be included in the results or not.
 */
export enum IncludeArchivedOption {
  EXCLUSIVELY = "EXCLUSIVELY",
  INHERIT = "INHERIT",
  NO = "NO",
  YES = "YES",
}

export enum NotificationType {
  JOB_NEW_COMMENT = "JOB_NEW_COMMENT",
  LOAD_DROPOFF_TIME_EDITED = "LOAD_DROPOFF_TIME_EDITED",
  LOAD_PICKUP_TIME_EDITED = "LOAD_PICKUP_TIME_EDITED",
  TRUCKER_APPLICATION_ACCEPTED = "TRUCKER_APPLICATION_ACCEPTED",
  TRUCKER_APPLICATION_SUBMITTED = "TRUCKER_APPLICATION_SUBMITTED",
  WOOD_LOAD_NEW_COMMENT = "WOOD_LOAD_NEW_COMMENT",
  WOOD_LOAD_PUBLISHED = "WOOD_LOAD_PUBLISHED",
}

export enum WoodLoadUnit {
  CORD = "CORD",
  MBF = "MBF",
  TON = "TON",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
export default {}
