import React from 'react'

interface Props {
    color: string
    text: string
}

export default function ColoredLabel(props: Props) {
    return <div style={{
        backgroundColor: props.color,
        padding: 3,
        borderRadius: 3,
        display: 'inline-block',
        paddingLeft: 10,
        paddingRight: 10,
        color: 'black'
    }}>{props.text}</div>
}